export default {
  mobileFirefoxAlert: 'Σε αυτόν τον περιηγητή η επεξεργασία της εργασίας δεν είναι διαθέσιμη.',
  datePicker: {
    from: 'Από',
    to: 'Προς',
    cancel: 'Ακύρωση',
    apply: 'Εφαρμογή',
    month: [
      'Ιανουάριος',
      'Φεβρουάριος',
      'Μάρτιος',
      'Απρίλιος',
      'Μάιος',
      'Ιούνιος',
      'Ιούλιος',
      'Αύγουστος',
      'Σεπτέμβριος',
      'Οκτώβριος',
      'Νοέμβριος',
      'Δεκέμβριος'
    ],
    week: 'εβδομάδα',
    weekShot: 'εβδ',
    buttonName: {
      date: 'Ημερομηνία',
      week: 'Εβδομάδα',
      month: 'Μήνας'
    },
    modal: {
      header: 'Προειδοποίηση',
      text: 'Επιλέγηκε μη έγκυρο εύρος ημερομηνιών',
      action: 'Αποδοχή'
    }
  },
  filters: {
    name: 'Όνομα'
  },
  scenario: {
    noDataReceived: 'Δεν λήφθηκαν δεδομένα',
    filterPart: {
      name: 'Όνομα',
      clear: 'Καθαρισμός',
      notFound: 'Δεν βρέθηκε',
      search: 'Αναζήτηση...'
    }
  },
  header: {
    logout: 'Αποσύνδεση',
    changePassword: 'Αλλαγή κωδικού',
    settings: 'Επαλήθευση',
    title: 'Προβολή διεπαφής χρήστη',
    searchTitle: 'Αναζήτηση χρήστη',
    input: 'Ξεκινήστε να πληκτρολογείτε όνομα ή σύνδεση...',
    cancel: 'Ακύρωση',
    view: 'Προβολή',
    id: 'ΑΝ',
    login: 'Σύνδεση',
    name: 'Όνομα',
    phone: 'Τηλέφωνο',
    email: 'Ηλεκτρονικό ταχυδρομείο',
    stopSettings: 'Διακοπή προβολής'
  },
  directTask: {
    addCase: 'Προσθήκη περίπτωσης',
    commentCase: 'Σχόλιο περίπτωσης',
    actions: 'Ενέργειες',
    searchProduct: 'PLU/Μπάρκον',
    searchProductShort: 'PLU/Μπάρκον',
    photo: 'Φωτογραφία',
    back: 'Πίσω',
    treeEmpty: 'ΟΙ ΕΝΕΡΓΕΙΕΣ ΔΕΝ ΕΙΝΑΙ ΔΙΑΘΕΣΙΜΕΣ',
    step: 'Βήμα',
    sortingButton: 'Ταξινόμηση',
    sortButton: 'Ταξινόμηση',
    add: 'Προσθήκη',
    delete: 'Διαγραφή',
    deleteManualCases: 'Διαγραφή χειροκίνητων περιπτώσεων',
    show: 'Εμφάνιση',
    hide: 'Απόκρυψη',
    comment: 'Σχόλιο',
    showDoneCases: 'Εμφάνιση ολοκληρωμένων εργασιών',
    hideDoneCases: 'Απόκρυψη ολοκληρωμένων εργασιών'
  },
  gridTable: {
    pluList: 'Λίστα PLU'
  },
  casesForm: {
    product: 'Προϊόν',
    loadFoto: 'Φόρτωση φωτογραφίας',
    plu: 'Όνομα/PLU',
    success: 'Δημιουργήθηκε περίπτωση',
    delete: 'Διαγράφω',
    add: 'Δημιουργώ',
    new: 'Νέος',
    cancel: 'Ματαίωση',
    comment: 'Σχόλιο'
  },
  pagination: {
    apply: 'Μετάβαση',
    placeholder: 'Σελίδα'
  },
  restorePass: {
    noLength: 'Ο κωδικός πρέπει να έχει τουλάχιστον 12 χαρακτήρες',
    noLowercase: 'Ο κωδικός πρέπει να έχει τουλάχιστον ένα πεζό γράμμα',
    noUppercase: 'Ο κωδικός πρέπει να έχει τουλάχιστον ένα κεφαλαίο γράμμα',
    noNumber: 'Ο κωδικός πρέπει να έχει τουλάχιστον έναν αριθμητικό σύμβολο',
    noMatch: 'Οι κωδικοί δεν ταιριάζουν',
    password: 'Κωδικός',
    repeatPassword: 'Επανάληψη κωδικού',
    ok: 'Αποδοχή',
    cancel: 'Ακύρωση'
  },
  navigation: {
    missions: 'Αποστολές',
    scenarios: 'Σενάρια'
  },
  changePassword: {
    previousPassword: 'Τρέχων κωδικός',
    newPassword: 'Νέος κωδικός',
    confirmPassword: 'Επαλήθευση νέου κωδικού',
    apply: 'Εφαρμογή',
    title: 'Αλλαγή κωδικού',
    success: 'Ο κωδικός αλλάχθηκε με επιτυχία',
    wrongPassword: 'Λάθος κωδικός',
    weakPassword: 'Ο κωδικός δεν πληροί τις απαιτήσεις',
    passwordsNotMatch: 'Οι κωδικοί δεν ταιριάζουν',
    requirements: 'Ο νέος κωδικός πρέπει να έχει τουλάχιστον 12 χαρακτήρες, 1 κεφαλαίο και 1 πεζό γράμμα και 1 αριθμό '
  },
  auth: {
    login: 'Σύνδεση',
    password: 'Κωδικός',
    restorePass: 'Ανάκτηση κωδικού',
    submit: 'Υποβολή',
    loginError: 'Μη έγκυρη σύνδεση και/ή κωδικός'
  },
  restorePassForm: {
    restore: 'Ανάκτηση',
    cancel: 'Ακύρωση',
    info: 'Παρακαλούμε εισαγάγετε το email σας, που είναι εγγεγραμμένο στην OSA Hybrid Platform',
    emailInvalid: 'Το υποβληθέν email δεν βρέθηκε',
    email: 'Email',
    success: 'Ο σύνδεσμος ανάκτησης στάλθηκε στο email σας'
  },
  search: {
    notFound: 'Δεν βρέθηκε'
  },
  noMatchPage: {
    title: 'Η σελίδα που ζητήσατε δεν βρέθηκε',
    body: 'Επιστροφή στην αρχική σελίδα'
  },
  warnings: {
    title: 'Προειδοποίηση',
    isDesktop: 'Η μεταφόρτωση φωτογραφιών είναι διαθέσιμη μόνο στην κινητή έκδοση του συστήματος. Χρησιμοποιήστε την κάμερα στο κινητό ή το tablet σας, παρακαλώ.'
  },
  errors: {
    netErrorText: 'Σφάλμα δικτύου. Παρακαλώ, ανανεώστε τη σελίδα',
    netErrorReload: 'Ανανέωση',
    netErrorTitle: 'Σφάλμα',
    netErrorClose: 'Κλείσιμο',
    noShops: 'Ωχ! Φαίνεται ότι δεν σας έχουν εκχωρηθεί ακόμη καταστήματα. Πείτε το στον διευθυντή σας',
    noCases: 'Φαίνεται ότι η υπηρεσία μας δεν μπόρεσε να βρει ούτε μια θήκη σε αυτό το κατάστημα. Ξεκινήστε να τα δημιουργείτε μόνοι σας',
    noAutoOrder: 'Δεν υπάρχουν εργασίες για την προσαρμογή της αυτόματης παραγγελίας',
    causeError: 'Σφάλμα',
    scenariosEmpty: 'Ο πίνακας των σεναρίων είναι κενός'
  },
  officeUI: {
    resetZoom: 'Επαναφορά εστίασης'
  },
  gridAutocomplete: {
    placeholder: 'Ξεκινήστε να πληκτρολογείτε το όνομα του καταστήματος',
    notFound: 'Το κατάστημα δεν βρέθηκε'
  },
  targets: {
    nothing: '',
    shop: 'Κατάστημα',
    category: 'Κατηγορία',
    supplier: 'Προμηθευτής',
    vendor: 'Προμηθευτής',
    brand: 'Μάρκα',
    plu: 'PLU',
    day: 'Ημέρα',
    week: 'Εβδομάδα',
    month: 'Μήνας',
    quarter: 'Τρίμηνο',
    year: 'Έτος'
  },
  bubbleChart: {
    qty: 'ποσότητα',
    currency: '€'
  },
  chart: {
    quarter: 'τρίμηνο',
    week: 'εβδομάδα'
  },
  download: {
    CSV: 'Λήψη CSV',
    XLS: 'Λήψη XLS'
  },
  counterTask: {
    task: 'Εκκρεμείς εργασίες',
    noTask: 'Δεν υπάρχουν εργασίες'
  },
  filtersShop: {
    shopName: 'Διεύθυνση καταστήματος',
    shopId: 'Όνομα καταστήματος'
  },
  chartCaption: {
    thousand: 'χιλ.',
    million: 'εκατ.',
    billion: 'δισ.'
  },
  common: {
    close: 'Κλείσιμο',
    userNotFound: 'Ο χρήστης δεν βρέθηκε',
    yes: 'Ναι',
    no: 'Όχι',
  },
  virtualStock: {
    headers: {
      case_id: "ΑΝ",
      plu_original: "PLU",
      plu: "Εσωτερικό PLU",
      shop_id: "ΑΝ καταστήματος",
      ass_type: "Τύπος αναφοράς",
      category_id3: "ID ενότητας",
      category_id4: "ID κατηγορίας",
      stock: "Όγκος αποθεμάτων",
      stock_sum: "Ποσότητα αποθεμάτων",
      product_turnover: "Κίνηση προϊόντος",
      days_without_sales: "Ημέρες χωρίς πωλήσεις",
      last_sales_date: "Ημερομηνία τελευταίας πώλησης",
      last_sales_volume: "Τελευταίος όγκος πώλησης",
      last_shipment_date: "Τελευταία ημερομηνία παράδοσης",
      last_shipment_volume: "Τελευταίος όγκος παράδοσης",
      promo_start: "Έναρξη προσφοράς",
      promo_end: "Λήξη προσφοράς",
      promo_period: "Περίοδος προσφοράς",
      is_promo: "Προσφορά",
      title: "Όνομα",
      category_name3: "Ενότητα",
      category_name4: "Κατηγορία"
    },
    hideReadyTasks: "Απόκρυψη έτοιμων εργασιών",
    nothingFound: "Δεν βρέθηκε τίποτα",
    shopsNotFound: "Δεν βρέθηκαν καταστήματα",
    chooseShop: "Επιλέξτε ένα κατάστημα",
    createTasks: "Δημιουργία εργασιών",
    taskCreating: "Δημιουργία εργασιών",
    next: "Συνέχεια",
    columns: "Στήλες",
    from: "Από",
    ok: "OK",
    cancel: "Ακύρωση",
    filter: "Φίλτρο",
    chooseValue: "Επιλέξτε μια τιμή",
    enterValue: "Εισαγωγή τιμής",
    rangeFrom: "Από",
    rangeTo: "Προς",
    selected: "Επιλεγμένο",
    foundRows: "Βρέθηκαν σειρές",
    createdTasksTotal: "Συνολικά δημιουργημένες εργασίες"
  }
};
