import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { MuiThemeProvider, Button, createMuiTheme } from '@material-ui/core';
import 'moment/locale/ru';

import { setGridDatePickerAction } from '../../store/actions/actions';
import ButtonField from '../Buttons/ButtonField';

import styles from './DatePickerGridComponent.css';

const materialTheme = createMuiTheme({
  typography: {
    fontFamily: ['MuseoSansRounded', 'sans-serif', '-apple-system'].join(','),
    fontWeight: 400,
    htmlFontSize: 16,
    h4: {
      fontSize: '1.6rem',
    },
  },
  overrides: {
    MuiInput: {
      underline: {
        '&:hover:not($disabled):not($focused):not($error):before': {
          borderBottomColor: '#949494',
          borderBottomWidth: 1
        },
        '&:hover:after': {
          borderBottomColor: '#949494',
          borderBottomWidth: 1
        },
        '&:after': {
          borderBottomColor: '#949494',
          borderBottomWidth: 1
        }
      },
      input: {
        // fontSize: '1.3rem',
        textAlign: 'center',
        color: '#494949',
        cursor: 'pointer'
      }
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#1aa7e0'
      }
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: '#1aa7e0',
        '&:hover': {
          backgroundColor: '#0077a7'
        }
      }
    }
  }
});

const defaultDate = {
  minDate: new Date(),
  maxDate: new Date()
};

class FilterPart extends Component {
  state = {
    minDate: this.props.gridDatePickerDate.minDate || defaultDate.minDate,
    maxDate: this.props.gridDatePickerDate.maxDate || defaultDate.maxDate
  };

  setDate = () => {
    if (this.state.minDate && this.state.maxDate) {
      const scenarioFilters = this.props.filters;
      const startDate = this.state.minDate;
      const endDate = this.state.maxDate;
      let filters = {
        ...scenarioFilters
      };
      filters.startDate = this.formatDateToReport(startDate);
      filters.endDate = this.formatDateToReport(endDate);
      this.props.updateFilters(filters, 'date');
    }
  };

  handleChangeMinDate = date => {
    const { dispatch } = this.props;

    dispatch(
      setGridDatePickerAction({
        minDate: date.toDate(),
        maxDate: this.state.maxDate
      })
    );
    this.setState({
      minDate: date.toDate()
    });
  };

  handleChangeMaxDate = date => {
    const { dispatch } = this.props;

    dispatch(
      setGridDatePickerAction({
        minDate: this.state.minDate,
        maxDate: date.toDate()
      })
    );
    this.setState({
      maxDate: date.toDate()
    });
  };

  formatDate = date => {
    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
    return day + '.' + month + '.' + date.getFullYear();
  };

  formatDateToReport = date => {
    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
    return date.getFullYear() + '-' + month + '-' + day;
  };

  render() {
    const {
      restrictDateStart,
      language: { translation, lang = 'en' }
    } = this.props;
    const datePickerLanguage = translation.datePicker;
    const { minDate, maxDate } = this.state;
    const mobileVer = screen.width < 767;
    const styleButtonWrap = !mobileVer ? styles.container : styles.containerMob;

    return (
      <div className={styleButtonWrap}>
        <MuiThemeProvider theme={materialTheme}>
          <MuiPickersUtilsProvider utils={MomentUtils} locale={lang} moment={moment}>
            <div className={styles.containerPickers}>
              <div className={styles.datePickerWrap}>
                <DatePicker
                  autoOk
                  orientation="landscape"
                  variant="inline"
                  emptyLabel={datePickerLanguage.from}
                  format="DD.MM.YYYY"
                  minDate={restrictDateStart ? new Date(restrictDateStart) : undefined}
                  maxDate={maxDate}
                  onChange={this.handleChangeMinDate}
                  initialFocusedDate={minDate}
                  value={minDate}
                />
              </div>
              <div className={styles.datePickerWrap}>
                <DatePicker
                  autoOk
                  orientation="landscape"
                  variant="inline"
                  emptyLabel={datePickerLanguage.to}
                  format="DD.MM.YYYY"
                  minDate={minDate}
                  onChange={this.handleChangeMaxDate}
                  initialFocusedDate={minDate}
                  value={maxDate}
                />
              </div>
            </div>
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
        <div className={styles.buttonWrap}>
          <Button className={styles.button} fullWidth={mobileVer} variant="outlined" onClick={this.setDate}>
            {translation.datePicker.apply}
          </Button>
        </div>
      </div>
    );
  }
}

FilterPart.defaultProps = {
  filters: {},
  restrictDateStart: ''
};

FilterPart.propTypes = {
  dispatch: PropTypes.func.isRequired,
  gridDatePickerDate: PropTypes.object.isRequired,
  filters: PropTypes.object,
  updateFilters: PropTypes.func.isRequired,
  language: PropTypes.object.isRequired,
  restrictDateStart: PropTypes.string
};

export default connect(store => {
  return {
    language: store.language,
    gridDatePickerDate: store.gridDatePickerDate
  };
})(FilterPart);
