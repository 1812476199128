import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';

import styles from './Item.css';

const style = {
  root: {
    '&$checked': {
      color: '#03a7e0'
    }
  },
  checked: {}
};

class FilterPartBodyItem extends Component {
  /**
   *
   * @param props
   */
  constructor(props) {
    super(props);
  }

  /**
   *
   * @param nextProps
   * @returns {boolean}
   */
  shouldComponentUpdate(nextProps) {
    const { contentIsOpened } = this.props;
    if (contentIsOpened !== nextProps.contentIsOpened) {
      return true;
    }
    return (
      nextProps.kpi !== this.props.kpi ||
      nextProps.item !== this.props.item ||
      nextProps.allColumnsMode !== this.props.allColumnsMode ||
      nextProps.isActive !== this.props.isActive
    );
  }

  /**
   *
   * @param item
   */
  handleLoadLevel = item => {
    if (item['level'] < item['max_level'] && item['id'] !== -1) {
      this.props.loadLevel(item);
    }
  };

  /**
   *
   * @param item
   */
  handleChooseItem = item => {
    this.props.chooseItem(item);
  };

  styleFilters = metrics => {
    const { contentIsOpened } = this.props;
    if (metrics) {
      return contentIsOpened ? styles.itemNameOpen : styles.itemName;
    } else {
      return contentIsOpened ? styles.itemNameSingleOpen : styles.itemNameSingle;
    }
  };

  /**
   *
   * @returns JSX
   */
  render() {
    const { item, isActive, singleLevel, allColumnsMode, classes } = this.props;

    const levelIsLast = item['level'] < item['max_level'];
    return (
      <div
        key={item.id}
        class={cls(styles.item, {
          [styles.allColumnsMode]: allColumnsMode,
          [styles.itemActive]: isActive
        })}
      >
        <Checkbox
          checked={isActive}
          class={styles.itemCheckbox}
          classes={{
            root: classes.root,
            checked: classes.checked
          }}
          onChange={() => this.handleChooseItem(item)}
        />
        {singleLevel ? (
          <button className={this.styleFilters(item.metrics)}>{item.name}</button>
        ) : (
          <button
            className={[
              this.styleFilters(item.metrics),
              levelIsLast && item['id'] !== -1 ? styles.itemNameLevel : styles.itemNameLastLevel
            ].join(' ')}
            onClick={() => this.handleLoadLevel(item)}
          >
            {item.name}
          </button>
        )}

        {item.metrics &&
          item.metrics.map((kpiItem, index) => {
            let key = item.id + index;

            let valItem = kpiItem.value;

            if (isNaN(parseInt(valItem))) {
              valItem = 0;
            }
            return (
              <div key={key} className={styles.itemColumn}>
                {valItem}
              </div>
            );
          })}
      </div>
    );
  }
}

FilterPartBodyItem.defaultProps = {
  contentIsOpened: false
};

FilterPartBodyItem.propTypes = {
  classes: PropTypes.object,
  kpi: PropTypes.array.isRequired,
  item: PropTypes.object.isRequired,
  isActive: PropTypes.bool.isRequired,
  allColumnsMode: PropTypes.bool,
  singleLevel: PropTypes.bool.isRequired,
  loadLevel: PropTypes.func.isRequired,
  chooseItem: PropTypes.func.isRequired,
  contentIsOpened: PropTypes.bool.isRequired
};

export default withStyles(style)(FilterPartBodyItem);
