export default {
  mobileFirefoxAlert: 'В данном браузере отработка задач недоступна',
  datePicker: {
    from: 'От',
    to: 'До',
    cancel: 'Отмена',
    apply: 'Применить',
    month: [
      'Январь',
      'Февраль',
      'Март',
      'Апрель',
      'Май',
      'Июнь',
      'Июль',
      'Август',
      'Сентябрь',
      'Октябрь',
      'Ноябрь',
      'Декабрь'
    ],
    week: 'неделя',
    weekShot: 'нед',
    buttonName: {
      date: 'Дата',
      week: 'Неделя',
      month: 'Месяц'
    },
    modal: {
      header: 'Внимание',
      text: 'Выбран неверный диапазон дат',
      action: 'ОК'
    }
  },
  filters: {
    name: 'Название'
  },
  header: {
    logout: 'Выйти',
    changePassword: 'Сменить пароль',
    settings: 'Проверка',
    title: 'Посмотреть интерфейс пользователя',
    searchTitle: 'Поиск пользователя',
    input: 'Начните вводить имя или логин…',
    cancel: 'Отмена',
    view: 'Просмотр',
    id: 'ID',
    login: 'Логин',
    name: 'Имя',
    phone: 'Телефон',
    email: 'Почта',
    stopSettings: 'Остановить просмотр'
  },
  scenario: {
    noDataReceived: 'Данные не получены',
    filterPart: {
      name: 'Название',
      clear: 'Очистить',
      notFound: 'Не найдено',
      search: 'Поиск...'
    }
  },
  directTask: {
    addCase: 'Добавить кейс',
    addComment: 'Комментарий',
    actions: 'Действия',
    searchProduct: 'PLU/Barcode',
    searchProductShort: 'PLU/Barcode',
    photo: 'Фото',
    back: 'Назад',
    treeEmpty: 'ДЕЙСТВИЯ НЕДОСТУПНЫ',
    step: 'Шаг',
    sortingButton: 'Сортировать',
    sortButton: 'Сортировка',
    add: 'Добавить',
    delete: 'Удалить',
    deleteManualCases: 'Удалить кейс',
    show: 'Показать',
    hide: 'Скрыть',
    comment: 'Комментарий',
    showDoneCases: 'Показать отр. кейсы',
    hideDoneCases: 'Скрыть отр. кейсы'
  },
  gridTable: {
    pluList: 'Список PLU'
  },
  casesForm: {
    product: 'Товар',
    loadFoto: 'Загрузить фото',
    plu: 'Введите название/PLU',
    success: 'Кейс создан',
    add: 'Создать',
    new: 'Новый',
    delete: 'Удалить',
    cancel: 'Отмена',
    comment: 'Комментарий'
  },
  pagination: {
    apply: 'Вперёд',
    placeholder: 'Номер'
  },
  restorePass: {
    noLength: 'Пароль меньше 12 символов',
    noLowercase: 'Требуется как минимум одна строчная буква',
    noUppercase: 'Требуется как минимум одна прописная буква',
    noNumber: 'Требуется как минимум одно числовое значение',
    noMatch: 'Пароли не совпадают',
    password: 'Пароль',
    repeatPassword: 'Повторите пароль',
    ok: 'Подтвердить',
    cancel: 'Отмена'
  },
  navigation: {
    missions: 'Миссии',
    scenarios: 'Сценарии'
  },
  changePassword: {
    previousPassword: 'Предыдущий пароль',
    newPassword: 'Новый пароль',
    confirmPassword: 'Ещё раз новый пароль',
    apply: 'Применить',
    title: 'Сменить пароль',
    success: 'Пароль успешно изменен',
    wrongPassword: 'Неправильный пароль',
    weakPassword: 'Пароль не соответствует критериям',
    passwordsNotMatch: 'Пароли не совпадают',
    requirements:
      'Пароль должен быть не менее 12 символов длиной, из которых одна заглавная буква, одна прописная и одно число '
  },
  auth: {
    login: 'Логин',
    password: 'Пароль',
    restorePass: 'Восстановить пароль',
    submit: 'Вход в систему',
    loginError: 'Логин и/или пароль введены неверно'
  },
  restorePassForm: {
    restore: 'Восстановить',
    cancel: 'Отменить',
    info: 'Пожалуйста, введите ваш e-mail, который зарегистрирован в OSA Hybrid Platform',
    emailInvalid: 'Email не найден',
    email: 'Email',
    success: 'Ссылка для восстановления пароля выслана на ваш email'
  },
  search: {
    notFound: 'Не найдено'
  },
  noMatchPage: {
    title: 'Запрашиваемая страница не найдена',
    body: 'Вернуться на главную'
  },
  warnings: {
    title: 'Внимание',
    isDesktop: 'Загрузка фотографий доступна только в мобильной версии системы. Воспользуйтесь камерой телефона или планшета.'
  },
  errors: {
    netErrorText: 'Ошибка сети. Пожалуйста, перезагрузите страницу',
    netErrorReload: 'Перезагрузить',
    netErrorClose: 'Закрыть',
    netErrorTitle: 'Ошибка',
    noShops: 'ОЙ! Похоже, для вас еще не назначили ни одного магазина. Скажите об этом своему руководителю.',
    noCases: 'Похоже, что наш сервис не смог найти в этом магазине ни одного кейса. Начните создавать их самостоятельно.',
    noAutoOrder: 'Нет задач на корректировку автозаказа.',
    causeError: 'Произошла ошибка',
    scenariosEmpty: 'У вас нет ни одного сценария'
  },
  officeUI: {
    resetZoom: 'Сбросить зум'
  },
  gridAutocomplete: {
    placeholder: 'Начните вводить название магазина',
    notFound: 'Магазин не найден'
  },
  targets: {
    nothing: '',
    shop: 'Магазин',
    category: 'Категория',
    supplier: 'Поставщик',
    vendor: 'Вендор',
    brand: 'Бренд',
    plu: 'PLU',
    day: 'День',
    week: 'Неделя',
    month: 'Месяц',
    quarter: 'Квартал',
    year: 'Год'
  },
  bubbleChart: {
    qty: 'шт',
    currency: 'руб'
  },
  chart: {
    quarter: 'квартал',
    week: 'неделя'
  },
  download: {
    CSV: 'Скачать CSV',
    XLS: 'Скачать XLS'
  },
  counterTask: {
    task: 'Есть задачи',
    noTask: 'Нет задач'
  },
  filtersShop: {
    shopName: 'Адрес магазина',
    shopId: 'Название магазина'
  },
  chartCaption: {
    thousand: 'тыс',
    million: 'млн',
    billion: 'млрд'
  },
  common: {
    close: 'Закрыть',
    userNotFound: 'Пользователь не найден',
    yes: 'Да',
    no: 'Нет',
  },
  virtualStock: {
    headers: {
      case_id: "ID",
      plu_original: "Код товара",
      plu: "Внутр. ID товара",
      shop_id: "ID магазина",
      ass_type: "Тип ассортимента",
      category_id3: "ID секции",
      category_id4: "ID категории",
      stock: "Объем остатков",
      stock_sum: "Сумма остатков",
      product_turnover: "Оборачиваемость",
      days_without_sales: "Дней без продаж",
      last_sales_date: "Дата посл. продажи",
      last_sales_volume: "Объем посл. продажи",
      last_shipment_date: "Дата посл. поставки",
      last_shipment_volume: "Объем посл. поставки",
      promo_start: "Начало промо-акции",
      promo_end: "Конец промо-акции",
      promo_period: "Период промо",
      is_promo: "Промо",
      title: "Название",
      category_name3: "Секция",
      category_name4: "Категория"
    },
    hideReadyTasks: "Скрыть завершенные",
    nothingFound: "Ничего не найдено",
    shopsNotFound: "Магазины не найдены",
    chooseShop: "Выберите магазин",
    createTasks: "Создать задачи",
    taskCreating: "Создаются задачи",
    next: "Продолжить",
    columns: "Колонки",
    from: "Из",
    ok: "OK",
    cancel: "Отмена",
    filter: "Фильтр",
    chooseValue: "Выберите значение",
    enterValue: "Введите значение",
    rangeFrom: "От",
    rangeTo: "До",
    selected: "Выбрано",
    foundRows: "Записей",
    createdTasksTotal: "Создано задач"
  }
};
