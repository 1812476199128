export default {
  mobileFirefoxAlert: 'In this browser task processing is not available.',
  datePicker: {
    from: 'From',
    to: 'To',
    cancel: 'Cancel',
    apply: 'Apply',
    month: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ],
    week: 'week',
    weekShot: 'w',
    buttonName: {
      date: 'Date',
      week: 'Week',
      month: 'Month'
    },
    modal: {
      header: 'Warning',
      text: 'Invalid date range selected',
      action: 'Accept'
    }
  },
  filters: {
    name: 'Name'
  },
  scenario: {
    noDataReceived: 'No data received',
    filterPart: {
      name: 'Name',
      clear: 'Clear',
      notFound: 'Not found',
      search: 'Search...'
    }
  },
  header: {
    logout: 'Logout',
    changePassword: 'Change password',
    settings: 'Verification',
    title: 'View user interface',
    searchTitle: 'Search user',
    input: 'Start typing name or login...',
    cancel: 'Cancel',
    view: 'View',
    id: 'ID',
    login: 'Логин',
    name: 'Имя',
    phone: 'Телефон',
    email: 'Почта',
    stopSettings: 'Остановить просмотр'
  },
  directTask: {
    addCase: 'Add case',
    commentCase: 'commentCase',
    actions: 'Actions',
    searchProduct: 'PLU/Barcode',
    searchProductShort: 'PLU/Barcode',
    photo: 'Photo',
    back: 'Back',
    treeEmpty: 'ACTIONS ARE UNAVAILABLE',
    step: 'Step',
    sortingButton: 'Sorting',
    sortButton: 'Sort',
    add: 'Add',
    delete: 'Delete',
    deleteManualCases: 'Delete manual cases',
    show: 'Show',
    hide: 'Hide',
    comment: 'Comment',
    showDoneCases: 'Show done cases',
    hideDoneCases: 'Hide done cases'
  },
  gridTable: {
    pluList: 'PLU list'
  },
  casesForm: {
    product: 'Product',
    loadFoto: 'Load Foto',
    plu: 'Name/PLU',
    success: 'Case created',
    delete: 'Delete',
    add: 'Add',
    new: 'New',
    cancel: 'Cancel',
    comment: 'Comment'
  },
  pagination: {
    apply: 'Go',
    placeholder: 'Page'
  },
  restorePass: {
    noLength: 'Password should have at least 12 characters',
    noLowercase: 'Password should have at least one lowercase letter',
    noUppercase: 'Password should have at least one uppercase letter',
    noNumber: 'Password should have at least one numeric symbol',
    noMatch: 'Passwords don\'t match',
    password: 'Password',
    repeatPassword: 'Repeat password',
    ok: 'Accept',
    cancel: 'Cancel'
  },
  navigation: {
    missions: 'Missions',
    scenarios: 'Scenarios'
  },
  changePassword: {
    previousPassword: 'Current password',
    newPassword: 'New password',
    confirmPassword: 'New password once more',
    apply: 'Apply',
    title: 'Change password',
    success: 'Password was successfully changed',
    wrongPassword: 'Wrong password',
    weakPassword: 'Password doesn\'t match requirements',
    passwordsNotMatch: 'Passwords don\'t password',
    requirements: 'New password should have at least 12 characters, 1 upper and lowercase letter and 1 number '
  },
  auth: {
    login: 'Login',
    password: 'Password',
    restorePass: 'Restore password',
    submit: 'Submit',
    loginError: 'Login and/or password are invalid'
  },
  restorePassForm: {
    restore: 'Restore',
    cancel: 'Cancel',
    info: 'Please enter your e-mail, which registered in OSA Hybrid Platform',
    emailInvalid: 'Submitted email was not found',
    email: 'Email',
    success: 'Restore link was sent to your email'
  },
  search: {
    notFound: 'Not found'
  },
  noMatchPage: {
    title: 'Requested page not found',
    body: 'Return to homepage'
  },
  warnings: {
    title: 'Warning',
    isDesktop: 'Uploading photos is available only in the mobile version of the system. Use the camera on your phone or tablet, please.'
  },
  errors: {
    netErrorText: 'Network error. Please, reload the page',
    netErrorReload: 'Reload',
    netErrorTitle: 'Error',
    netErrorClose: 'Close',
    noShops: 'Oops! Looks like you haven\'t assigned any stores yet. Tell your supervisor about this.',
    noCases: 'It seems that our service could not find any case in this store. Start creating them by yourself.',
    noAutoOrder: 'There are no tasks for autoorder correction.',
    causeError: 'Error',
    scenariosEmpty: 'Scenarios array is empty'
  },
  officeUI: {
    resetZoom: 'Reset zoom'
  },
  gridAutocomplete: {
    placeholder: 'Start typing shop name',
    notFound: 'Shop not found'
  },
  targets: {
    nothing: '',
    shop: 'Shop',
    category: 'Category',
    supplier: 'Supplier',
    vendor: 'Vendor',
    brand: 'Brand',
    plu: 'PLU',
    day: 'Day',
    week: 'Week',
    month: 'Month',
    quarter: 'Quarter',
    year: 'Year'
  },
  bubbleChart: {
    qty: 'qty',
    currency: 'rub'
  },
  chart: {
    quarter: 'quarter',
    week: 'week'
  },
  download: {
    CSV: 'Download CSV',
    XLS: 'Download XLS'
  },
  counterTask: {
    task: 'Pending tasks',
    noTask: 'No tasks'
  },
  filtersShop: {
    shopName: 'Store address',
    shopId: 'Store name'
  },
  chartCaption: {
    thousand: 'k',
    million: 'M',
    billion: 'B'
  },
  common: {
    close: 'Close',
    userNotFound: 'User not found',
    yes: 'Yes',
    no: 'No',
  },
  virtualStock: {
    headers: {
      case_id: "ID",
      plu_original: "PLU",
      plu: "Internal PLU",
      shop_id: "Shop ID",
      ass_type: "Assortment Type",
      category_id3: "Section ID",
      category_id4: "Category ID",
      stock: "Volume of residues",
      stock_sum: "Amount of balances",
      product_turnover: "Turnover",
      days_without_sales: "Days without sales",
      last_sales_date: "Last sale date",
      last_sales_volume: "Last sale volume",
      last_shipment_date: "Last shipment date",
      last_shipment_volume: "Last shipment volume",
      promo_start: "Promo Start",
      promo_end: "Promo End",
      promo_period: "Promo Period",
      is_promo: "Promo",
      title: "Name",
      category_name3: "Section",
      category_name4: "Category"
    },
    hideReadyTasks: "Hide ready tasks",
    nothingFound: "Nothing Found",
    shopsNotFound: "Shops not found",
    chooseShop: "Choose a shop",
    createTasks: "Create tasks",
    taskCreating: "Creating tasks",
    next: "Continue",
    columns: "Columns",
    from: "From",
    ok: "OK",
    cancel: "Cancel",
    filter: "Filter",
    chooseValue: "Choose a value",
    enterValue: "Enter value",
    rangeFrom: "From",
    rangeTo: "To",
    selected: "Selected",
    foundRows: "Found rows",
    createdTasksTotal: "Created tasks total"
  }
};
