export default {
  mobileFirefoxAlert: 'Katika kivinjari hiki, usindikaji wa kazi haipatikani.',
  datePicker: {
    from: 'Kutoka',
    to: 'Kwenda',
    cancel: 'Ghairi',
    apply: 'Tumia',
    month: [
      'Januari',
      'Februari',
      'Machi',
      'Aprili',
      'Mei',
      'Juni',
      'Julai',
      'Agosti',
      'Septemba',
      'Oktoba',
      'Novemba',
      'Desemba'
    ],
    week: 'wiki',
    weekShot: 'w',
    buttonName: {
      date: 'Tarehe',
      week: 'Wiki',
      month: 'Mwezi'
    },
    modal: {
      header: 'Onyo',
      text: 'Tarehe isiyofaa imechaguliwa',
      action: 'Kubali'
    }
  },
  filters: {
    name: 'Jina'
  },
  scenario: {
    noDataReceived: 'Hakuna data iliyopokelewa',
    filterPart: {
      name: 'Jina',
      clear: 'Ondoa',
      notFound: 'Haijapatikana',
      search: 'Tafuta...'
    }
  },
  header: {
    logout: 'Kutoka',
    changePassword: 'Badilisha nenosiri',
    settings: 'Vipimo',
    title: 'Tazama interface ya mtumiaji',
    searchTitle: 'Tafuta mtumiaji',
    input: 'Anza kuingiza jina au ingia...',
    cancel: 'Ghairi',
    view: 'Tazama',
    id: 'ID',
    login: 'Ingia',
    name: 'Jina',
    phone: 'Simu',
    email: 'Barua pepe',
    stopSettings: 'Acha kuangalia'
  },
  directTask: {
    addCase: 'Ongeza kesi',
    commentCase: 'Toa maoni kuhusu kesi',
    actions: 'Vitendo',
    searchProduct: 'PLU/Barcode',
    searchProductShort: 'PLU/Barcode',
    photo: 'Picha',
    back: 'Nyuma',
    treeEmpty: 'VITENDO HAVIPATIKANI',
    step: 'Hatua',
    sortingButton: 'Utaratibu',
    sortButton: 'Panga',
    add: 'Ongeza',
    delete: 'Futa',
    deleteManualCases: 'Futa kesi za mwongozo',
    show: 'Onesha',
    hide: 'Ficha',
    comment: 'Maoni',
    showDoneCases: 'Onyesha kazi zilizokamilishwa',
    hideDoneCases: 'Ficha kazi zilizokamilishwa'
  },
  gridTable: {
    pluList: 'Orodha ya PLU'
  },
  casesForm: {
    product: 'Bidhaa',
    loadFoto: 'Pakia Picha',
    plu: 'Jina/PLU',
    success: 'Kesi imeundwa',
    delete: 'Futa',
    add: 'Unda',
    new: 'Mpya',
    cancel: 'Ghairi',
    comment: 'Maoni'
  },
  pagination: {
    apply: 'Nenda',
    placeholder: 'Ukurasa'
  },
  restorePass: {
    noLength: 'Nenosiri lazima liwe na herufi angalau 12',
    noLowercase: 'Nenosiri lazima liwe na herufi ndogo angalau moja',
    noUppercase: 'Nenosiri lazima liwe na herufi kubwa angalau moja',
    noNumber: 'Nenosiri lazima liwe na nambari angalau moja',
    noMatch: 'Nenosiri hazifanani',
    password: 'Nenosiri',
    repeatPassword: 'Rudia nenosiri',
    ok: 'Kubali',
    cancel: 'Ghairi'
  },
  navigation: {
    missions: 'Misheni',
    scenarios: 'Matukio'
  },
  changePassword: {
    previousPassword: 'Nenosiri la sasa',
    newPassword: 'Nenosiri mpya',
    confirmPassword: 'Thibitisha nenosiri jipya',
    apply: 'Tumia',
    title: 'Badilisha nenosiri',
    success: 'Nenosiri imebadilishwa kwa mafanikio',
    wrongPassword: 'Nenosiri sio sahihi',
    weakPassword: 'Nenosiri haikidhi mahitaji',
    passwordsNotMatch: 'Nenosiri hazifanani',
    requirements: 'Nenosiri mpya lazima liwe na herufi angalau 12, herufi kubwa na ndogo moja, na nambari moja ' +
      'Nenosiri mpya lazima liwe na herufi angalau 12, herufi kubwa na ndogo moja, na nambari moja '
  },
  auth: {
    login: 'Ingia',
    password: 'Nenosiri',
    restorePass: 'Rejesha nenosiri',
    submit: 'Tuma',
    loginError: 'Jina la mtumiaji na/au nenosiri sio sahihi'
  },
  restorePassForm: {
    restore: 'Rejesha',
    cancel: 'Ghairi',
    info: 'Tafadhali ingiza barua pepe yako, iliyojisajili katika Jukwaa la OSA Hybrid',
    emailInvalid: 'Barua pepe iliyoingiliwa haikuonekana',
    email: 'Barua pepe',
    success: 'Kiungo cha urejesha kimetumwa kwa barua pepe yako'
  },
  search: {
    notFound: 'Haijapatikana'
  },
  noMatchPage: {
    title: 'Ukurasa uliotakiwa haupatikani',
    body: 'Rudi kwenye ukurasa wa nyumbani'
  },
  warnings: {
    title: 'Onyo',
    isDesktop: 'Upakiaji wa picha unapatikana tu kwenye toleo la rununu la mfumo. Tafadhali tumia kamera kwenye simu yako au kibao.'
  },
  errors: {
    netErrorText: 'Hitilafu ya mtandao. Tafadhali, pakia upya ukurasa',
    netErrorReload: 'Pakia upya',
    netErrorTitle: 'Hitilafu',
    netErrorClose: 'Funga',
    noShops: 'OH! Inaonekana bado hakuna maduka ambayo yamekabidhiwa kwako. Mwambie meneja wako kuhusu hilo',
    noCases: 'Inaonekana kwamba huduma yetu haikuweza kupata kesi moja katika duka hili. Anza kuunda wewe mwenyewe',
    noAutoOrder: 'Hakuna kazi za kurekebisha mpangilio wa kiotomatiki',
    causeError: 'Hitilafu',
    scenariosEmpty: 'Safu ya matukio imejazwa'
  },
  officeUI: {
    resetZoom: 'Rejesha ukubwa'
  },
  gridAutocomplete: {
    placeholder: 'Anza kuingiza jina la duka',
    notFound: 'Duka halijapatikana'
  },
  targets: {
    nothing: '',
    shop: 'Duka',
    category: 'Jamii',
    supplier: 'Muuzaji',
    vendor: 'Mtoa huduma',
    brand: 'Chapa',
    plu: 'PLU',
    day: 'Siku',
    week: 'Wiki',
    month: 'Mwezi',
    quarter: 'Robo',
    year: 'Mwaka'
  },
  bubbleChart: {
    qty: 'idadi',
    currency: 'rub'
  },
  chart: {
    quarter: 'robo',
    week: 'wiki'
  },
  download: {
    CSV: 'Pakua CSV',
    XLS: 'Pakua XLS'
  },
  counterTask: {
    task: 'Kazi zilizosubiri',
    noTask: 'Hakuna kazi'
  },
  filtersShop: {
    shopName: 'Anwani ya duka',
    shopId: 'Jina la duka'
  },
  chartCaption: {
    thousand: 'elfu',
    million: 'M',
    billion: 'B'
  },
  common: {
    close: 'Funga',
    userNotFound: 'Mtumiaji hapatikani',
    yes: 'Ndio',
    no: 'Hapana',
  },
  virtualStock: {
    headers: {
      case_id: "ID",
      plu_original: "PLU",
      plu: "PLU ya ndani",
      shop_id: "ID ya Duka",
      ass_type: "Aina ya bidhaa",
      category_id3: "ID ya Sehemu",
      category_id4: "ID ya Jamii",
      stock: "Kiasi cha bidhaa",
      stock_sum: "Jumla ya mizani",
      product_turnover: "Mzunguko",
      days_without_sales: "Siku bila mauzo",
      last_sales_date: "Tarehe ya mwisho ya kuuza",
      last_sales_volume: "Kiasi cha mauzo ya mwisho",
      last_shipment_date: "Tarehe ya mwisho ya kujifungua",
      last_shipment_volume: "Kiasi cha mwisho cha utoaji",
      promo_start: "Kuanza kwa Promo",
      promo_end: "Mwisho wa Promo",
      promo_period: "Kipindi cha Promo",
      is_promo: "Promo",
      title: "Jina",
      category_name3: "Sehemu",
      category_name4: "Jamii"
    },
    hideReadyTasks: "Ficha kazi zilizokamilika",
    nothingFound: "Hakuna Kilichopatikana",
    shopsNotFound: "Maduka hayajapatikana",
    chooseShop: "Chagua duka",
    createTasks: "Unda kazi",
    taskCreating: "Kuunda kazi",
    next: "Endelea",
    columns: "Safu",
    from: "Kutoka",
    ok: "Sawa",
    cancel: "Ghairi",
    filter: "Chuja",
    chooseValue: "Chagua thamani",
    enterValue: "Ingiza thamani",
    rangeFrom: "Kutoka",
    rangeTo: "Kwenda",
    selected: "Imechaguliwa",
    foundRows: "Vilivyoonekana",
    createdTasksTotal: "Jumla ya kazi zilizoundwa"
  }
};
