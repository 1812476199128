import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactHighcharts from 'react-highcharts';
import { SwatchesPicker } from 'react-color';
import HighchartsMore from 'highcharts-more';
import ColorPickerIcon from '@material-ui/icons/ColorLens';
import { connect } from 'react-redux';
import styles from './Chart.css';
import ExportDataComponent from '../../ExportDataComponent';
import UiElement from '../../UiElement/UiElement';

HighchartsMore(ReactHighcharts.Highcharts);

const setOptionForHighcharts = data => {
  ReactHighcharts.Highcharts.setOptions({
    lang: {
      resetZoom: data,
      thousandsSep: ' '
    }
  });
};

class Chart extends UiElement {
  static setDefaultDataToInvalidDataChart(input) {
    return {
      ...input,
      source: input.source.map(data => {
        return {
          ...data,
          data: [0]
        };
      })
    };
  }
  constructor(props) {
    super(props);
    this.bubbleChart = React.createRef();
  }

  state = {
    chartColor: '#42bde8',
    chartDefaultColors: [
      '#42bde8',
      '#3B3B3F',
      '#1c5787',
      '#d4dff0',
      '#6DA67A',
      '#99A66D',
      '#A9BD68',
      '#B5CC6A',
      '#C0DE5D',
      '#FE4365',
      '#FC9D9A',
      '#F9CDAD',
      '#C8C8A9',
      '#83AF9B',
      '#EEE6AB',
      '#C5BC8E',
      '#696758',
      '#45484B',
      '#36393B',
      '#30261C',
      '#403831',
      '#36544F',
      '#1F5F61',
      '#ffee90'
    ],
    customBarColor: '#42bde8',
    colorPickerOpen: false,
    customColorPickerOpen: false,
    itemToColor: 0,
    itemGlobalToColor: 0,
    kpiToColor: '',
    kpiList: [],
    source: [],
    propsData: {},
    exportCSVConfig: {},
    config: {},
    dateToCheck: [],
    inputDataIsValid: true
  };

  componentDidMount() {
    const { data } = this.props;
    const initData = JSON.parse(JSON.stringify(data));
    const { translation } = this.props.language;
    setOptionForHighcharts(translation.officeUI.resetZoom);
    this.transformData(initData);
  }

  componentWillReceiveProps(nextProps) {
    const { data } = nextProps;
    const nextPropsData = JSON.parse(JSON.stringify(data));
    this.transformData(nextPropsData);
  }

  shouldComponentUpdate(nextProps) {
    return !this.props.updatesLocked && this.props.updatesLocked === nextProps.updatesLocked;
  }

  median = values => {
    values.sort(function(a, b) {
      return a - b;
    });

    if (values.length === 0) return 0;

    var half = Math.floor(values.length / 2);

    if (values.length % 2) return values[half];
    else return (values[half - 1] + values[half]) / 2.0;
  };

  plotBandsMutate = (target, data, xNamesData) => {
    data.map(item => {
      const newFrom = this.dateNamesTransformHandle(target, [item.from]);
      const newTo = this.dateNamesTransformHandle(target, [item.to]);

      const fromIndex = xNamesData.indexOf(newFrom[0]);
      const toIndex = xNamesData.indexOf(newTo[0]);

      item.from = fromIndex - 0.5;
      item.to = toIndex + 0.5;
      item.color = 'rgba(66, 189, 232, .1)';
    });

    return data;
  };

  transformData = data => {
    const { chartColor } = this.state;
    const { height } = this.props.data;
    const names = ['x', 'y', 'z', 'q', 'w', 'r'];
    let propsData = data.data;
    const sources = propsData.source.map(item => item.name);
    const arr = [];
    const h = height || 1;
    let calcHeight = 120;
    let inputDataIsValid = true;

    if (propsData.source.every(item => item.data.length === 0)) {
      propsData = Chart.setDefaultDataToInvalidDataChart(propsData);
      inputDataIsValid = false;
    }

    if (height > 1) {
      calcHeight = 163 * h - 43;
    }

    propsData.chart = {
      type: propsData.type,
      height: calcHeight,
      plotBorderWidth: 1,
      zoomType: 'xy'
    };

    propsData.xAxis = {
      categories: undefined,
      data: propsData.xNames.data,
      title: {
        text: propsData.source[0].name
      }
    };
    propsData.yAxis = {
      categories: undefined,
      data: propsData.yNames.data,
      title: {
        text: propsData.source[1].name
      },
      plotLines: [
        {
          color: 'black',
          dashStyle: 'dot',
          width: 2,
          value: 0,
          zIndex: 3
        }
      ]
    };

    const datesSourceOptions = ['day', 'week', 'month', 'quarter', 'year'];

    if (datesSourceOptions.indexOf(propsData.xNames.source) !== -1) {
      const value = datesSourceOptions[datesSourceOptions.indexOf(propsData.xNames.source)];
      propsData.xAxis.data = this.dateNamesTransformHandle(value, propsData.xNames.data);

      if (propsData.xNames.plotBands) {
        propsData.xAxis.plotBands = this.plotBandsMutate(value, propsData.xNames.plotBands, propsData.xNames.data);
      }
    }

    if (datesSourceOptions.indexOf(propsData.yNames.source) !== -1) {
      const value = datesSourceOptions[datesSourceOptions.indexOf(propsData.yNames.source)];
      propsData.yAxis.data = this.dateNamesTransformHandle(value, propsData.yNames.data);
    }

    propsData.source.map((item, key) => {
      item.data.map((value, index) => {
        const type = item.dimension ? item.dimension : '';
        const typeItem = `type_${key}`;
        const source = `source_${key}`;

        // Highcharts does not format values properly.
        // That's why we need custom  property for display it on tooltip

        const toolTip = `${names[key]}Tooltip`;
        arr[index] = {
          ...arr[index],
          [names[key]]: value,
          [toolTip]: ('' + value).replace(/\B(?=(\d{3})+(?!\d))/g, ' '),
          name: propsData.xAxis.data[index],
          [source]: sources[key],
          [typeItem]: type,
          color: chartColor,
          marker: {
            fillColor: chartColor,
            lineColor: chartColor,
            width: 15,
            height: 15
          }
        };
      });
    });

    const xValues = arr.map(item => item.x);
    const xMedian = Math.round(this.median(xValues) * 100) / 100;
    const xMedianTooltip = ('' + xMedian).replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    const yValues = arr.map(item => item.y);
    const yMedian = Math.round(this.median(yValues) * 100) / 100;
    const yMedianTooltip = ('' + yMedian).replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    propsData.xAxis.plotLines = [
      {
        color: '#666666',
        dashStyle: 'dot',
        width: 2,
        value: xMedian,
        label: {
          rotation: 90,
          style: {
            fontStyle: 'italic',
            color: '#666666'
          },
          y: 15,
          text: arr[0].source_0 ? `${arr[0].source_0} (${xMedianTooltip})` : 'median'
        },
        zIndex: 3
      },
      {
        color: '#666666',
        dashStyle: 'dot',
        width:
          arr.filter(item => {
            const newItem = item.x === null ? 0 : item.x;
            return newItem < 0;
          }).length > 0
            ? 1
            : 0,
        value: 0,
        zIndex: 3
      }
    ];

    propsData.yAxis.plotLines = [
      {
        color: '#666666',
        dashStyle: 'dot',
        width: 2,
        value: yMedian,
        label: {
          align: 'right',
          style: {
            fontStyle: 'italic',
            color: '#666666'
          },
          text: arr[0].source_1 ? `${arr[0].source_1} (${yMedianTooltip})` : 'median',
          x: -10
        },
        zIndex: 3
      },
      {
        color: '#666666',
        dashStyle: 'dot',
        width:
          arr.filter(item => {
            const newItem = item.x === null ? 0 : item.x;
            return newItem < 0;
          }).length > 0
            ? 1
            : 0,
        value: 0,
        zIndex: 3
      }
    ];

    propsData.source = [
      {
        data: arr
      }
    ];

    this.setState(
      {
        propsData: propsData,
        source: sources,
        inputDataIsValid: inputDataIsValid
      },
      () => {
        this.prepareConfig(this.state.propsData);
        this.exportCSVConfig();
      }
    );
  };

  dateNamesTransformHandle = (target, data) => {
    const { translation } = this.props.language;
    if (!data.length) {
      return data;
    }

    const weeksCount = date => {
      const d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
      d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
      const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
      const weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
      return [d.getUTCFullYear(), weekNo];
    };

    switch (target) {
      case 'day':
        return data.map(item =>
          item
            .split('-')
            .reverse()
            .join('.')
        );
      case 'week':
        return data.map(item => {
          const targetDate = new Date(item);
          const weeks = weeksCount(targetDate);
          return `${weeks[1]} ${translation.chart.week} ${weeks[0]}`;
        });
      case 'month':
        return data.map(item => {
          const newItem = item.split('-').reverse();
          newItem.shift();
          return newItem.join('.');
        });
      case 'quarter':
        return data.map(item => {
          const newItem = item.split('-').reverse();
          const quarter = Math.ceil(+newItem[1] / 3);
          return `${quarter} ${translation.chart.quarter} ${newItem[2]}`;
        });
      case 'year':
        return data.map(item => item.split('-')[0]);
      default:
        return data;
    }
  };

  prepareConfig = propsData => {
    const { transition, datePickerDate, transitionIsActive } = this.props;

    const subScenarioId = propsData['sub-scenario'] ? propsData['sub-scenario'] : 0;
    const targets = propsData.xNames.source;
    const dateObj = new Date();
    const month = dateObj.getUTCMonth() + 1;
    const day = dateObj.getUTCDate();
    const year = dateObj.getUTCFullYear();
    const dateNow = day + '_' + month + '_' + year;
    const that = this;
    const isAbleToTransition = transition && Object.keys(transition).length;
    const isAbleToDrill = subScenarioId > 0;

    propsData.plotType.series = {
      point: {
        events: {
          mouseOver: function() {
            if (isAbleToDrill && !that.props.updatesLocked) {
              this.dataLabel.css({
                cursor: 'pointer',
                textDecoration: 'underline'
              });
            } else if (isAbleToTransition && !that.props.updatesLocked) {
              this.dataLabel.css({
                cursor: 'pointer',
                textDecoration: 'underline'
              });
            } else {
              this.dataLabel.css({
                cursor: 'default',
                textDecoration: 'none'
              });
            }
          },
          mouseOut: function() {
            if (isAbleToDrill) {
              this.dataLabel.css({
                textDecoration: 'none'
              });
            } else if (isAbleToTransition) {
              this.dataLabel.css({
                textDecoration: 'none'
              });
            }
          }
        }
      },
      dataLabels: {
        color: `${isAbleToDrill ? '#1AA7E0' : '#000'}`,
        enabled: true,
        format: '{point.name}',
        style: {
          fontStyle: `${transition ? 'italic' : 'normal'}`
        }
      },
      stickyTracking: false,
      cursor: 'pointer',
      events: {
        click: function(e) {
          if (e.target.className.animVal.length) {
            const { chartDefaultColors } = that.state;
            const defaultColorState = chartDefaultColors.filter(item => item === e.point.color);
            that.setState({
              itemToColor: e.point.index,
              kpiToColor: this.userOptions.name,
              customColorPickerOpen: true,
              customBarColor: defaultColorState.length ? false : e.point.color
            });
          } else {
            const item = e.target.textContent;
            const { source, data } = propsData.xNames;
            let key;
            const isDate = new Date(propsData.xNames.data[0]);
            const isChecked = !isNaN(isDate.getDate());
            const dateCheck = that.dateNamesTransformHandle(source, data);
            const target = propsData.xNames.source;
            if (isChecked) {
              that.setState(
                {
                  dateToCheck: dateCheck
                },
                () => {
                  key = that.state.dateToCheck.indexOf(item);
                }
              );
            } else {
              key = data.indexOf(item);
            }
            if (isAbleToDrill && !that.props.updatesLocked) {
              const filter = that.prepareFilter(targets, propsData.xNames.resultSetAll[key]);
              that.getSubScenarioData(subScenarioId, filter, targets);
            } else if (isAbleToTransition && !that.props.updatesLocked) {
              const filter = that.prepareFilter(target, propsData.xNames.resultSetAll[key], transition, datePickerDate);
              that.groupTransition(filter, target);
              transitionIsActive(true);
            }
          }
        }
      }
    };

    const config = {
      chart: propsData.chart,
      legend: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      title: {
        text: propsData.name,
        style: {
          color: '#293258'
        }
      },
      exporting: {
        filename: `${dateNow}_${propsData.name}`,
        csv: {
          itemDelimiter: ';'
        },
        buttons: {
          contextButton: {
            enabled: true
          }
        }
      },
      plotOptions: propsData.plotType,
      xAxis: propsData.xAxis,
      yAxis: propsData.yAxis,
      series: propsData.source
    };

    if (propsData.chart.type === 'bubble') {
      config.tooltip = {
        useHTML: true,
        headerFormat: '<div>',
        pointFormat:
          '<h3>{point.name}</h3>' +
          '<div><b>{point.source_0}:</b> {point.xTooltip} {point.type_0}</div>' +
          '<div><b>{point.source_1}:</b> {point.yTooltip} {point.type_1}</div>' +
          '<div><b>{point.source_2}:</b> {point.zTooltip} {point.type_2}</div>',
        footerFormat: '</div>',
        followPointer: true
      };
    } else {
      config.tooltip = {
        useHTML: true,
        headerFormat: '<div>',
        pointFormat:
          '<h3>{point.name}</h3>' +
          '<div><b>{point.source_0}:</b> {point.xTooltip} {point.type_0}</div>' +
          '<div><b>{point.source_1}:</b> {point.yTooltip} {point.type_1}</div>',
        footerFormat: '</div>',
        followPointer: true,
        valueDecimals: 2
      };
    }

    const bubbleConfig = JSON.parse(JSON.stringify(config));
    bubbleConfig.xAxis.categories = propsData.xNames.data;

    if (this.state.inputDataIsValid) {
      this.setState({
        config: config
      });
    } else {
      this.setState({
        config: {
          chart: propsData.chart,
          legend: {
            enabled: false
          },
          credits: {
            enabled: false
          },
          title: {
            text: propsData.name,
            style: {
              color: '#293258'
            }
          },
          xAxis: propsData.xAxis,
          yAxis: propsData.yAxis,
          series: [0]
        }
      });
    }
  };

  exportCSVConfig = () => {
    const { data } = this.props.data;
    const dateObj = new Date();
    const month = dateObj.getUTCMonth() + 1;
    const day = dateObj.getUTCDate();
    const year = dateObj.getUTCFullYear();
    const dateNow = day + '_' + month + '_' + year;
    const config = {
      credits: {
        enabled: false
      },
      title: {
        text: data.name,
        style: {
          color: '#293258'
        }
      },
      exporting: {
        filename: `${dateNow}_${data.name}`,
        csv: {
          itemDelimiter: ';'
        },
        buttons: {
          contextButton: {
            enabled: false
          }
        }
      },
      legend: {
        itemStyle: {
          color: '#293258'
        }
      },
      plotOptions: data.plotType,
      xAxis: {
        categories: data.xNames.data,
        title: {
          text: data.xNames.source,
          enabled: false
        }
      },
      yAxis: {
        title: {
          text: ''
        }
      },
      series: data.source
    };

    this.setState({
      exportCSVConfig: config
    });
  };

  handleColorChange = color => {
    const { propsData } = this.state;
    this.setState(
      {
        chartColor: color.hex
      },
      () => {
        const { chartColor, itemGlobalToColor } = this.state;
        if (propsData.source[itemGlobalToColor] !== undefined) {
          propsData.source[itemGlobalToColor].data.map(item => {
            item.color = chartColor;
            item.marker = {
              fillColor: chartColor,
              lineColor: chartColor
            };
          });
        }
        this.setState({
          chartColor: this.state.chartColor,
          propsData: propsData,
          colorPickerOpen: false,
          itemGlobalToColor: 0
        });
      }
    );
  };

  iconClickHandle = () => {
    this.setState({
      colorPickerOpen: !this.state.colorPickerOpen
    });
  };

  overlayHandle = () => {
    this.setState({
      colorPickerOpen: false,
      itemGlobalToColor: 0,
      customColorPickerOpen: false
    });
  };

  handleCustomColorChange = color => {
    const { propsData } = this.state;
    this.setState(
      {
        customBarColor: color.hex
      },
      () => {
        const { itemToColor, customBarColor } = this.state;
        propsData.source[0].data[itemToColor].color = customBarColor;
        propsData.source[0].data[itemToColor].marker = {
          fillColor: customBarColor,
          lineColor: customBarColor
        };
        this.setState({
          propsData: propsData,
          customColorPickerOpen: false
        });
      }
    );
  };

  downloadCSV = () => {
    const chart = this.bubbleChart.current.getChart();
    chart.downloadCSV();
  };

  render() {
    const {
      config,
      exportCSVConfig,
      chartColor,
      colorPickerOpen,
      customColorPickerOpen,
      customBarColor,
      inputDataIsValid
    } = this.state;
    const { language, data } = this.props;
    const { height } = data;
    const h = height || 1;
    let calcHeight = 120;
    if (height > 1) {
      calcHeight = 163 * h - 43;
    }

    return (
      <div className={styles.chart} style={{ height: +calcHeight }}>
        {inputDataIsValid && (
          <Fragment>
            <div className={styles.topButtons}>
              <div role="presentation" className={styles.topButtonsButton} onClick={this.iconClickHandle}>
                <ColorPickerIcon style={{ color: chartColor }} className={styles.topButtonsIcon} />
              </div>
              <ExportDataComponent data={data} translation={language.translation} />
            </div>
            {(colorPickerOpen || customColorPickerOpen) && (
              <div role="presentation" className={styles.overlay} onClick={this.overlayHandle} />
            )}
            {colorPickerOpen && (
              <div className={styles.colorPickerPlugin}>
                <SwatchesPicker color={chartColor} onChangeComplete={this.handleColorChange} />
              </div>
            )}

            {customColorPickerOpen && (
              <div className={styles.colorPickerPlugin}>
                <SwatchesPicker color={customBarColor} onChangeComplete={this.handleCustomColorChange} />
              </div>
            )}
          </Fragment>
        )}
        <ReactHighcharts ref={this.originalChart} config={config} />
        <div style={{ display: 'none' }}>
          <ReactHighcharts config={exportCSVConfig} ref={this.bubbleChart} />
        </div>
      </div>
    );
  }
}

Chart.defaultProps = {
  data: {},
  language: {},
  updatesLocked: false
};

Chart.propTypes = {
  data: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  language: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  updatesLocked: PropTypes.bool, // eslint-disable-line react/forbid-prop-types
  filters: PropTypes.func.isRequired, // eslint-disable-line
  updateFilters: PropTypes.func.isRequired, // eslint-disable-line
  setDrillFilters: PropTypes.func.isRequired, // eslint-disable-line
  transition: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  transitionIsActive: PropTypes.func.isRequired, // eslint-disable-line
  datePickerDate: PropTypes.object.isRequired // eslint-disable-line react/forbid-prop-types
};

export default connect(store => ({
  language: store.language,
  parentScenario: store.parentScenario,
  datePickerDate: store.datePickerDate
}))(Chart);
