export default {
  mobileFirefoxAlert: 'Šioje naršyklėje užduoties apdorojimas negalimas.',
  datePicker: {
    from: 'Nuo',
    to: 'Iki',
    cancel: 'Atšaukti',
    apply: 'Taikyti',
    month: [
      'Sausis',
      'Vasaris',
      'Kovas',
      'Balandis',
      'Gegužė',
      'Birželis',
      'Liepa',
      'Rugpjūtis',
      'Rugsėjis',
      'Spalis',
      'Lapkritis',
      'Gruodis'
    ],
    week: 'savaitė',
    weekShot: 'sav',
    buttonName: {
      date: 'Data',
      week: 'Savaitė',
      month: 'Mėnuo'
    },
    modal: {
      header: 'Įspėjimas',
      text: 'Pasirinktas netinkamas datos intervalas',
      action: 'Patvirtinti'
    }
  },
  filters: {
    name: 'Vardas'
  },
  scenario: {
    noDataReceived: 'Duomenų negauta',
    filterPart: {
      name: 'Vardas',
      clear: 'Valyti',
      notFound: 'Nerasta',
      search: 'Paieška...'
    }
  },
  header: {
    logout: 'Atsijungti',
    changePassword: 'Pakeisti slaptažodį',
    settings: 'Nustatymai',
    title: 'Peržiūrėti vartotojo sąsają',
    searchTitle: 'Ieškoti vartotojo',
    input: 'Pradėkite rašyti vardą ar prisijungimo vardą...',
    cancel: 'Atšaukti',
    view: 'Peržiūra',
    id: 'ID',
    login: 'Prisijungimas',
    name: 'Vardas',
    phone: 'Telefonas',
    email: 'El. paštas',
    stopSettings: 'Sustabdyti peržiūrą'
  },
  directTask: {
    addCase: 'Pridėti atvejį',
    commentCase: 'komentuotiAtvejį',
    actions: 'Veiksmai',
    searchProduct: 'PLU/Barkodas',
    searchProductShort: 'PLU/Barkodas',
    photo: 'Nuotrauka',
    back: 'Atgal',
    treeEmpty: 'VEIKSMŲ NĖRA',
    step: 'Žingsnis',
    sortingButton: 'Rūšiuoti',
    sortButton: 'Rūšiavimas',
    add: 'Pridėti',
    delete: 'Ištrinti',
    deleteManualCases: 'Ištrinti rankinius atvejus',
    show: 'Rodyti',
    hide: 'Slėpti',
    comment: 'Komentaras',
    showDoneCases: 'Rodyti atliktas užduotis',
    hideDoneCases: 'Slėpti atliktas užduotis'
  },
  gridTable: {
    pluList: 'PLU sąrašas'
  },
  casesForm: {
    product: 'Prekė',
    loadFoto: 'Įkelti nuotrauką',
    plu: 'Pavadinimas/PLU',
    success: 'Atvejis sukurtas',
    delete: 'Ištrinti',
    add: 'Sukurti',
    new: 'Naujas',
    cancel: 'Atšaukti',
    comment: 'Komentaras'
  },
  pagination: {
    apply: 'Eiti',
    placeholder: 'Puslapis'
  },
  restorePass: {
    noLength: 'Slaptažodis turi būti bent 12 simbolių ilgio',
    noLowercase: 'Slaptažodyje turi būti bent viena mažoji raidė',
    noUppercase: 'Slaptažodyje turi būti bent viena didžioji raidė',
    noNumber: 'Slaptažodyje turi būti bent vienas skaitmuo',
    noMatch: 'Slaptažodžiai nesutampa',
    password: 'Slaptažodis',
    repeatPassword: 'Pakartokite slaptažodį',
    ok: 'Gerai',
    cancel: 'Atšaukti'
  },
  navigation: {
    missions: 'Užduotys',
    scenarios: 'Scenarijai'
  },
  changePassword: {
    previousPassword: 'Dabartinis slaptažodis',
    newPassword: 'Naujas slaptažodis',
    confirmPassword: 'Pakartotinai įveskite naują slaptažodį',
    apply: 'Taikyti',
    title: 'Pakeisti slaptažodį',
    success: 'Slaptažodis sėkmingai pakeistas',
    wrongPassword: 'Neteisingas slaptažodis',
    weakPassword: 'Slaptažodis neatitinka reikalavimų',
    passwordsNotMatch: 'Slaptažodžiai nesutampa',
    requirements: 'Naujam slaptažodžiui turi būti bent 12 simbolių, viena didžioji ir mažoji raidė bei bent vienas skaitmuo'
  },
  auth: {
    login: 'Prisijungti',
    password: 'Slaptažodis',
    restorePass: 'Atkurti slaptažodį',
    submit: 'Pateikti',
    loginError: 'Neteisingas prisijungimo vardas ir/ar slaptažodis'
  },
  restorePassForm: {
    restore: 'Atkurti',
    cancel: 'Atšaukti',
    info: 'Įveskite savo el. paštą, kuris yra užregistruotas OSA Hybrid Platformoje',
    emailInvalid: 'Pateiktas el. paštas nerastas',
    email: 'El. paštas',
    success: 'Atkūrimo nuoroda išsiųsta į jūsų el. paštą'
  },
  search: {
    notFound: 'Nerasta'
  },
  noMatchPage: {
    title: 'Puslapis nerastas',
    body: 'Grįžti į pagrindinį puslapį'
  },
  warnings: {
    title: 'Įspėjimas',
    isDesktop: 'Nuotraukų įkėlimas įmanomas tik mobiliajame sistemos versijoje. Prašome naudoti telefono ar planšetės kamerą.'
  },
  errors: {
    netErrorText: 'Tinklo klaida. Prašome perkrauti puslapį',
    netErrorReload: 'Pakartoti',
    netErrorTitle: 'Klaida',
    netErrorClose: 'Uždaryti',
    noShops: 'OI! Panašu, kad jums dar nepriskirta jokia parduotuvė. Pasakykite apie tai savo vadovui',
    noCases: 'Atrodo, kad mūsų tarnyba šioje parduotuvėje negalėjo rasti nei vieno atvejo. Pradėkite juos kurti patys',
    noAutoOrder: 'Nėra užduočių automatiniam užsakymui koreguoti',
    causeError: 'Klaida',
    scenariosEmpty: 'Scenarijų masyvas yra tuščias'
  },
  officeUI: {
    resetZoom: 'Atstatyti mastelį'
  },
  gridAutocomplete: {
    placeholder: 'Pradėkite rašyti parduotuvės pavadinimą',
    notFound: 'Parduotuvė nerasta'
  },
  targets: {
    nothing: '',
    shop: 'Parduotuvė',
    category: 'Kategorija',
    supplier: 'Tiekėjas',
    vendor: 'Tiekėjas',
    brand: 'Prekės ženklas',
    plu: 'PLU',
    day: 'Diena',
    week: 'Savaitė',
    month: 'Mėnuo',
    quarter: 'Ketvirtis',
    year: 'Metai'
  },
  bubbleChart: {
    qty: 'kiekis',
    currency: 'eur'
  },
  chart: {
    quarter: 'ketvirtis',
    week: 'savaitė'
  },
  download: {
    CSV: 'Atsisiųsti CSV',
    XLS: 'Atsisiųsti XLS'
  },
  counterTask: {
    task: 'Laukiančios užduotys',
    noTask: 'Nėra užduočių'
  },
  filtersShop: {
    shopName: 'Parduotuvės adresas',
    shopId: 'Parduotuvės pavadinimas'
  },
  chartCaption: {
    thousand: 'tūkst.',
    million: 'mln.',
    billion: 'mlrd.'
  },
  common: {
    close: 'Uždaryti',
    userNotFound: 'Naudotojas nerastas',
    yes: 'Taip',
    no: 'Ne'
  },
  virtualStock: {
    headers: {
      case_id: 'ID',
      plu_original: 'PLU',
      plu: 'Vidinis PLU',
      shop_id: 'Parduotuvės ID',
      ass_type: 'Asortimento tipas',
      category_id3: 'Skyriaus ID',
      category_id4: 'Kategorijos ID',
      stock: 'Likučio apimtis',
      stock_sum: 'Balanso suma',
      product_turnover: 'Apsukos',
      days_without_sales: 'Dienos be pardavimų',
      last_sales_date: 'Paskutinė pardavimo data',
      last_sales_volume: 'Paskutinis pardavimo kiekis',
      last_shipment_date: 'Paskutinė pristatymo data',
      last_shipment_volume: 'Paskutinis pristatymo kiekis',
      promo_start: 'Aktyvavimo pradžia',
      promo_end: 'Aktyvavimo pabaiga',
      promo_period: 'Aktyvavimo laikotarpis',
      is_promo: 'Aktyvavimas',
      title: 'Pavadinimas',
      category_name3: 'Skyrius',
      category_name4: 'Kategorija'
    },
    hideReadyTasks: 'Slėpti paruoštus užduotis',
    nothingFound: 'Nieko nerasta',
    shopsNotFound: 'Parduotuvės nerastos',
    chooseShop: 'Pasirinkite parduotuvę',
    createTasks: 'Kurti užduotis',
    taskCreating: 'Kuriama užduotis',
    next: 'Toliau',
    columns: 'Stulpeliai',
    from: 'Nuo',
    ok: 'Gerai',
    cancel: 'Atšaukti',
    filter: 'Filtruoti',
    chooseValue: 'Pasirinkite reikšmę',
    enterValue: 'Įveskite reikšmę',
    rangeFrom: 'Nuo',
    rangeTo: 'Iki',
    selected: 'Pasirinkta',
    foundRows: 'Rasta eilučių',
    createdTasksTotal: 'Iš viso sukurtų užduočių'
  }
};

