import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import ClearIcon from '@material-ui/icons/Clear';

import styles from './Display.css';

const style = {
  root: {
    width: 17,
    height: 17,
    minHeight: 17,
    backgroundColor: '#03a7e0',
    color: '#fff',
    margin: 10,
    boxShadow: 'none',
    '&:hover' : {
      backgroundColor: '#03a7e0',
    }
  },
  ssmal: {
    fontSize: 11
  }
};

class FilterPartDisplay extends Component {
  /**
   *
   * @param props
   */
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(nextProps) {
    let nextValues = nextProps.display;
    let values = this.props.display;

    return nextValues.every(item => {
      return values.indexOf(item) !== -1;
    });
  }

  /**
   *
   */
  componentWillUnmount() {}

  /**
   *
   * @param item
   */
  handleChooseItem = item => {
    this.props.chooseItem(item);
  };

  render() {
    const { name, display, kpiNames, kpi, classes } = this.props;
    return (
      <div>
        <div className={styles.head}>
          <div className={styles.headName}>{name}</div>
          {kpiNames.map((kpiName, kpiIndex) => {
            const index = kpiIndex;
            return (
              <div key={index} class={styles.headColumn}>
                <div className={styles.headColumnText}>{kpiName}</div>
              </div>
            );
          })}
        </div>
        <div className={styles.body}>
          {display.map(item => {
            return (
              <div key={item.id} className={styles.item}>
                <Fab class={classes.root} aria-label="clear" onClick={() => this.handleChooseItem(item)}>
                  <ClearIcon class={classes.ssmal} />
                </Fab>
                <button className={styles.itemName}>{item.name}</button>
                {kpi.map(kpiItem => {
                  const valObj = item.metrics.filter(obj => {
                    return obj.identity === kpiItem.compiled_source;
                  });
                  const valItem = valObj[0];

                  let value = valItem && valItem.hasOwnProperty('value') ? valItem.value : 0;
                  if (isNaN(parseInt(value))) {
                    value = 0;
                  }
                  return (
                    <div key={kpiItem.source} className={styles.itemColumn}>
                      {value}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

FilterPartDisplay.defaultProps = {
  kpi: [],
  display: []
};

FilterPartDisplay.propTypes = {
  classes: PropTypes.object,
  kpi: PropTypes.array.isRequired, // eslint-disable-line
  kpiNames: PropTypes.array.isRequired, // eslint-disable-line
  display: PropTypes.array.isRequired, // eslint-disable-line
  name: PropTypes.string.isRequired, // eslint-disable-line
  chooseItem: PropTypes.func.isRequired // eslint-disable-line
};

export default withStyles(style)(FilterPartDisplay);
