export default {
  mobileFirefoxAlert: 'V tomto prehliadači nie je spracovanie úloh dostupné.',
  datePicker: {
    from: 'Od',
    to: 'Do',
    cancel: 'Zrušiť',
    apply: 'Použiť',
    month: [
      'Január',
      'Február',
      'Marec',
      'Apríl',
      'Máj',
      'Jún',
      'Júl',
      'August',
      'September',
      'Október',
      'November',
      'December'
    ],
    week: 'týždeň',
    weekShot: 'týž.',
    buttonName: {
      date: 'Dátum',
      week: 'Týždeň',
      month: 'Mesiac'
    },
    modal: {
      header: 'Upozornenie',
      text: 'Vybraný neplatný rozsah dátumov',
      action: 'Akceptovať'
    }
  },
  filters: {
    name: 'Meno'
  },
  scenario: {
    noDataReceived: 'Žiadne údaje nezískané',
    filterPart: {
      name: 'Meno',
      clear: 'Vymazať',
      notFound: 'Nenájdené',
      search: 'Hľadať...'
    }
  },
  header: {
    logout: 'Odhlásiť sa',
    changePassword: 'Zmeniť heslo',
    settings: 'Nastavenia',
    title: 'Zobraziť užívateľské rozhranie',
    searchTitle: 'Hľadať užívateľa',
    input: 'Začnite písať meno alebo prihlásenie...',
    cancel: 'Zrušiť',
    view: 'Zobraziť',
    id: 'ID',
    login: 'Prihlásenie',
    name: 'Meno',
    phone: 'Telefón',
    email: 'E-mail',
    stopSettings: 'Zastaviť zobrazenie'
  },
  directTask: {
    addCase: 'Pridať prípad',
    commentCase: 'Komentár k prípadu',
    actions: 'Akcie',
    searchProduct: 'PLU/Čiarový kód',
    searchProductShort: 'PLU/Čiarový kód',
    photo: 'Fotka',
    back: 'Späť',
    treeEmpty: 'AKCIE NIE SÚ DOSTUPNÉ',
    step: 'Krok',
    sortingButton: 'Zoradenie',
    sortButton: 'Zoradiť',
    add: 'Pridať',
    delete: 'Vymazať',
    deleteManualCases: 'Vymazať manuálne prípady',
    show: 'Zobraziť',
    hide: 'Skryť',
    comment: 'Komentár',
    showDoneCases: 'Zobraziť dokončené úlohy',
    hideDoneCases: 'Skryť dokončené úlohy'
  },
  gridTable: {
    pluList: 'Zoznam PLU'
  },
  casesForm: {
    product: 'Produkt',
    loadFoto: 'Načítať fotku',
    plu: 'Názov/PLU',
    success: 'Prípad vytvorený',
    delete: 'Odstrániť',
    add: 'Vytvorte',
    new: 'Nový',
    cancel: 'Zrušiť',
    comment: 'Komentár'
  },
  pagination: {
    apply: 'Prejsť',
    placeholder: 'Stránka'
  },
  restorePass: {
    noLength: 'Heslo musí mať aspoň 12 znakov',
    noLowercase: 'Heslo musí obsahovať aspoň jedno malé písmeno',
    noUppercase: 'Heslo musí obsahovať aspoň jedno veľké písmeno',
    noNumber: 'Heslo musí obsahovať aspoň jedno číslo',
    noMatch: 'Heslá sa nezhodujú',
    password: 'Heslo',
    repeatPassword: 'Zopakovať heslo',
    ok: 'Potvrdiť',
    cancel: 'Zrušiť'
  },
  navigation: {
    missions: 'Misie',
    scenarios: 'Scenáre'
  },
  changePassword: {
    previousPassword: 'Súčasné heslo',
    newPassword: 'Nové heslo',
    confirmPassword: 'Potvrdenie nového hesla',
    apply: 'Použiť',
    title: 'Zmena hesla',
    success: 'Heslo bolo úspešne zmenené',
    wrongPassword: 'Nesprávne heslo',
    weakPassword: 'Heslo nespĺňa požiadavky',
    passwordsNotMatch: 'Heslá sa nezhodujú',
    requirements: 'Nové heslo musí mať aspoň 12 znakov, 1 veľké a malé písmeno a 1 číslo'
  },
  auth: {
    login: 'Prihlásenie',
    password: 'Heslo',
    restorePass: 'Obnoviť heslo',
    submit: 'Odoslať',
    loginError: 'Prihlasovacie údaje sú neplatné'
  },
  restorePassForm: {
    restore: 'Obnoviť',
    cancel: 'Zrušiť',
    info: 'Prosím, zadajte váš e-mail, ktorý je zaregistrovaný v platforme OSA Hybrid',
    emailInvalid: 'Zadaný e-mail nebol nájdený',
    email: 'E-mail',
    success: 'Odkaz na obnovenie bol odoslaný na váš e-mail'
  },
  search: {
    notFound: 'Nenájdené'
  },
  noMatchPage: {
    title: 'Požadovaná stránka sa nenašla',
    body: 'Späť na úvodnú stránku'
  },
  warnings: {
    title: 'Varovanie',
    isDesktop: 'Nahrávanie fotografií je k dispozícii iba v mobilnej verzii systému. Prosím, použite fotoaparát na svojom telefóne alebo tablete.'
  },
  errors: {
    netErrorText: 'Chyba siete. Prosím, obnovte stránku',
    netErrorReload: 'Obnoviť',
    netErrorTitle: 'Chyba',
    netErrorClose: 'Zavrieť',
    noShops: 'Ó! Zdá sa, že vám ešte neboli priradené žiadne obchody. Povedzte o tom svojmu manažérovi',
    noCases: 'Zdá sa, že naša služba nenašla v tomto obchode žiadny prípad. Začnite ich vytvárať sami.',
    noAutoOrder: 'Neexistujú žiadne úlohy na úpravu automatického poradia',
    causeError: 'Chyba',
    scenariosEmpty: 'Pole scenárií je prázdne'
  },
  officeUI: {
    resetZoom: 'Resetovať priblíženie'
  },
  gridAutocomplete: {
    placeholder: 'Začnite písať názov obchodu',
    notFound: 'Obchod nenájdený'
  },
  targets: {
    nothing: '',
    shop: 'Obchod',
    category: 'Kategória',
    supplier: 'Dodávateľ',
    vendor: 'Dodávateľ',
    brand: 'Značka',
    plu: 'PLU',
    day: 'Deň',
    week: 'Týždeň',
    month: 'Mesiac',
    quarter: 'Štvrťrok',
    year: 'Rok'
  },
  bubbleChart: {
    qty: 'množstvo',
    currency: '€'
  },
  chart: {
    quarter: 'štvrtrok',
    week: 'týždeň'
  },
  download: {
    CSV: 'Stiahnuť CSV',
    XLS: 'Stiahnuť XLS'
  },
  counterTask: {
    task: 'Očakávajúce úlohy',
    noTask: 'Žiadne úlohy'
  },
  filtersShop: {
    shopName: 'Adresa obchodu',
    shopId: 'Názov obchodu'
  },
  chartCaption: {
    thousand: 'tis.',
    million: 'mil.',
    billion: 'mld.'
  },
  common: {
    close: 'Zavrieť',
    userNotFound: 'Používateľ nebol nájdený',
    yes: 'Áno',
    no: 'Nie'
  },
  virtualStock: {
    headers: {
      case_id: 'ID',
      plu_original: 'PLU',
      plu: 'Interné PLU',
      shop_id: 'ID obchodu',
      ass_type: 'Typ sortimentu',
      category_id3: 'ID sekcie',
      category_id4: 'ID kategórie',
      stock: 'Objem zostatkov',
      stock_sum: 'Množstvo zostatkov',
      product_turnover: 'Obrat',
      days_without_sales: 'Dni bez predaja',
      last_sales_date: 'Dátum posledného predaja',
      last_sales_volume: 'Objem posledného predaja',
      last_shipment_date: 'Posledný termín dodania',
      last_shipment_volume: 'Posledný objem dodávky',
      promo_start: 'Začiatok akcie',
      promo_end: 'Koniec akcie',
      promo_period: 'Trvanie akcie',
      is_promo: 'Akcia',
      title: 'Názov',
      category_name3: 'Sekcia',
      category_name4: 'Kategória'
    },
    hideReadyTasks: 'Skryť hotové úlohy',
    nothingFound: 'Nič sa nenašlo',
    shopsNotFound: 'Obchod(y) nenájdené',
    chooseShop: 'Vyberte obchod',
    createTasks: 'Vytvoriť úlohy',
    taskCreating: 'Vytváranie úloh',
    next: 'Pokračovať',
    columns: 'Stĺpce',
    from: 'Od',
    ok: 'OK',
    cancel: 'Zrušiť',
    filter: 'Filter',
    chooseValue: 'Vyberte hodnotu',
    enterValue: 'Zadajte hodnotu',
    rangeFrom: 'Od',
    rangeTo: 'Do',
    selected: 'Vybrané',
    foundRows: 'Nájdené riadky',
    createdTasksTotal: 'Celkom vytvorených úloh'
  }
};
