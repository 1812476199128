import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Row, Col } from 'react-flexbox-grid';
import cls from 'classnames';
import { Table as TableToolBox, TableHead, TableRow, TableCell } from 'react-toolbox/lib/table';
import { loadTask, setPageLoadingStatus } from '../../store/actions/actions';
import configAxios from '../../config/axios';
import Preload from '../../constructors/Preload';
import ButtonDefault from '../Buttons/ButtonDefault';
import styles from './MobileTableEquipment.css';
import Comment from '../../components/tableComponents/CommentMobile/CommentMobile';
import Search from '../../components/tableComponents/Search/Search';
import TaskDropdown from '../../components/tableComponents/TaskDropdownMobile/TaskDropdownMobile';
import CarouselPhoto from '../../components/tableComponents/CarouselPhoto/CarouselPhoto';

const style = () => ({
  progressSelectedItem: {
    color: '#00A5E6'
  },
  progressStatus: {
    color: '#42bde8',
    verticalAlign: 'middle'
  }
});

class MobileTableEquipment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openPopover: {},
      popover: false,
      selectedItem: null,
      loadingArray: []
    };

    this.showTalbeCol = ['item_id', 'equipment_type', 'status'];
    this.withoutColumns = ['tree', 'image', 'info', 'comment'];

    this.preload = new Preload();
    this.preload.subscribe(promises => {
      this.props.dispatch(setPageLoadingStatus(promises.length > 0));
    });
  }

  componentWillReceiveProps(nextProps) {
    const itemIdField = this.props.tableType === 'direct' ? 'item_id' : 'random_plu_id';
    const { loadingArray } = this.state;

    if (nextProps.items !== this.props.items) {
      loadingArray.map(item => {
        nextProps.items.filter(obj => {
          if (obj.item_id === item.item_id) {
            return (obj.tree = 'loading');
          }
        });
      });

      if (this.state.selectedItem) {
        let nextSelectedItem = null;

        nextProps.items.forEach(item => {
          if (item[itemIdField] === this.state.selectedItem[itemIdField]) {
            nextSelectedItem = item;
          }
        });

        this.setState({
          selectedItem: nextSelectedItem
        });
      }
    }
  }

  handleItemOpen = item => {
    this.setState({
      selectedItem: item
    });
  };

  handleItemClose = () => {
    this.setState({
      selectedItem: null
    });
  };

  deleteCase = (itemId, caseType) => {
    const { dispatch, shopId, urlApi } = this.props;
    const query = new URLSearchParams(location.search);
    const mid = query.has('mid') ? Number(query.get('mid')) : JSON.parse(localStorage.mission).id;
    const promise = configAxios({
      method: 'DELETE',
      url: `/${urlApi}/delete`,
      params: {
        itemId,
        missionId: mid,
        shopId,
        caseType
      }
    });

    promise.then(function() {
      dispatch(loadTask(true));
    });

    return promise;
  };

  loadActionToServer = formData => {
    const { dispatch, items, urlApi } = this.props;
    const { loadingArray, selectedItem } = this.state;

    let newLoadingArray = [...loadingArray];

    const selectedIndex = items.indexOf(selectedItem);
    items[selectedIndex].tree = 'loading';

    newLoadingArray.push(selectedItem);

    this.setState({
      loadingArray: newLoadingArray
    });

    configAxios({
      method: 'POST',
      url: `/${urlApi}/update`,
      data: formData
    })
      .then(response => {
        if (response.status === 200) {
          const loadingArrayIndex = newLoadingArray.indexOf(selectedItem);
          newLoadingArray.splice(loadingArrayIndex, 1);

          this.setState(
            {
              openMenu: false,
              loadingImg: false,
              loadingArray: newLoadingArray
            },
            () => {
              dispatch(loadTask(true, true));
            }
          );
        }
      })
      .catch(() => {
        this.setState({
          loadingImg: false,
          imageFile: null
        });
      });
  };
  render() {
    const {
      shopId,
      items = [],
      headers = {},
      suggest = '',
      manualCases,
      tableType,
      classes,
      language,
      urlApi
    } = this.props;
    const { selectedItem } = this.state;

    const lang = language.translation.directTask;

    const itemIdField = tableType === 'direct' ? 'item_id' : 'random_plu_id';

    const tableRow = (key, field, value) => (
      <Row key={key} between="xs" class={styles.selectedCaseItem}>
        <Col xs={4} class={styles.selectedCaseItemName}>
          <div>{field}:</div>
        </Col>
        <Col xs={8} class={styles.selectedCaseItemValue}>
          {value}
        </Col>
      </Row>
    );

    return (
      <div>
        {items.length > 0 && (
          <TableToolBox selectable={false} class={styles.table}>
            <TableHead class={styles.tableHeadRow}>
              {this.showTalbeCol.map(key => {
                if (key === 'product_name' && tableType === 'direct') {
                  return (
                    <TableCell key={key} class={cls(styles.tableCell, styles.tableHeadCell)}>
                      <Search
                        urlApi={urlApi}
                        suggest={suggest}
                        shopId={shopId}
                        textFieldStyle={{
                          fontSize: '13px'
                        }}
                      />
                    </TableCell>
                  );
                }
                return (
                  <TableCell key={key} class={cls(styles.tableCell, styles.tableHeadCell)}>
                    {headers[key]}
                  </TableCell>
                );
              })}
            </TableHead>

            {items.map((item, index) => {
              const keyCode = index;
              return (
                <TableRow
                  key={keyCode}
                  class={cls(styles.tableRow, {
                    [styles.tableRowStatus]: item.tree_status_id !== 0 && item.action_type !== 0,
                    [styles.manualCasesStyle]: manualCases,
                    [styles.finalCasesStyle]: item.is_final,
                    [styles.loading]: item.tree === 'loading'
                  })}
                  onClick={() => {
                    if (item.tree !== 'loading') {
                      this.handleItemOpen(item);
                    }
                  }}
                >
                  {this.showTalbeCol.map(field => (
                    <TableCell key={field} class={styles.tableCell}>
                      {field === 'status' && item.tree === 'loading' ? (
                        <CircularProgress class={classes.progressStatus} size={30} />
                      ) : (
                        item[field]
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableToolBox>
        )}

        {selectedItem && (
          <div className={styles.selectedCaseContainer}>
            <div className={styles.closeSelectedCase}>
              <CloseIcon onClick={this.handleItemClose} />
            </div>
            <div className={styles.selectedCaseWrap}>
              {Object.keys(headers).map(key => {
                if (!this.withoutColumns.includes(key)) {
                  return tableRow(key, headers[key], selectedItem[key]);
                }
              })}
            </div>
            <div className={styles.controlWrap}>
              {manualCases ? (
                <ButtonDefault fullWidth onClick={() => this.deleteCase(selectedItem.item_id, selectedItem.type_id)}>
                  {lang.delete}
                </ButtonDefault>
              ) : (
                <TaskDropdown
                  data={typeof selectedItem['tree'] === 'object' ? selectedItem['tree'] : []}
                  shopId={shopId}
                  caseType={selectedItem.type_id}
                  itemId={selectedItem[itemIdField]}
                  loadToServer={this.loadActionToServer}
                />
              )}
            </div>

            {tableType === 'direct' && (
              <div className={styles.controlWrap}>
                <Comment
                  urlApi={urlApi}
                  shopId={shopId}
                  itemId={selectedItem.item_id}
                  text={selectedItem.comment ? selectedItem.comment : ''}
                  caseType={selectedItem.type_id}
                />
              </div>
            )}

            {tableType === 'direct' && selectedItem['image'] && (
              <div className={styles.controlWrap}>
                <CarouselPhoto mobile mobileBtnText={lang.photo} images={selectedItem['image']} />
              </div>
            )}

            <div className={styles.controlWrap}>
              <ButtonDefault fullWidth onClick={this.handleItemClose}>
                {lang.back}
              </ButtonDefault>
            </div>
          </div>
        )}
      </div>
    );
  }
}

MobileTableEquipment.propTypes = {
  urlApi: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  items: PropTypes.array,
  headers: PropTypes.object,
  suggest: PropTypes.string,
  classes: PropTypes.object,
  shopId: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
  tableType: PropTypes.string.isRequired,
  language: PropTypes.object.isRequired,
  manualCases: PropTypes.bool.isRequired
};

export default connect(store => store)(withStyles(style)(MobileTableEquipment));
