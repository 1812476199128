export default {
  mobileFirefoxAlert: 'Selles brauseris ülesande töötlemine pole saadaval.',
  datePicker: {
    from: 'Alates',
    to: 'Kuni',
    cancel: 'Tühista',
    apply: 'Kohalda',
    month: [
      'jaanuar',
      'veebruar',
      'märts',
      'aprill',
      'mai',
      'juuni',
      'juuli',
      'august',
      'september',
      'oktoober',
      'november',
      'detsember'
    ],
    week: 'nädal',
    weekShot: 'näd',
    buttonName: {
      date: 'Kuupäev',
      week: 'Nädal',
      month: 'Kuu'
    },
    modal: {
      header: 'Hoiatus',
      text: 'Valitud on vigane kuupäevavahemik',
      action: 'Nõustu'
    }
  },
  filters: {
    name: 'Nimi'
  },
  scenario: {
    noDataReceived: 'Andmeid ei saadud',
    filterPart: {
      name: 'Nimi',
      clear: 'Puhasta',
      notFound: 'Ei leitud',
      search: 'Otsi...'
    }
  },
  header: {
    logout: 'Logi välja',
    changePassword: 'Muuda parooli',
    settings: 'Sätted',
    title: 'Vaata kasutajaliidest',
    searchTitle: 'Otsi kasutajat',
    input: 'Alusta nime või sisselogimise sisestamist...',
    cancel: 'Tühista',
    view: 'Vaade',
    id: 'ID',
    login: 'Sisselogimine',
    name: 'Nimi',
    phone: 'Telefon',
    email: 'E-post',
    stopSettings: 'Lõpeta vaatamine'
  },
  directTask: {
    addCase: 'Lisa juhtum',
    commentCase: 'kommentaarJuhtum',
    actions: 'Tegevused',
    searchProduct: 'PLU/Vöötkood',
    searchProductShort: 'PLU/Vöötkood',
    photo: 'Foto',
    back: 'Tagasi',
    treeEmpty: 'TEGEVUSED POLE SAADAVAL',
    step: 'Samm',
    sortingButton: 'Sorteerimine',
    sortButton: 'Sorteeri',
    add: 'Lisa',
    delete: 'Kustuta',
    deleteManualCases: 'Kustuta käsitsi juhtumid',
    show: 'Näita',
    hide: 'Peida',
    comment: 'Kommentaar',
    showDoneCases: 'Näita lõpetatud ülesandeid',
    hideDoneCases: 'Peida lõpetatud ülesanded'
  },
  gridTable: {
    pluList: 'PLU nimekiri'
  },
  casesForm: {
    product: 'Toode',
    loadFoto: 'Laadi foto',
    plu: 'Nimi/PLU',
    success: 'Juhtum loodud',
    delete: 'Kustuta',
    add: 'Loo',
    new: 'Uus',
    cancel: 'Tühista',
    comment: 'Kommentaar'
  },
  pagination: {
    apply: 'Mine',
    placeholder: 'Lehekülg'
  },
  restorePass: {
    noLength: 'Parool peab olema vähemalt 12 tähemärki pikk',
    noLowercase: 'Paroolis peab olema vähemalt üks väike täht',
    noUppercase: 'Paroolis peab olema vähemalt üks suur täht',
    noNumber: 'Paroolis peab olema vähemalt üks number',
    noMatch: 'Paroolid ei ühti',
    password: 'Parool',
    repeatPassword: 'Korda parooli',
    ok: 'Nõustu',
    cancel: 'Tühista'
  },
  navigation: {
    missions: 'Ülesanded',
    scenarios: 'Stsenaariumid'
  },
  changePassword: {
    previousPassword: 'Praegune parool',
    newPassword: 'Uus parool',
    confirmPassword: 'Korda uut parooli',
    apply: 'Kohalda',
    title: 'Muuda parooli',
    success: 'Parool on edukalt muudetud',
    wrongPassword: 'Vale parool',
    weakPassword: 'Parool ei vasta nõuetele',
    passwordsNotMatch: 'Paroolid ei klapi',
    requirements: 'Uus parool peab olema vähemalt 12 tähemärki pikk, sisaldama vähemalt ühte suurt ja väikest tähte ning ühte numbrit'
  },
  auth: {
    login: 'Logi sisse',
    password: 'Parool',
    restorePass: 'Taasta parool',
    submit: 'Esita',
    loginError: 'Sisselogimine ebaõnnestus, kasutajanimi ja/või parool on vale'
  },
  restorePassForm: {
    restore: 'Taasta',
    cancel: 'Tühista',
    info: 'Palun sisesta oma e-post, mis on registreeritud OSA Hybrid Platformis',
    emailInvalid: 'Esitatud e-posti aadressi ei leitud',
    email: 'E-post',
    success: 'Taastamise link on saadetud teie e-postile'
  },
  search: {
    notFound: 'Ei leitud'
  },
  noMatchPage: {
    title: 'Päritud lehte ei leitud',
    body: 'Tagasi avalehele'
  },
  warnings: {
    title: 'Hoiatus',
    isDesktop:
      'Fotode üleslaadimine on võimalik ainult süsteemi mobiiliversioonis. Kasutage oma telefoni või tahvelarvuti kaamerat.'
  },
  errors: {
    netErrorText: 'Võrguvea tõttu ei õnnestunud. Palun laadi leht uuesti',
    netErrorReload: 'Laadi uuesti',
    netErrorTitle: 'Viga',
    netErrorClose: 'Sulge',
    noShops: 'Ups! Näib, et teile pole veel ühtegi poodi määratud. Rääkige sellest oma juhile',
    noCases: 'Näib, et meie teenus ei leidnud sellest poest ühtegi juhtumit. Alustage nende loomist ise.',
    noAutoOrder: 'Automaatse tellimuse kohandamiseks pole ülesandeid',
    causeError: 'Viga',
    scenariosEmpty: 'Stsenaariumide massiiv on tühi'
  },
  officeUI: {
    resetZoom: 'Lähtesta suum'
  },
  gridAutocomplete: {
    placeholder: 'Alusta kaupluse nime tippimist',
    notFound: 'Poodi ei leitud'
  },
  targets: {
    nothing: '',
    shop: 'Pood',
    category: 'Kategooria',
    supplier: 'Tarnija',
    vendor: 'Müüja',
    brand: 'Bränd',
    plu: 'PLU',
    day: 'Päev',
    week: 'Nädal',
    month: 'Kuu',
    quarter: 'Kvartal',
    year: 'Aasta'
  },
  bubbleChart: {
    qty: 'kogus',
    currency: '€'
  },
  chart: {
    quarter: 'kvartal',
    week: 'nädal'
  },
  download: {
    CSV: 'Laadi alla CSV',
    XLS: 'Laadi alla XLS'
  },
  counterTask: {
    task: 'Ootel ülesanded',
    noTask: 'Ülesandeid pole'
  },
  filtersShop: {
    shopName: 'Poekoha aadress',
    shopId: 'Poekoha nimi'
  },
  chartCaption: {
    thousand: 'tuhat',
    million: 'miljon',
    billion: 'miljard'
  },
  common: {
    close: 'Sulge',
    userNotFound: 'Kasutajat ei leitud',
    yes: 'Jah',
    no: 'Ei'
  },
  virtualStock: {
    headers: {
      case_id: 'ID',
      plu_original: 'PLU',
      plu: 'Sisemine PLU',
      shop_id: 'Poekoha ID',
      ass_type: 'Assortii tüüp',
      category_id3: 'Sektsiooni ID',
      category_id4: 'Kategooria ID',
      stock: 'Jäägi maht',
      stock_sum: 'Jääkide summa',
      product_turnover: 'Toote pöörlemine',
      days_without_sales: 'Päevi müügi puudumisel',
      last_sales_date: 'Viimase müügi kuupäev',
      last_sales_volume: 'Viimane müügimaht',
      last_shipment_date: 'Viimane tarnekuupäev',
      last_shipment_volume: 'Viimane tarnemaht',
      promo_start: 'Reklaami algus',
      promo_end: 'Reklaami lõpp',
      promo_period: 'Reklaami periood',
      is_promo: 'Reklaam',
      title: 'Nimi',
      category_name3: 'Sektsioon',
      category_name4: 'Kategooria'
    },
    hideReadyTasks: 'Peida valmis ülesanded',
    nothingFound: 'Midagi ei leitud',
    shopsNotFound: 'Poode ei leitud',
    chooseShop: 'Valige pood',
    createTasks: 'Loo ülesanded',
    taskCreating: 'Ülesannete loomine',
    next: 'Järgmine',
    columns: 'Veerud',
    from: 'Alates',
    ok: 'OK',
    cancel: 'Tühista',
    filter: 'Filter',
    chooseValue: 'Valige väärtus',
    enterValue: 'Sisesta väärtus',
    rangeFrom: 'Alates',
    rangeTo: 'Kuni',
    selected: 'Valitud',
    foundRows: 'Leitud read',
    createdTasksTotal: 'Kokku loodud ülesanded'
  }
};
