export default {
  mobileFirefoxAlert: 'I den här webbläsaren är uppgiftsbehandling inte tillgänglig.',
  datePicker: {
    from: 'Från',
    to: 'Till',
    cancel: 'Avbryt',
    apply: 'Använd',
    month: [
      'januari',
      'februari',
      'mars',
      'april',
      'maj',
      'juni',
      'juli',
      'augusti',
      'september',
      'oktober',
      'november',
      'december'
    ],
    week: 'vecka',
    weekShot: 'v',
    buttonName: {
      date: 'Datum',
      week: 'Vecka',
      month: 'Månad'
    },
    modal: {
      header: 'Varning',
      text: 'Ogiltigt datumintervall valt',
      action: 'Acceptera'
    }
  },
  filters: {
    name: 'Namn'
  },
  scenario: {
    noDataReceived: 'Ingen data mottagen',
    filterPart: {
      name: 'Namn',
      clear: 'Rensa',
      notFound: 'Ej hittad',
      search: 'Sök...'
    }
  },
  header: {
    logout: 'Logga ut',
    changePassword: 'Byt lösenord',
    settings: 'Inställningar',
    title: 'Visa användargränssnitt',
    searchTitle: 'Sök användare',
    input: 'Börja skriva namn eller användarnamn...',
    cancel: 'Avbryt',
    view: 'Visa',
    id: 'ID',
    login: 'Inloggning',
    name: 'Namn',
    phone: 'Telefon',
    email: 'E-post',
    stopSettings: 'Sluta titta'
  },
  directTask: {
    addCase: 'Lägg till ärende',
    commentCase: 'kommentarFall',
    actions: 'Åtgärder',
    searchProduct: 'PLU/Streckkod',
    searchProductShort: 'PLU/Streckkod',
    photo: 'Foto',
    back: 'Tillbaka',
    treeEmpty: 'ÅTGÄRDER ÄR INTE TILLGÄNGLIGA',
    step: 'Steg',
    sortingButton: 'Sortering',
    sortButton: 'Sortera',
    add: 'Lägg till',
    delete: 'Ta bort',
    deleteManualCases: 'Ta bort manuella ärenden',
    show: 'Visa',
    hide: 'Dölj',
    comment: 'Kommentar',
    showDoneCases: 'Visa utförda uppgifter',
    hideDoneCases: 'Dölj slutförda uppgifter'
  },
  gridTable: {
    pluList: 'PLU-lista'
  },
  casesForm: {
    product: 'Produkt',
    loadFoto: 'Ladda upp foto',
    plu: 'Namn/PLU',
    success: 'Ärende skapat',
    delete: 'Radera',
    add: 'Skapa',
    new: 'Ny',
    cancel: 'Avbryt',
    comment: 'Kommentar'
  },
  pagination: {
    apply: 'Gå',
    placeholder: 'Sida'
  },
  restorePass: {
    noLength: 'Lösenordet måste vara minst 12 tecken långt',
    noLowercase: 'Lösenordet måste innehålla minst en liten bokstav',
    noUppercase: 'Lösenordet måste innehålla minst en stor bokstav',
    noNumber: 'Lösenordet måste innehålla minst en siffra',
    noMatch: 'Lösenorden matchar inte',
    password: 'Lösenord',
    repeatPassword: 'Upprepa lösenord',
    ok: 'Acceptera',
    cancel: 'Avbryt'
  },
  navigation: {
    missions: 'Uppdrag',
    scenarios: 'Scenarier'
  },
  changePassword: {
    previousPassword: 'Nuvarande lösenord',
    newPassword: 'Nytt lösenord',
    confirmPassword: 'Bekräfta nytt lösenord',
    apply: 'Använd',
    title: 'Byt lösenord',
    success: 'Lösenordet har ändrats',
    wrongPassword: 'Fel lösenord',
    weakPassword: 'Lösenordet uppfyller inte kraven',
    passwordsNotMatch: 'Lösenorden matchar inte',
    requirements: 'Det nya lösenordet måste vara minst 12 tecken långt, innehålla en stor och en liten bokstav samt en siffra'
  },
  auth: {
    login: 'Logga in',
    password: 'Lösenord',
    restorePass: 'Återställ lösenord',
    submit: 'Skicka',
    loginError: 'Inloggning och/eller lösenord är ogiltiga'
  },
  restorePassForm: {
    restore: 'Återställ',
    cancel: 'Avbryt',
    info: 'Ange din e-postadress som är registrerad i OSA Hybrid Platform',
    emailInvalid: 'Den angivna e-postadressen hittades inte',
    email: 'E-post',
    success: 'Länk för återställning har skickats till din e-post'
  },
  search: {
    notFound: 'Ej funnen'
  },
  noMatchPage: {
    title: 'Begärd sida hittades inte',
    body: 'Återgå till startsidan'
  },
  warnings: {
    title: 'Varning',
    isDesktop: 
      'Uppladdning av bilder är endast tillgänglig i mobilversionen av systemet. Använd kameran på din mobiltelefon eller surfplatta.'
  },
  errors: {
    netErrorText: 'Nätverksfel. Vänligen uppdatera sidan',
    netErrorReload: 'Ladda om',
    netErrorTitle: 'Fel',
    netErrorClose: 'Stäng',
    noShops: 'ÅH! Det verkar som att inga butiker har tilldelats dig ännu. Berätta för din chef om det',
    noCases: 'Det verkar som att vår tjänst inte kunde hitta ett enda fodral i denna butik. Börja skapa dem själv',
    noAutoOrder: 'Det finns inga uppgifter för att justera den automatiska ordningen',
    causeError: 'Fel',
    scenariosEmpty: 'Skenarielistan är tom'
  },
  officeUI: {
    resetZoom: 'Återställ zoom'
  },
  gridAutocomplete: {
    placeholder: 'Börja skriva butiksnamn',
    notFound: 'Butiken hittades inte'
  },
  targets: {
    nothing: '',
    shop: 'Butik',
    category: 'Kategori',
    supplier: 'Leverantör',
    vendor: 'Försäljare',
    brand: 'Varumärke',
    plu: 'PLU',
    day: 'Dag',
    week: 'Vecka',
    month: 'Månad',
    quarter: 'Kvartal',
    year: 'År'
  },
  bubbleChart: {
    qty: 'antal',
    currency: 'SEK'
  },
  chart: {
    quarter: 'Kvartal',
    week: 'Vecka'
  },
  download: {
    CSV: 'Ladda ner CSV',
    XLS: 'Ladda ner XLS'
  },
  counterTask: {
    task: 'Pågående uppgifter',
    noTask: 'Inga uppgifter'
  },
  filtersShop: {
    shopName: 'Butikadress',
    shopId: 'Butiksnamn'
  },
  chartCaption: {
    thousand: 'tusen',
    million: 'miljon',
    billion: 'miljard'
  },
  common: {
    close: 'Stäng',
    userNotFound: 'Användare hittades inte',
    yes: 'Ja',
    no: 'Nej'
  },
  virtualStock: {
    headers: {
      case_id: 'ID',
      plu_original: 'PLU',
      plu: 'Intern PLU',
      shop_id: 'Butik-ID',
      ass_type: 'Sortimentstyp',
      category_id3: 'Sektions-ID',
      category_id4: 'Kategori-ID',
      stock: 'Lagerbestånd',
      stock_sum: 'Summa av balanser',
      product_turnover: 'Omsättning',
      days_without_sales: 'Dagar utan försäljning',
      last_sales_date: 'Datum för senaste försäljning',
      last_sales_volume: 'Senaste försäljningsvolym',
      last_shipment_date: 'Sista leveransdatum',
      last_shipment_volume: 'Sista leveransvolym',
      promo_start: 'Kampanjstart',
      promo_end: 'Kampanjslut',
      promo_period: 'Kampanjperiod',
      is_promo: 'Kampanj',
      title: 'Namn',
      category_name3: 'Sektion',
      category_name4: 'Kategori'
    },
    hideReadyTasks: 'Dölj klara uppgifter',
    nothingFound: 'Inget hittades',
    shopsNotFound: 'Inga butiker hittades',
    chooseShop: 'Välj en butik',
    createTasks: 'Skapa uppgifter',
    taskCreating: 'Skapar uppgifter',
    next: 'Fortsätt',
    columns: 'Kolumner',
    from: 'Från',
    ok: 'OK',
    cancel: 'Avbryt',
    filter: 'Filter',
    chooseValue: 'Välj ett värde',
    enterValue: 'Ange ett värde',
    rangeFrom: 'Från',
    rangeTo: 'Till',
    selected: 'Vald',
    foundRows: 'Antal hittade rader',
    createdTasksTotal: 'Totalt skapade uppgifter'
  }
};
