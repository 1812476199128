import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { deburr } from 'lodash';
import Autosuggest from 'react-autosuggest';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import ChevronIcon from '@material-ui/icons/ArrowDropDown';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import { IconButton } from '@material-ui/core';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import IconFilters from './svg';

import style from './Autocomplete.css';

let activeShop = null;

function renderInputComponent(inputProps) {
  const {
    classes, noSuggestions, disabled, inputRef = () => {
    }, ref, ...other
  } = inputProps;

  return (
    <TextField
      fullWidth
      className={[style.textField, noSuggestions && style.notFound, disabled && style.disabled].join(' ')}
      InputProps={{
        inputRef: node => {
          ref(node);
          inputRef(node);
        },
        classes: {
          input: classes.input
        }
      }}
      {...other}
    />
  );
}

function renderSuggestion(suggestion) {
  const { toggle = '', shopCode = '', label = '', id } = suggestion;
  const text = toggle ? shopCode : label;

  return (
    <MenuItem
      selected={activeShop && activeShop.id === id}
      component="div"
      classes={{
        selected: style.selected
      }}
      className={style.menuItem}
    >
      <div>
        <span>{text}</span>
      </div>
    </MenuItem>
  );
}

function getSuggestions(data, value) {
  const toSuggest = value.val ? value.val : '';
  const toggle = value.toggle ? value.toggle : 0;
  const inputValue = deburr(toSuggest.trim()).toLowerCase();
  //prettier-ignore
  const suggestions = data
    ? data.map(item => {
      return {
        label: item.text,
        id: item.id,
        shopCode: item.shop_code,
        toggle: toggle
      };
    })
    : [];
  const suggestionShop = toggle ? 'shopCode' : 'label';
  return suggestions.filter(suggestion => {
    return suggestion[suggestionShop] ? suggestion[suggestionShop].toLowerCase().indexOf(inputValue) !== -1 : '';
  });
}

function getSuggestionValue(suggestion) {
  const { toggle = '', shopCode = '', label = '' } = suggestion;
  return toggle ? shopCode : label;
}

const inputWidth = window.screen.width > 767 ? '480px' : '100px';
const styles = theme => ({
  root: {
    flexGrow: 1
  },
  container: {
    position: 'relative',
    zIndex: 1,
    width: '100%',
    border: '1px solid #C5C5C5',
    borderRadius: '4px'
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 10,
    marginTop: theme.spacing(1),
    maxHeight: 300,
    left: 0,
    overflowY: 'auto',
    overflowX: 'auto',
    right: 0
  },
  suggestion: {
    display: 'block'
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none'
  },
  divider: {
    height: theme.spacing(2)
  },
  input: {
    minWidth: inputWidth,
    fontSize: 14,
    padding: '11px 11px 10px 11px',
    paddingRight: '30px'
  },
  menuItem: {
    fontSize: 15
  },
  button: {
    // margin: '5px',
    // padding: '7px',
    borderRadius: '5px',
    // minWidth: '50%',
    // maxWidth: '50%',
    width: '100%',
    whiteSpace: 'nowrap',
    border: '0.5px solid #1C73C2 !important',
    // fontSize: '1.2rem'
  },
  buttonActive: {
    whiteSpace: 'nowrap',
    // fontSize: '1.2rem',
    // margin: '5px',
    // padding: '7px',
    borderRadius: '5px',
    color: '#ffff',
    // minWidth: '50%',
    // maxWidth: '50%',
    width: '100%',
    pointerEvents: 'none',
    border: '0.5px solid #1AA7E0 !important',
    backgroundColor: '#1AA7E0 !important',
    '&:hover': {
      backgroundColor: '#1AA7E0 !important'
    }
  }
});

class IntegrationAutosuggest extends React.Component {
  constructor(props) {
    super(props);
    this.input = React.createRef();
    this.ownInput = React.createRef();
  }

  state = {
    value: '',
    searchValue: '',
    menuOpen: false,
    noSuggestions: false,
    popper: '',
    suggestions: getSuggestions(this.props.shopsData, '', 0),
    isBlur: '',
    toggleSort: 0,
    copied: false
  };

  componentDidUpdate({ currentShop }) {
    const { toggleSort } = this.state;

    if (currentShop != this.props.currentShop) {
      getSuggestions(this.props.shopsData, '', toggleSort);
      this.setActiveShop();
    }
  }

  componentDidMount() {
    this.setActiveShop();
  }

  componentWillReceiveProps(nextProps) {
    const { value } = this.state;

    if (this.props.currentShop !== 0 && nextProps.currentShop !== 0) {
      if (this.props.currentShop === nextProps.currentShop) {
        nextProps.shopsData.find(item => {
          return item.id == nextProps.currentShop;
        }) || {};
      }
    }

    if (nextProps.mission.id !== this.props.mission.id) {
      const newValue = nextProps.shopsData.filter(item => {
        if (item.text === value) {
          return item;
        } else if (item.shop_code === value) {
          return item;
        }
      });

      this.setState({
        toggleSort: 0,
        value: newValue[0].text || value
      });
    }
  }

  setActiveShop = () => {
    const { toggleSort } = this.state;
    const { shopsData, currentShop } = this.props;
    if (currentShop) {
      const shop = shopsData && shopsData.filter(item => item.id == currentShop);
      activeShop = shop[0];
      const checkedSort = shop[0] ? (toggleSort ? shop[0].shop_code : shop[0].text) : '';
      this.setState({
        value: checkedSort
      });
    }
  };

  handleSuggestionsFetchRequested = ({ value, reason }) => {
    const { shopsData } = this.props;
    const { toggleSort } = this.state;
    const inputVale = {
      val: '' + value,
      toggle: toggleSort
    };
    const getSuggestionsWrapped = getSuggestions.bind(this, shopsData);
    const suggestions = getSuggestionsWrapped(inputVale);
    const isInputBlank = inputVale.val.trim() === '';
    const noSuggestions = !isInputBlank && suggestions.length === 0;

    this.setState({
      noSuggestions,
      menuOpen: true
    });

    if (reason === 'input-focused') {
      this.setState({
        searchValue: '',
        suggestions: getSuggestionsWrapped({ val: '', toggle: toggleSort })
      });
    } else {
      this.setState({
        suggestions: getSuggestionsWrapped(inputVale)
      });
    }
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      // suggestions: [],
    });
  };

  handleChange = () => (event, data) => {
    const { suggestions, toggleSort } = this.state;
    const { newValue = '', method = '' } = data;
    let shop;

    if (toggleSort) {
      shop = suggestions.filter(item => item.shopCode === newValue);
    } else {
      shop = suggestions.filter(item => item.label === newValue);
    }

    if (method === 'up' || method === 'down') {
      this.setState(prevState =>
        ({
          searchValue: prevState.searchValue,
          value: prevState.value
        })
      );

      return true;
    }
    this.setState({
      searchValue: newValue
    });

    if (data.method === 'type' && shop.length) {
      this.setState(prevState => ({
        isBlur: this.state.value,
        searchValue: ' ' + prevState.searchValue,
        value: data.newValue,
        noSuggestions: false
      }));
    }
  };

  shouldRenderSuggestions = () => {
    return true;
  };

  handleBlur = () => {
    const { isBlur, value } = this.state;
    this.input.current.input.blur();
    this.setState({
      menuOpen: false,
      value: isBlur ? isBlur : value,
      searchValue: '',
      noSuggestions: false,
      isBlur: isBlur ? '' : isBlur
    });
  };

  handleChangeTrigger = (event, mob) => {
    const { toggleSort } = this.state;
    const { currentShop, shopsData } = this.props;

    let val = typeof mob === 'object' ? event.target.value : event;

    if (toggleSort !== val) {
      this.setState(
        {
          toggleSort: val
        },
        () => {
          if (currentShop) {
            const shop = shopsData.find(item => item.id == currentShop);
            this.setState({
              value: toggleSort ? shop.text : shop.shop_code
            });
          }
        }
      );
      const { isBlur } = this.state;
      if (isBlur) {
        this.setState({
          menuOpen: false,
          value: this.state.isBlur,
          searchValue: '',
          noSuggestions: false,
          isBlur: ''
        });
      } else {
        this.setState({
          menuOpen: false,
          searchValue: '',
          noSuggestions: false
        });
      }
    }
  };

  onKeyPress = event => {
    const { suggestions, value, searchValue, toggleSort } = this.state;
    const { handleChangeShop, shopsData } = this.props;

    if (event.key === 'Enter' && searchValue.length && suggestions.length < shopsData.length) {
      const curValue = toggleSort ? suggestions[0].shopCode : suggestions[0].label;
      this.setState({
        isBlur: value,
        searchValue: ' ' + curValue,
        noSuggestions: false
      });
      handleChangeShop(false, false, suggestions[0].id);
      this.input.current.input.blur();
    } else if (event.key === 'Enter' && suggestions.length === shopsData.length) {
      this.input.current.input.blur();
      this.setState({
        searchValue: value
      });
    }
  };

  onSuggestionSelected = (event, { suggestion: { id, label } }) => {
    const { handleChangeShop } = this.props;
    if (event.type !== 'keydown') {
      handleChangeShop(false, false, id);
      this.setState({
        value: label,
        noSuggestions: false
      });
    }
  };

  render() {
    const {
      classes,
      translation,
      autoWidth,
      isDisabled,
      language: { translation: { filtersShop: { shopName = '', shopId = '' } = {} } = {} } = {}
    } = this.props;
    const { menuOpen, value, searchValue, noSuggestions, toggleSort } = this.state;
    const autosuggestProps = {
      renderInputComponent,
      suggestions: this.state.suggestions,
      onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
      getSuggestionValue,
      renderSuggestion
    };
    const mobileVer = screen.width < 767;
    const holderLength = translation.gridAutocomplete.placeholder.length;
    const valueLength = value ? value.length : 0;
    const computedLength = valueLength + (holderLength - valueLength);
    const letters = value ? (holderLength > valueLength ? computedLength : valueLength) : 0;
    const width = {
      width: autoWidth ? '100%' : `${136 + letters * 6}px`
    };

    return (
      <div>
        {mobileVer && (
          <div className={style.buttonMobile}>
            <Button
              variant="outlined"
              className={toggleSort === 0 ? classes.buttonActive : classes.button}
              onClick={() => this.handleChangeTrigger(0, true)}
            >
              {shopName}
            </Button>
            <Button
              variant="outlined"
              className={toggleSort === 1 ? classes.buttonActive : classes.button}
              onClick={() => this.handleChangeTrigger(1, true)}
            >
              {shopId}
            </Button>
          </div>
        )}
        <div className={style.root} style={width}>
          {!mobileVer && (
            <Select
              variant="outlined"
              value={toggleSort}
              onChange={this.handleChangeTrigger}
              className={isDisabled ? style.shopNotActive : style.selectShop}
              inputProps={{
                disabled: isDisabled
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
            >
              <MenuItem value={0}>{shopName}</MenuItem>
              <MenuItem value={1}>{shopId}</MenuItem>
            </Select>
          )}
          <div style={{ position: 'relative', width: `${!mobileVer ? '100%' : 'auto'}` }}>
            <Autosuggest
              {...autosuggestProps}
              ref={this.input}
              underlineShow={false}
              inputProps={{
                classes,
                placeholder: translation.gridAutocomplete.placeholder,
                value: !menuOpen ? value : searchValue,
                onChange: this.handleChange('value'),
                onBlur: this.handleBlur,
                noSuggestions: noSuggestions,
                disabled: isDisabled,
                onKeyPress: this.onKeyPress
              }}
              theme={{
                container: classes.container,
                suggestionsContainerOpen: classes.suggestionsContainerOpen,
                suggestionsList: classes.suggestionsList,
                suggestion: classes.suggestion
              }}
              onBlur={this.onBlur}
              onSuggestionSelected={this.onSuggestionSelected}
              shouldRenderSuggestions={this.shouldRenderSuggestions}
              focusInputOnSuggestionClick={false}
              renderSuggestionsContainer={options => {
                return (
                  <Paper {...options.containerProps} square>
                    {options.children}
                  </Paper>
                );
              }}
            />

            {!isDisabled && (
              <div className={style.icon}>
                <ChevronIcon style={{ fontSize: 24, fontWeight: 400 }} />
                <input type="text" className={style.chevronIcon} ref={this.ownInput} />
              </div>
            )}
          </div>

          {!mobileVer && (
            <div className={style.copyButton}>
              <CopyToClipboard text={value} onCopy={() => this.setState({ copied: true })}>
                <IconButton aria-label="copy" style={{ color: '#356F97', marginTop: '1px', padding: '5px' }}>
                  <IconFilters icon="copy" />
                </IconButton>
              </CopyToClipboard>
            </div>
          )}
        </div>
      </div>
    );
  }
}

IntegrationAutosuggest.defaultProps = {
  isDisabled: false,
  autoWidth: false
};

IntegrationAutosuggest.propTypes = {
  language: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired, // eslint-disable-line
  classes: PropTypes.object.isRequired, // eslint-disable-line
  translation: PropTypes.object.isRequired, //eslint-disable-line
  isDisabled: PropTypes.bool, //eslint-disable-line
  mission: PropTypes.object.isRequired, //eslint-disable-line
  shopsData: PropTypes.array,
  currentShop: PropTypes.number,
  autoWidth: PropTypes.bool,
  handleChangeShop: PropTypes.func.isRequired,
  loadShopsFromServer: PropTypes.func, //eslint-disable-line
  shopOrCode: PropTypes.oneOfType([PropTypes.object, PropTypes.number])
};

export default connect(store => {
  return {
    changePage: store.changePage,
    loadTask: store.loadTask,
    searchProduct: store.searchProduct,
    pageLoadingStatus: store.pageLoadingStatus,
    language: store.language,
    blockingContent: store.blockingContent,
    mission: store.mission
  };
})(withStyles(styles)(IntegrationAutosuggest));
