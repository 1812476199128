export default {
  mobileFirefoxAlert: 'Առաջադրանքների մշակումը հասանելի չէ այս դիտարկիչում',
  datePicker: {
    from: 'Սկսած',
    to: 'Մինչև',
    cancel: 'Չեղարկել',
    apply: 'Կիրառել',
    month: [
      'Հունվար',
      'Փետրվար',
      'Մարտ',
      'Ապրիլ',
      'Մայիս',
      'Հունիս',
      'Հուլիս',
      'Օգոստոս',
      'Սեպտեմբեր',
      'Հոկտեմբեր',
      'Նոյեմբեր',
      'Դեկտեմբեր'
    ],
    week: 'շաբաթ',
    weekShot: 'Շ',
    buttonName: {
      date: 'Ամսաթիվ',
      week: 'Շաբաթ',
      month: 'Ամիս'
    },
    modal: {
      header: 'Զգուշացում',
      text: 'Ընտրված անվավեր ամսաթիվներ',
      action: 'Ընդունել'
    }
  },
  filters: {
    name: 'Անուն'
  },
  scenario: {
    noDataReceived: 'Տվյալներ չեն ստացվել',
    filterPart: {
      name: 'Անուն',
      clear: 'Մաքրել',
      notFound: 'Չի գտնվել',
      search: 'Որոնել...'
    }
  },
  header: {
    logout: 'Դուրս գալ',
    changePassword: 'Փոխել ծածկագիրը',
    settings: 'Ստուգում',
    title: 'Դիտել օգտատերի ինտերֆեյսը',
    searchTitle: 'Որոնել օգտատեր',
    input: 'Սկսեք մուտքագրել ձեր անունը կամ մուտքը...',
    cancel: 'Չեղարկել',
    view: 'Դիտել',
    id: 'Իդենտիֆիկատոր',
    login: 'Մուտքանուն',
    name: 'Անուն',
    phone: 'Հեռախոս',
    email: 'Էլ. փոստ',
    stopSettings: 'Դադարեցնել դիտումը'
  },
  directTask: {
    addCase: 'Ավելացրեք առաջադրանք',
    commentCase: 'Մեկնաբանություն',
    actions: 'Գործողություններ',
    searchProduct: 'PLU/շտրիխ կոդ',
    searchProductShort: 'PLU/շտրիխ կոդ',
    photo: 'Լուսանկարը',
    back: 'Հետ',
    treeEmpty: 'Գործողությունները հասանելի չեն',
    step: 'Քայլ',
    sortingButton: 'Տեսակավորել:',
    sortButton: 'Տեսակավորում',
    add: 'Ավելացնել',
    delete: 'Ջնջել',
    deleteManualCases: 'Ջնջել առաջադրանքը',
    show: 'Ցուցադրել',
    hide: 'Թաքցնել',
    comment: 'Մեկնաբանություն',
    showDoneCases: 'Ցույց տալ ավարտված առաջադրանքները',
    hideDoneCases: 'Թաքցնել ավարտված առաջադրանքները'
  },
  gridTable: {
    pluList: 'PLU-ի ցանկ'
  },
  casesForm: {
    product: 'Ապրանք',
    loadFoto: 'Բեռնել նկար',
    plu: 'Անուն/PLU',
    success: 'Ստեղծվել է առաջադրանք',
    delete: 'Ջնջել',
    add: 'Ստեղծել',
    new: 'Նոր',
    cancel: 'Չեղարկել',
    comment: 'Մեկնաբանություն'
  },
  pagination: {
    apply: 'Առաջ',
    placeholder: 'Թիվ'
  },
  restorePass: {
    noLength: 'Գաղտնաբառը պետք է ունենա առնվազն 12 նիշ',
    noLowercase: 'Գաղտնաբառը պետք է ունենա առնվազն մեկ փոքրատառ տառ',
    noUppercase: 'Գաղտնաբառը պետք է ունենա առնվազն մեկ մեծատառ տառ',
    noNumber: 'Գաղտնաբառը պետք է ունենա առնվազն մեկ թվային նշան',
    noMatch: 'Գաղտնաբառերը չեն համընկնում',
    password: 'Գաղտնաբառ',
    repeatPassword: 'Կրկնեք գաղտնաբառը',
    ok: 'Հաստատել',
    cancel: 'Չեղարկել'
  },
  navigation: {
    missions: 'Առաքելություններ',
    scenarios: 'Սցենարներ'
  },
  changePassword: {
    previousPassword: 'Նախորդ գաղտնաբառը',
    newPassword: 'Նոր գաղտնաբառ',
    confirmPassword: 'Նոր գաղտնաբառը կրկնեք',
    apply: 'Կիրառել',
    title: 'Փոխել գաղտնաբառը',
    success: 'Գաղտնաբառը հաջողությամբ փոխվեց',
    wrongPassword: 'Սխալ գաղտնաբառ',
    weakPassword: 'Գաղտնաբառը չի բավարարում պահանջներին',
    passwordsNotMatch: 'Գաղտնաբառերը չեն համընկնում',
    requirements: 'Նոր գաղտնաբառը պետք է ունենա առնվազն 12 նիշ, 1 մեծատառ և փոքրատառ տառ, և 1 թվային նշան '
  },
  auth: {
    login: 'Մուտք',
    password: 'Գաղտնաբառ',
    restorePass: 'Վերականգնել գաղտնաբառը',
    submit: 'Մուտք գործել',
    loginError: 'Մուտքանուն և/կամ գաղտնաբառը սխալ են'
  },
  restorePassForm: {
    restore: 'Վերականգնել',
    cancel: 'Չեղարկել',
    info: 'Խնդրվում է մուտքագրեք ձեր էլ. փոստի հասցեն, որը գրանցված է OSA Hybrid Platform-ում',
    emailInvalid: 'Մուտքագրված էլ. փոստը չի գտնվել',
    email: 'Էլ. փոստ',
    success: 'Վերականգնման հղումը ուղարկվեց ձեր էլ. փոստին'
  },
  search: {
    notFound: 'Չի գտնվել'
  },
  noMatchPage: {
    title: 'Հարցված էջը չի գտնվել',
    body: 'Վերադարձ դեպի գլխավոր վեբ էջ'
  },
  warnings: {
    title: 'Զգուշացում',
    isDesktop: 'Լուսանկարների վերբեռնումը հասանելի է միայն համակարգի բջջային տարբերակում: Օգտագործեք ձեր հեռախոսի կամ պլանշետի տեսախցիկը:'
  },
  errors: {
    netErrorText: 'Ցանցի սխալ։ Խնդրվում է թարմացնել էջը',
    netErrorReload: 'Բեռնել նորի։',
    netErrorTitle: 'Սխալ',
    netErrorClose: 'Փակել',
    noShops: 'Օ՜ Կարծես դեռ խանութներ չեն նշանակվել ձեզ: Ասացեք ձեր մենեջերին այդ մասին',
    noCases: 'Թվում է, թե մեր ծառայությունը չի կարողացել որևէ առաջադրանք գտնել այս խանութում: Սկսեք ստեղծել դրանք ինքներդ',
    noAutoOrder: 'Ավտոմատ կարգը կարգավորելու առաջադրանքներ չկան',
    causeError: 'Սխալ',
    scenariosEmpty: 'Դուք ոչ մի սցենար չունեք'
  },
  officeUI: {
    resetZoom: 'Վերակացնել զումանը'
  },
  gridAutocomplete: {
    placeholder: 'Սկսեք գրել խանութի անունը',
    notFound: 'Խանութը չի գտնվել'
  },
  targets: {
    nothing: '',
    shop: 'Խանութ',
    category: 'Կատեգորիա',
    supplier: 'Մատակարար',
    vendor: 'Մատակարար',
    brand: 'Ապրանքանիշը',
    plu: 'PLU',
    day: 'Օր',
    week: 'Շաբաթ',
    month: 'Ամիս',
    quarter: 'Եռամս',
    year: 'Տարի'
  },
  bubbleChart: {
    qty: 'Քանակ',
    currency: 'ՀՀ Դրամ'
  },
  chart: {
    quarter: 'Եռամս',
    week: 'Շաբաթ'
  },
  download: {
    CSV: 'Ներբեռնել CSV',
    XLS: 'Ներբեռնել XLS'
  },
  counterTask: {
    task: 'Առաջադրանքներ կան',
    noTask: 'Առաջադրանքներ չկան'
  },
  filtersShop: {
    shopName: 'Խանութի հասցե',
    shopId: 'Խանութի անուն'
  },
  chartCaption: {
    thousand: 'հազար',
    million: 'միլիոն',
    billion: 'միլիարդ'
  },
  common: {
    close: 'Փակել',
    userNotFound: 'Օգտատերը չի գտնվել',
    yes: 'Այո',
    no: 'Ոչ',
  },
  virtualStock: {
    headers: {
      case_id: "Իդենտիֆիկատոր",
      plu_original: "PLU",
      plu: "Ներքին PLU",
      shop_id: "Խանութի անդամակցություն",
      ass_type: "Ասորտիմենտի տեսակ",
      category_id3: "Տարբերակի ID",
      category_id4: "Կատեգորիայի ID",
      stock: "Մնացորդների քանակ",
      stock_sum: "Մնացորդների գումար",
      product_turnover: "Առևտրի շրջանառություն",
      days_without_sales: "Օրեր վաճառքի առանց",
      last_sales_date: "Վերջին վաճառքի ամսաթիվը",
      last_sales_volume: "Վաճառքի վերջին ծավալը",
      last_shipment_date: "Վերջին առաքման ամսաթիվը",
      last_shipment_volume: "Վերջին առաքման ծավալը",
      promo_start: "Ակցիայի սկիզբ",
      promo_end: "Ակցիայի ավարտ",
      promo_period: "Ակցիայի ժամկետ",
      is_promo: "Ակցիա",
      title: "Անուն",
      category_name3: "Բաժին",
      category_name4: "Կատեգորիա"
    },
    hideReadyTasks: "Թաքցնել պատրաստ դաշտերը",
    nothingFound: "Ոչինչ չի գտնվել",
    shopsNotFound: "Չեն գտնվել խանութներ",
    chooseShop: "Ընտրեք խանութ",
    createTasks: "Ստեղծել առաջարկներ",
    taskCreating: "Առաջարկների ստեղծում",
    next: "Շարունակել",
    columns: "Սյուներ",
    from: "Սկսած",
    ok: "Լավ",
    cancel: "Չեղարկել",
    filter: "Ֆիլտր",
    chooseValue: "Ընտրեք արժեք",
    enterValue: "Մուտքագրեք արժեքը",
    rangeFrom: "Սկսած",
    rangeTo: "Մինչև",
    selected: "Ընտրված",
    foundRows: "Գտնված տողեր",
    createdTasksTotal: "Առաջարկների ընդհանուր ստեղծված՝"
  }
};
