export default {
  mobileFirefoxAlert: 'Šajā pārlūkprogrammā uzdevumu apstrāde nav pieejama.',
  datePicker: {
    from: 'No',
    to: 'Līdz',
    cancel: 'Atcelt',
    apply: 'Pielietot',
    month: [
      'janvāris',
      'februāris',
      'marts',
      'aprīlis',
      'maijs',
      'jūnijs',
      'jūlijs',
      'augusts',
      'septembris',
      'oktobris',
      'novembris',
      'decembris'
    ],
    week: 'nedēļa',
    weekShot: 'n',
    buttonName: {
      date: 'Datums',
      week: 'Nedēļa',
      month: 'Mēnesis'
    },
    modal: {
      header: 'Brīdinājums',
      text: 'Atlasīta nederīga datuma diapazona',
      action: 'Apstiprināt'
    }
  },
  filters: {
    name: 'Vārds'
  },
  scenario: {
    noDataReceived: 'Dati nav saņemti',
    filterPart: {
      name: 'Vārds',
      clear: 'Notīrīt',
      notFound: 'Nav atrasts',
      search: 'Meklēt...'
    }
  },
  header: {
    logout: 'Izrakstīties',
    changePassword: 'Mainīt paroli',
    settings: 'Iestatījumi',
    title: 'Skatīt lietotāja interfeisu',
    searchTitle: 'Meklēt lietotāju',
    input: 'Sāciet ierakstīt vārdu vai lietotājvārdu...',
    cancel: 'Atcelt',
    view: 'Skatīt',
    id: 'ID',
    login: 'Pieslēgties',
    name: 'Vārds',
    phone: 'Tālrunis',
    email: 'E-pasts',
    stopSettings: 'Apturēt skatīšanu'
  },
  directTask: {
    addCase: 'Pievienot lietu',
    commentCase: 'komentētLietu',
    actions: 'Darbības',
    searchProduct: 'PLU/Barkods',
    searchProductShort: 'PLU/Barkods',
    photo: 'Foto',
    back: 'Atpakaļ',
    treeEmpty: 'DARBI NAV PIEEJAMI',
    step: 'Solis',
    sortingButton: 'Kārtošana',
    sortButton: 'Kārtot',
    add: 'Pievienot',
    delete: 'Dzēst',
    deleteManualCases: 'Dzēst manuālās lietas',
    show: 'Parādīt',
    hide: 'Paslēpt',
    comment: 'Komentēt',
    showDoneCases: 'Rādīt pabeigtos uzdevumus',
    hideDoneCases: 'Paslēpt pabeigtos uzdevumus'
  },
  gridTable: {
    pluList: 'PLU saraksts'
  },
  casesForm: {
    product: 'Produkts',
    loadFoto: 'Ielādēt foto',
    plu: 'Nosaukums/PLU',
    success: 'Lietas izveidota',
    delete: 'Dzēst',
    add: 'Izveidot',
    new: 'Jauns',
    cancel: 'Atcelt',
    comment: 'Komentēt'
  },
  pagination: {
    apply: 'Iet',
    placeholder: 'Lapa'
  },
  restorePass: {
    noLength: 'Parolei jābūt vismaz 12 simbolus garai',
    noLowercase: 'Parolei jāsatur vismaz viens mazais burts',
    noUppercase: 'Parolei jāsatur vismaz viens lielais burts',
    noNumber: 'Parolei jāsatur vismaz viens cipars',
    noMatch: 'Paroles nesakrīt',
    password: 'Parole',
    repeatPassword: 'Atkārtoti ievadiet paroli',
    ok: 'Apstiprināt',
    cancel: 'Atcelt'
  },
  navigation: {
    missions: 'Uzdevumi',
    scenarios: 'Scenāriji'
  },
  changePassword: {
    previousPassword: 'Pašreizējā parole',
    newPassword: 'Jaunā parole',
    confirmPassword: 'Atkārtoti ievadiet jauno paroli',
    apply: 'Pielietot',
    title: 'Mainīt paroli',
    success: 'Parole veiksmīgi mainīta',
    wrongPassword: 'Nepareiza parole',
    weakPassword: 'Parole neatbilst prasībām',
    passwordsNotMatch: 'Paroles nesakrīt',
    requirements: 'Jaunai parolei jābūt vismaz 12 simbolus garai, jāsatur vismaz viens lielais un viens mazais burts, kā arī vismaz viens cipars'
  },
  auth: {
    login: 'Pieslēgties',
    password: 'Parole',
    restorePass: 'Atjaunot paroli',
    submit: 'Iesniegt',
    loginError: 'Nederīgs lietotājvārds un/vai parole'
  },
  restorePassForm: {
    restore: 'Atjaunot',
    cancel: 'Atcelt',
    info: 'Lūdzu, ievadiet savu e-pastu, kas reģistrēts OSA Hybrid Platformā',
    emailInvalid: 'Iesniegtais e-pasts netika atrasts',
    email: 'E-pasts',
    success: 'Atjaunošanas saite nosūtīta uz jūsu e-pastu'
  },
  search: {
    notFound: 'Nav atrasts'
  },
  noMatchPage: {
    title: 'Pieprasītā lapa nav atrasta',
    body: 'Atgriezties uz sākumlapu'
  },
  warnings: {
    title: 'Brīdinājums',
    isDesktop:
      'Bildes augšupielāde ir pieejama tikai sistēmas mobilajā versijā. Lūdzu, izmantojiet fotoaparātu savā telefonā vai planšetdatorā.'
  },
  errors: {
    netErrorText: 'Tīkla kļūda. Lūdzu, atkārtoti ielādējiet lapu',
    netErrorReload: 'Pārlādēt',
    netErrorTitle: 'Kļūda',
    netErrorClose: 'Aizvērt',
    noShops: 'Ak! Šķiet, ka jums vēl nav piešķirts neviens veikals. Pastāstiet par to savam vadītājam',
    noCases: 'Šķiet, ka mūsu serviss šajā veikalā nevarēja atrast nevienu lietu. Sāciet tos veidot pats',
    noAutoOrder: 'Nav nekādu uzdevumu, lai pielāgotu automātisko pasūtījumu',
    causeError: 'Kļūda',
    scenariosEmpty: 'Scenāriju masīvs ir tukšs'
  },
  officeUI: {
    resetZoom: 'Atiestatīt mērogu'
  },
  gridAutocomplete: {
    placeholder: 'Sāciet ierakstīt veikala nosaukumu',
    notFound: 'Veikals nav atrasts'
  },
  targets: {
    nothing: '',
    shop: 'Veikals',
    category: 'Kategorija',
    supplier: 'Piegādātājs',
    vendor: 'Pārdevējs',
    brand: 'Zīmols',
    plu: 'PLU',
    day: 'Diena',
    week: 'Nedēļa',
    month: 'Mēnesis',
    quarter: 'Ceturksnis',
    year: 'Gads'
  },
  bubbleChart: {
    qty: 'daudzums',
    currency: 'eur'
  },
  chart: {
    quarter: 'ceturksnis',
    week: 'nedēļa'
  },
  download: {
    CSV: 'Lejupielādēt CSV',
    XLS: 'Lejupielādēt XLS'
  },
  counterTask: {
    task: 'Gaidošie uzdevumi',
    noTask: 'Nav uzdevumu'
  },
  filtersShop: {
    shopName: 'Veikala adrese',
    shopId: 'Veikala nosaukums'
  },
  chartCaption: {
    thousand: 'tūkst.',
    million: 'milj.',
    billion: 'mljrd.'
  },
  common: {
    close: 'Aizvērt',
    userNotFound: 'Lietotājs nav atrasts',
    yes: 'Jā',
    no: 'Nē'
  },
  virtualStock: {
    headers: {
      case_id: 'ID',
      plu_original: 'PLU',
      plu: 'Iekšējais PLU',
      shop_id: 'Veikala ID',
      ass_type: 'Sortiments veids',
      category_id3: 'Sadaļas ID',
      category_id4: 'Kategorijas ID',
      stock: 'Krājums',
      stock_sum: 'Atlikumu apjoms',
      product_turnover: 'Apgriešanās',
      days_without_sales: 'Dienas bez pārdošanas',
      last_sales_date: 'Pēdējās pārdošanas datums',
      last_sales_volume: 'Pēdējā pārdošanas apjoms',
      last_shipment_date: 'Pēdējais piegādes datums',
      last_shipment_volume: 'Pēdējais piegādes apjoms',
      promo_start: 'Aktivizācijas sākums',
      promo_end: 'Aktivizācijas beigas',
      promo_period: 'Aktivizācijas periods',
      is_promo: 'Aktivizēts',
      title: 'Nosaukums',
      category_name3: 'Sadaļa',
      category_name4: 'Kategorija'
    },
    hideReadyTasks: 'Paslēpt gatavos uzdevumus',
    nothingFound: 'Nekas nav atrasts',
    shopsNotFound: 'Veikali nav atrasti',
    chooseShop: 'Izvēlieties veikalu',
    createTasks: 'Izveidot uzdevumus',
    taskCreating: 'Uzdevumu izveide',
    next: 'Turpināt',
    columns: 'Kolonnas',
    from: 'No',
    ok: 'Labi',
    cancel: 'Atcelt',
    filter: 'Filtrs',
    chooseValue: 'Izvēlieties vērtību',
    enterValue: 'Ievadiet vērtību',
    rangeFrom: 'No',
    rangeTo: 'Līdz',
    selected: 'Atlasīts',
    foundRows: 'Atrastās rindas',
    createdTasksTotal: 'Kopā izveidoti uzdevumi'
  }
};
