export default {
  mobileFirefoxAlert: 'In deze browser is taakverwerking niet beschikbaar.',
  datePicker: {
    from: 'Van',
    to: 'Tot',
    cancel: 'Annuleren',
    apply: 'Toepassen',
    month: [
      'januari',
      'februari',
      'maart',
      'april',
      'mei',
      'juni',
      'juli',
      'augustus',
      'september',
      'oktober',
      'november',
      'december'
    ],
    week: 'week',
    weekShot: 'w',
    buttonName: {
      date: 'Datum',
      week: 'Week',
      month: 'Maand'
    },
    modal: {
      header: 'Waarschuwing',
      text: 'Ongeldige datumreeks geselecteerd',
      action: 'Accepteren'
    }
  },
  filters: {
    name: 'Naam'
  },
  scenario: {
    noDataReceived: 'Geen gegevens ontvangen',
    filterPart: {
      name: 'Naam',
      clear: 'Wissen',
      notFound: 'Niet gevonden',
      search: 'Zoeken...'
    }
  },
  header: {
    logout: 'Uitloggen',
    changePassword: 'Wachtwoord wijzigen',
    settings: 'Instellingen',
    title: 'Bekijk gebruikersinterface',
    searchTitle: 'Zoek gebruiker',
    input: 'Begin met typen van naam of login...',
    cancel: 'Annuleren',
    view: 'Bekijken',
    id: 'ID',
    login: 'Login',
    name: 'Naam',
    phone: 'Telefoon',
    email: 'E-mail',
    stopSettings: 'Stop met bekijken'
  },
  directTask: {
    addCase: 'Toevoegen zaak',
    commentCase: 'Opmerking zaak',
    actions: 'Acties',
    searchProduct: 'PLU/Barcode',
    searchProductShort: 'PLU/Barcode',
    photo: 'Foto',
    back: 'Terug',
    treeEmpty: 'ACTIES ZIJN NIET BESCHIKBAAR',
    step: 'Stap',
    sortingButton: 'Sorteren',
    sortButton: 'Sorteer',
    add: 'Toevoegen',
    delete: 'Verwijderen',
    deleteManualCases: 'Verwijder handmatige zaken',
    show: 'Weergeven',
    hide: 'Verbergen',
    comment: 'Opmerking',
    showDoneCases: 'Toon voltooide taken',
    hideDoneCases: 'Voltooide taken verbergen'
  },
  gridTable: {
    pluList: 'PLU-lijst'
  },
  casesForm: {
    product: 'Product',
    loadFoto: 'Foto laden',
    plu: 'Naam/PLU',
    success: 'Zaak aangemaakt',
    delete: 'Verwijderen',
    add: 'Creëren',
    new: 'Nieuw',
    cancel: 'Annuleren',
    comment: 'Opmerking'
  },
  pagination: {
    apply: 'Ga',
    placeholder: 'Pagina'
  },
  restorePass: {
    noLength: 'Wachtwoord moet minimaal 12 tekens bevatten',
    noLowercase: 'Wachtwoord moet minimaal één kleine letter bevatten',
    noUppercase: 'Wachtwoord moet minimaal één hoofdletter bevatten',
    noNumber: 'Wachtwoord moet minimaal één numeriek symbool bevatten',
    noMatch: 'Wachtwoorden komen niet overeen',
    password: 'Wachtwoord',
    repeatPassword: 'Herhaal wachtwoord',
    ok: 'Accepteren',
    cancel: 'Annuleren'
  },
  navigation: {
    missions: 'Opdrachten',
    scenarios: 'Scenario\'s'
  },
  changePassword: {
    previousPassword: 'Huidig wachtwoord',
    newPassword: 'Nieuw wachtwoord',
    confirmPassword: 'Nieuw wachtwoord opnieuw',
    apply: 'Toepassen',
    title: 'Wachtwoord wijzigen',
    success: 'Wachtwoord is succesvol gewijzigd',
    wrongPassword: 'Verkeerd wachtwoord',
    weakPassword: 'Wachtwoord voldoet niet aan de vereisten',
    passwordsNotMatch: 'Wachtwoorden komen niet overeen',
    requirements: 
      'Het nieuwe wachtwoord moet minimaal 12 tekens bevatten, 1 hoofdletter en kleine letter en 1 nummer '
  },
  auth: {
    login: 'Login',
    password: 'Wachtwoord',
    restorePass: 'Wachtwoord herstellen',
    submit: 'Verzenden',
    loginError: 'Login en/of wachtwoord zijn ongeldig'
  },
  restorePassForm: {
    restore: 'Herstellen',
    cancel: 'Annuleren',
    info: 'Voer uw e-mailadres in dat geregistreerd is op het OSA Hybrid Platform',
    emailInvalid: 'Het ingediende e-mailadres is niet gevonden',
    email: 'E-mail',
    success: 'Herstelkoppeling is naar uw e-mailadres verzonden'
  },
  search: {
    notFound: 'Niet gevonden'
  },
  noMatchPage: {
    title: 'Aangevraagde pagina niet gevonden',
    body: 'Terug naar de startpagina'
  },
  warnings: {
    title: 'Waarschuwing',
    isDesktop: 'Het uploaden van foto\'s is alleen mogelijk in de mobiele versie van het systeem. Gebruik de camera op uw telefoon of tablet, alstublieft.'
  },
  errors: {
    netErrorText: 'Netwerkfout. Vernieuw de pagina alstublieft',
    netErrorReload: 'Vernieuwen',
    netErrorTitle: 'Fout',
    netErrorClose: 'Sluiten',
    noShops: 'Oeps! Het lijkt erop dat er nog geen winkels aan je zijn toegewezen. Vertel het uw manager',
    noCases: 'Het lijkt erop dat onze service geen enkel geval in deze winkel kon vinden. Begin ze zelf te maken',
    noAutoOrder: 'Er zijn geen taken om de automatische volgorde aan te passen',
    causeError: 'Fout',
    scenariosEmpty: 'Scenario\'s array is leeg'
  },
  officeUI: {
    resetZoom: 'Zoom resetten'
  },
  gridAutocomplete: {
    placeholder: 'Begin met typen van de winkelnaam',
    notFound: 'Winkel niet gevonden'
  },
  targets: {
    nothing: '',
    shop: 'Winkel',
    category: 'Categorie',
    supplier: 'Leverancier',
    vendor: 'Verkoper',
    brand: 'Merk',
    plu: 'PLU',
    day: 'Dag',
    week: 'Week',
    month: 'Maand',
    quarter: 'Kwartaal',
    year: 'Jaar'
  },
  bubbleChart: {
    qty: 'Hoeveelheid',
    currency: 'Roebel'
  },
  chart: {
    quarter: 'Kwartaal',
    week: 'Week'
  },
  download: {
    CSV: 'Download CSV',
    XLS: 'Download XLS'
  },
  counterTask: {
    task: 'Openstaande taken',
    noTask: 'Geen taken'
  },
  filtersShop: {
    shopName: 'Winkeladres',
    shopId: 'Winkelnaam'
  },
  chartCaption: {
    thousand: 'k',
    million: 'M',
    billion: 'G'
  },
  common: {
    close: 'Sluiten',
    userNotFound: 'Gebruiker niet gevonden',
    yes: 'Ja',
    no: 'Nee',
  },
  virtualStock: {
    headers: {
      case_id: 'ID',
      plu_original: 'PLU',
      plu: 'Interne PLU',
      shop_id: 'Winkel ID',
      ass_type: 'Assortimentstype',
      category_id3: 'Sectie ID',
      category_id4: 'Categorie ID',
      stock: 'Volume restanten',
      stock_sum: 'Hoeveelheid restanten',
      product_turnover: 'Omzet',
      days_without_sales: 'Dagen zonder verkoop',
      last_sales_date: 'Laatste verkoopdatum',
      last_sales_volume: 'Laatste verkoopvolume',
      last_shipment_date: 'Laatste leverdatum',
      last_shipment_volume: 'Laatste leveringsvolume',
      promo_start: 'Promo Start',
      promo_end: 'Promo Einde',
      promo_period: 'Promo Periode',
      is_promo: 'Promo',
      title: 'Naam',
      category_name3: 'Sectie',
      category_name4: 'Categorie'
    },
    hideReadyTasks: 'Verberg gereedstaande taken',
    nothingFound: 'Niets gevonden',
    shopsNotFound: 'Winkels niet gevonden',
    chooseShop: 'Kies een winkel',
    createTasks: 'Taken aanmaken',
    taskCreating: 'Taken aanmaken',
    next: 'Volgende',
    columns: 'Kolommen',
    from: 'Van',
    ok: 'OK',
    cancel: 'Annuleren',
    filter: 'Filter',
    chooseValue: 'Kies een waarde',
    enterValue: 'Voer waarde in',
    rangeFrom: 'Van',
    rangeTo: 'Tot',
    selected: 'Geselecteerd',
    foundRows: 'Gevonden rijen',
    createdTasksTotal: 'Totaal aantal aangemaakte taken'
  }
};
