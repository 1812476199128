import React from 'react';
import PropTypes from 'prop-types';
import ReactHighcharts from 'react-highcharts';
import { connect } from 'react-redux';

import styles from './Chart.css';
import ExportDataComponent from '../../ExportDataComponent';
import UiElement from '../../UiElement/UiElement';

class Chart extends UiElement {
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
  }

  state = {
    config: {}
  };

  shouldComponentUpdate(nextProps) {
    return !this.props.updatesLocked && this.props.updatesLocked === nextProps.updatesLocked;
  }

  componentWillReceiveProps(nextProps) {
    const propsData = nextProps.data.data;
    this.setState(
      {
        propsData: propsData
      },
      () => {
        this.prepareConfig();
      }
    );
  }

  componentDidMount() {
    this.prepareConfig();
  }

  dateNamesTransformHandle = (target, data) => {
    const { translation } = this.props.language;
    if (!data.length) {
      return data;
    }

    const weeksCount = date => {
      const d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
      d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
      const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
      const weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
      return [d.getUTCFullYear(), weekNo];
    };

    switch (target) {
      case 'day':
        return data.map(item =>
          item
            .split('-')
            .reverse()
            .join('.')
        );
      case 'week':
        return data.map(item => {
          const targetDate = new Date(item);
          const weeks = weeksCount(targetDate);
          return `${weeks[1]} ${translation.chart.week} ${weeks[0]}`;
        });
      case 'month':
        return data.map(item => {
          const newItem = item.split('-').reverse();
          newItem.shift();
          return newItem.join('.');
        });
      case 'quarter':
        return data.map(item => {
          const newItem = item.split('-').reverse();
          const quarter = Math.ceil(+newItem[1] / 3);
          return `${quarter} ${translation.chart.quarter} ${newItem[2]}`;
        });
      case 'year':
        return data.map(item => item.split('-')[0]);
      default:
        return data;
    }
  };

  prepareConfig = () => {
    const propsData = this.props.data.data;
    const { datePickerDate, transitionIsActive, transition } = this.props;
    const subScenarioId = propsData['sub-scenario'] ? propsData['sub-scenario'] : 0;
    const targets = propsData.xNames.source;
    const that = this;
    const { height } = this.props.data;
    let h = height || 1;
    let calcHeight = 120;
    if (height > 1) {
      calcHeight = 163 * h - 43;
    }

    const dateObj = new Date();
    const month = dateObj.getUTCMonth() + 1;
    const day = dateObj.getUTCDate();
    const year = dateObj.getUTCFullYear();

    const dateNow = day + '_' + month + '_' + year;
    const isAbleToTransition = transition && Object.keys(transition).length;
    const isAbleToDrill = subScenarioId > 0;

    propsData.xNames.data.map(elem => {
      var dateWrapper = new Date(propsData.xNames.data[0]);
      if (!isNaN(dateWrapper.getDate())) {
        propsData.xNames.data = this.dateNamesTransformHandle(propsData.xNames.source, propsData.xNames.data);
      }
      if (elem === null) {
        const index = propsData.xNames.data.indexOf(elem);
        propsData.xNames.data[index] = 'NO NAME';
      }
    });

    propsData.source.map(kpi => {
      const newData = [];
      kpi.data.map((item, key) => {
        newData.push({
          y: item && item.y ? item.y : item,
          name: propsData.xNames.data[key]
        });
      });
      kpi.data = newData;
    });

    propsData.plotType.series = {
      ...propsData.plotType.series,
      point: {
        events: {
          mouseOver: function() {
            if (isAbleToDrill && !that.props.updatesLocked) {
              this.dataLabel.css({
                cursor: 'pointer',
                textDecoration: 'underline'
              });
            } else if (isAbleToTransition && !that.props.updatesLocked) {
              this.dataLabel.css({
                cursor: 'pointer',
                textDecoration: 'underline'
              });
            } else {
              this.dataLabel.css({
                cursor: 'default',
                textDecoration: 'none'
              });
            }
          },
          mouseOut: function() {
            if (isAbleToDrill) {
              this.dataLabel.css({
                textDecoration: 'none'
              });
            } else if (isAbleToTransition) {
              this.dataLabel.css({
                textDecoration: 'none'
              });
            }
          },
          click: function() {
            const nameScenario = this.name;
            let key = propsData.xNames.data.indexOf(nameScenario);
            if (isAbleToDrill && !that.props.updatesLocked) {
              const filter = that.prepareFilter(targets, propsData.xNames.resultSetAll[key]);
              that.getSubScenarioData(subScenarioId, filter, targets);
            } else if (isAbleToTransition && !that.props.updatesLocked) {
              const invisibleFilters = { [targets]: propsData.xNames.resultSetAll[key].name };
              const filter = that.prepareFilter(
                targets,
                propsData.xNames.resultSetAll[key],
                transition,
                datePickerDate
              );
              that.groupTransition(filter, targets, invisibleFilters);
              transitionIsActive(true);
            }
          }
        }
      },
      dataLabels: {
        enabled: true,
        color: `${subScenarioId > 0 ? '#1AA7E0' : '#000'}`,
        style: {
          fontStyle: `${transition ? 'italic' : 'normal'}`
        }
      },
      stickyTracking: false
    };

    const config = {
      chart: {
        height: calcHeight
      },
      credits: {
        enabled: false
      },
      title: {
        text: propsData.name,
        style: {
          color: '#293258'
        }
      },
      exporting: {
        filename: `${dateNow}_${propsData.name}`,
        csv: {
          itemDelimiter: ';'
        },
        buttons: {
          contextButton: {
            enabled: false
          }
        }
      },
      legend: {
        itemStyle: {
          color: '#293258'
        }
      },
      plotOptions: propsData.plotType,
      xAxis: {
        categories: propsData.xNames.data,
        title: {
          text: propsData.xNames.source,
          enabled: false
        }
      },
      yAxis: {
        title: {
          text: ''
        }
      },

      series: propsData.source
    };

    this.setState({
      config: config
    });
  };

  downloadCSV = () => {
    const chart = this.chartRef.current.getChart();
    chart.downloadCSV();
  };

  render() {
    const { config } = this.state;
    const { data, language } = this.props;
    const { height } = data;
    let h = height || 1;
    let calcHeight = 120;
    if (height > 1) {
      calcHeight = 163 * h - 43;
    }

    return (
      <div className={styles.chart} style={{ height: +calcHeight }}>
        <div className={styles.topButtons}>
          <ExportDataComponent data={data} translation={language.translation} />
        </div>
        <ReactHighcharts config={config} ref={this.chartRef} />
      </div>
    );
  }
}

Chart.defaultProps = {
  data: {},
  language: {},
  updatesLocked: false
};

Chart.propTypes = {
  language: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  data: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  updatesLocked: PropTypes.bool, // eslint-disable-line react/forbid-prop-types
  filters: PropTypes.func.isRequired, // eslint-disable-line
  updateFilters: PropTypes.func.isRequired, // eslint-disable-line
  setDrillFilters: PropTypes.func.isRequired, // eslint-disable-line
  transition: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  transitionIsActive: PropTypes.func.isRequired // eslint-disable-line
};

export default connect(store => ({
  language: store.language,
  parentScenario: store.parentScenario,
  datePickerDate: store.datePickerDate
}))(Chart);
