import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row, Col } from 'react-flexbox-grid';
import cls from 'classnames';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Table as TableToolBox, TableHead, TableRow, TableCell } from 'react-toolbox/lib/table';
import ButtonDefault from '../Buttons/ButtonDefault';
import { loadTask, setPageLoadingStatus } from '../../store/actions/actions';
import configAxios from '../../config/axios';
import Preload from '../../constructors/Preload';
import Comment from '../../components/tableComponents/CommentMobile/CommentMobile';
import Search from '../../components/tableComponents/Search/Search';
import TaskDropdown from '../../components/tableComponents/TaskDropdownMobile/TaskDropdownMobile';
import CarouselPhoto from '../../components/tableComponents/CarouselPhoto/CarouselPhoto';

import styles from './MobilePlanogramTable.css';
import { updatePriceFormat } from '../../utils/utils';

const style = () => ({
  progressSelectedItem: {
    color: '#00A5E6'
  },
  progressStatus: {
    color: '#42bde8',
    verticalAlign: 'middle'
  }
});

class MobilePlanogramTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      infoResponse: {},
      infoLoading: true,
      selectedItem: null,
      loadingArray: [],
      cancelRequest: ''
    };

    this.withoutColumns = ['tree', 'image', 'info', 'comment'];
    this.showTalbeCol = ['plu_original', 'status', 'category_name'];

    this.preload = new Preload();
    this.preload.subscribe(promises => {
      this.props.dispatch(setPageLoadingStatus(promises.length > 0));
    });
  }

  /**
   *
   * @param nextProps
   */
  componentWillReceiveProps(nextProps) {
    const itemIdField = this.props.tableType === 'direct' ? 'item_id' : 'random_plu_id';

    if (nextProps.items !== this.props.items && this.state.selectedItem) {
      let nextSelectedItem = null;

      nextProps.items.forEach(item => {
        if (item[itemIdField] === this.state.selectedItem[itemIdField]) {
          nextSelectedItem = item;
        }
      });

      this.setState({
        selectedItem: nextSelectedItem
      });
    }
  }

  handleItemOpen = item => {
    const itemIdField = this.props.tableType === 'direct' ? 'item_id' : 'random_plu_id';
    this.setState(
      {
        selectedItem: item,
        infoResponse: {},
        infoLoading: true
      },
      () => {
        this.loadInfoFromServer(item[itemIdField]);
      }
    );
  };

  handleItemClose = () => {
    this.setState({
      selectedItem: null
    });
  };

  loadInfoFromServer(itemId) {

    const query = new URLSearchParams(location.search);
    const mid = query.has('mid') ? Number(query.get('mid')) : JSON.parse(localStorage.mission).id;

    if (this.props.tableType === 'direct') {
      configAxios({
        method: 'POST',
        url: `/${this.props.urlApi}/info`,
        data: {
          missionId: mid,
          itemId
        }
      }).then(
        response => {
          this.setState({
            infoLoading: false,
            infoResponse: updatePriceFormat(response.data.data)
          });
        },
        () => {
          this.setState({
            infoLoading: false
          });
        }
      );
    }
  }

  loadActionToServer = formData => {
    const { dispatch, items, tableType, urlApi } = this.props;
    const { loadingArray, selectedItem } = this.state;
    const itemIdField = tableType === 'direct' ? 'item_id' : 'random_plu_id';
    let newLoadingArray = loadingArray;

    const selectedIndex = items.indexOf(selectedItem);
    items[selectedIndex].tree = 'loading';

    newLoadingArray.push(selectedItem);

    this.setState({
      loadingArray: newLoadingArray
    });

    configAxios({
      method: 'POST',
      url: `/${urlApi}/update`,
      data: formData
    })
      .then(response => {
        if (response.status === 200) {
          const loadingArrayIndex = newLoadingArray.indexOf(selectedItem);
          newLoadingArray.splice(loadingArrayIndex, 1);

          this.setState(
            {
              openMenu: false,
              loadingImg: false,
              loadingArray: newLoadingArray
            },
            () => {
              const selectedItemNew = this.state.selectedItem;
              dispatch(loadTask(true, true));

              if (selectedItemNew && selectedItemNew[itemIdField] === selectedItem[itemIdField]) {
                this.loadInfoFromServer(selectedItemNew[itemIdField]);
              }
            }
          );
        }
      })
      .catch(() => {
        this.setState({
          loadingImg: false,
          imageFile: null
        });
      });
  };

  deleteCase = (itemId, caseType) => {
    const { dispatch, shopId } = this.props;
    const query = new URLSearchParams(location.search);
    const mid = query.has('mid') ? Number(query.get('mid')) : JSON.parse(localStorage.mission).id;
    const promise = configAxios({
      method: 'DELETE',
      url: `/task/delete`,
      params: {
        itemId,
        missionId: mid,
        shopId,
        caseType
      }
    });

    promise.then(function() {
      dispatch(loadTask(true));
    });

    return promise;
  };

  render() {
    const { shopId, items, headers, suggest, manualCases, tableType, classes, urlApi } = this.props;
    const { infoResponse, infoLoading, selectedItem } = this.state;

    const lang = this.props.language.translation.directTask;
    const itemIdField = tableType === 'direct' ? 'item_id' : 'random_plu_id';

    const { translation } = this.props.language;
    const infoResponseKeys = Object.keys(infoResponse).slice(1);
    return (
      <div>
        {items.length > 0 && (
          <TableToolBox selectable={false} className={styles.table}>
            <TableHead className={styles.tableHeadRow}>
              {this.showTalbeCol.map(key => {
                if (key === 'product_name' && tableType === 'direct') {
                  return (
                    <TableCell key={key} className={[styles.tableCell, styles.tableHeadCell].join(' ')}>
                      <Search
                        suggest={suggest}
                        shopId={this.props.shopId}
                        openOnlyCases={this.props.openOnlyCases}
                        textFieldStyle={{
                          fontSize: '13px'
                        }}
                        shortHint
                      />
                    </TableCell>
                  );
                }
                return (
                  <TableCell key={key} className={[styles.tableCell, styles.tableHeadCell].join(' ')}>
                    {headers[key]}
                  </TableCell>
                );
              })}
            </TableHead>

            {items.map((item, index) => {
              const keyCode = index;
              return (
                <TableRow
                  key={keyCode}
                  class={cls(styles.tableRow, {
                    [styles.tableRowStatus]: item.tree_status_id !== 0 && item.action_type !== 0,
                    [styles.manualCasesStyle]: manualCases && item.type_id === 4,
                    [styles.finalCasesStyle]: item.is_final,
                    [styles.loading]: item.tree === 'loading'
                  })}
                  onClick={() => {
                    if (item.tree !== 'loading') {
                      this.handleItemOpen(item);
                    }
                  }}
                >
                  {this.showTalbeCol.map(field => (
                    <TableCell key={field} class={styles.tableCell}>
                      {field === 'status' && item.tree === 'loading' ? (
                        <CircularProgress class={classes.progressStatus} size={30} />
                      ) : (
                        item[field]
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableToolBox>
        )}

        {selectedItem && (
          <div className={styles.selectedCaseContainer}>
            <div className={styles.closeSelectedCase}>
              <CloseIcon onClick={this.handleItemClose} />
            </div>
            <div className={styles.selectedCaseWrap}>
              {Object.keys(headers).map(key => {
                if (this.withoutColumns.indexOf(key) !== -1) {
                  return;
                }
                return (
                  <Row key={key} between="xs" className={styles.selectedCaseItem}>
                    <Col xs={4} className={styles.selectedCaseItemName}>
                      <div>{headers[key]}:</div>
                    </Col>
                    <Col xs={8} className={styles.selectedCaseItemValue}>
                      {selectedItem[key]}
                    </Col>
                  </Row>
                );
              })}

              {tableType === 'direct' && infoLoading && (
                <Row between="xs" class={styles.infoPreload}>
                  <Col xs={12}>
                    <CircularProgress class={classes.progressSelectedItem} size={20} />
                  </Col>
                </Row>
              )}

              {tableType === 'direct' &&
                !infoLoading &&
                infoResponseKeys.length > 0 &&
                infoResponseKeys.map(element => {
                  if (element === 'Plus') {
                    return (
                      <Row between="xs" key={element} className={styles.selectedCaseItem}>
                        <Col xs={4} className={styles.selectedCaseItemName}>
                          <div>{translation.gridTable.pluList}:</div>
                        </Col>
                        <Col xs={8} className={[styles.selectedCaseItemValue, styles.caseWithShops].join(' ')}>
                          {infoResponse[element].map((elem, index) => {
                            let key = index;

                            return (
                              <div key={elem.id + key} className={styles.planItem}>
                                <span>
                                  {index + 1}) ID: {elem.id}{' '}
                                </span>
                                {elem.name}
                              </div>
                            );
                          })}
                        </Col>
                      </Row>
                    );
                  } else {
                    return (
                      <Row between="xs" key={element} className={styles.selectedCaseItem}>
                        <Col xs={4} className={styles.selectedCaseItemName}>
                          <div>{element}:</div>
                        </Col>
                        <Col xs={8} className={styles.selectedCaseItemValue}>
                          {infoResponse[element]}
                        </Col>
                      </Row>
                    );
                  }
                })}
              <div className={styles.controlWrap}>
                {manualCases && selectedItem.type_id === 4 ? (
                  <ButtonDefault fullWidth onClick={() => this.deleteCase(selectedItem.item_id, selectedItem.type_id)}>
                    {lang.delete}
                  </ButtonDefault>
                ) : (
                  <TaskDropdown
                    data={typeof selectedItem['tree'] === 'object' ? selectedItem['tree'] : []}
                    shopId={shopId}
                    caseType={selectedItem.type_id}
                    itemId={selectedItem[itemIdField]}
                    loadToServer={this.loadActionToServer}
                  />
                )}
              </div>
            </div>

            {tableType === 'direct' && (
              <div className={styles.controlWrap}>
                <Comment
                  urlApi={urlApi}
                  shopId={shopId}
                  itemId={selectedItem.item_id}
                  text={selectedItem.comment ? selectedItem.comment : ''}
                  caseType={selectedItem.type_id}
                />
              </div>
            )}

            {tableType === 'direct' && selectedItem['image'] && (
              <div className={styles.controlWrap}>
                <CarouselPhoto mobile mobileBtnText={lang.photo} images={selectedItem['image']} />
              </div>
            )}

            <div className={styles.controlWrap}>
              <ButtonDefault fullWidth onClick={this.handleItemClose}>
                {lang.back}
              </ButtonDefault>
            </div>
          </div>
        )}
      </div>
    );
  }
}

MobilePlanogramTable.defaultProps = {
  items: [],
  headers: {},
  suggest: ''
};

MobilePlanogramTable.propTypes = {
  urlApi: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  items: PropTypes.array,
  headers: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  suggest: PropTypes.string,
  classes: PropTypes.object,
  shopId: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
  openOnlyCases: PropTypes.bool.isRequired,
  tableType: PropTypes.string.isRequired, // eslint-disable-line
  language: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  manualCases: PropTypes.bool.isRequired
};

// export default Table;
export default connect(store => store)(withStyles(style)(MobilePlanogramTable));
