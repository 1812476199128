export default {
  mobileFirefoxAlert: 'In questo browser, l\'elaborazione delle attività non è disponibile.',
  datePicker: {
    from: 'Da',
    to: 'A',
    cancel: 'Annulla',
    apply: 'Applica',
    month: [
      'Gennaio',
      'Febbraio',
      'Marzo',
      'Aprile',
      'Maggio',
      'Giugno',
      'Luglio',
      'Agosto',
      'Settembre',
      'Ottobre',
      'Novembre',
      'Dicembre'
    ],
    week: 'settimana',
    weekShot: 'sett.',
    buttonName: {
      date: 'Data',
      week: 'Settimana',
      month: 'Mese'
    },
    modal: {
      header: 'Avvertenza',
      text: 'Intervallo di date non valido selezionato',
      action: 'Accetta'
    }
  },
  filters: {
    name: 'Nome'
  },
  scenario: {
    noDataReceived: 'Nessun dato ricevuto',
    filterPart: {
      name: 'Nome',
      clear: 'Cancella',
      notFound: 'Non trovato',
      search: 'Cerca...'
    }
  },
  header: {
    logout: 'Logout',
    changePassword: 'Cambia password',
    settings: 'Verifica',
    title: 'Visualizza interfaccia utente',
    searchTitle: 'Cerca utente',
    input: 'Inizia a digitare il nome o il login...',
    cancel: 'Annulla',
    view: 'Visualizza',
    id: 'ID',
    login: 'Login',
    name: 'Nome',
    phone: 'Telefono',
    email: 'E-mail',
    stopSettings: 'Interrompi visualizzazione'
  },
  directTask: {
    addCase: 'Aggiungi caso',
    commentCase: 'Commenta caso',
    actions: 'Azioni',
    searchProduct: 'PLU/Codice a barre',
    searchProductShort: 'PLU/Codice a barre',
    photo: 'Foto',
    back: 'Indietro',
    treeEmpty: 'LE AZIONI NON SONO DISPONIBILI',
    step: 'Passo',
    sortingButton: 'Ordinamento',
    sortButton: 'Ordina',
    add: 'Aggiungi',
    delete: 'Elimina',
    deleteManualCases: 'Elimina casi manuali',
    show: 'Mostra',
    hide: 'Nascondi',
    comment: 'Commento',
    showDoneCases: 'Mostra le attività completate',
    hideDoneCases: 'Nascondi le attività completate'
  },
  gridTable: {
    pluList: 'Lista PLU'
  },
  casesForm: {
    product: 'Prodotto',
    loadFoto: 'Carica foto',
    plu: 'Nome/PLU',
    success: 'Caso creato',
    delete: 'Eliminare',
    add: 'Creare',
    new: 'Nuovo',
    cancel: 'Annulla',
    comment: 'Commento'
  },
  pagination: {
    apply: 'Vai',
    placeholder: 'Pagina'
  },
  restorePass: {
    noLength: 'La password deve contenere almeno 12 caratteri',
    noLowercase: 'La password deve contenere almeno una lettera minuscola',
    noUppercase: 'La password deve contenere almeno una lettera maiuscola',
    noNumber: 'La password deve contenere almeno un simbolo numerico',
    noMatch: 'Le password non corrispondono',
    password: 'Password',
    repeatPassword: 'Ripeti la password',
    ok: 'Accetta',
    cancel: 'Annulla'
  },
  navigation: {
    missions: 'Missioni',
    scenarios: 'Scenari'
  },
  changePassword: {
    previousPassword: 'Password attuale',
    newPassword: 'Nuova password',
    confirmPassword: 'Ripeti nuova password',
    apply: 'Applica',
    title: 'Cambia password',
    success: 'Password modificata con successo',
    wrongPassword: 'Password errata',
    weakPassword: 'La password non soddisfa i requisiti',
    passwordsNotMatch: 'Le password non corrispondono',
    requirements:
      'La nuova password deve contenere almeno 12 caratteri, 1 lettera maiuscola, 1 lettera minuscola e 1 numero'
  },
  auth: {
    login: 'Login',
    password: 'Password',
    restorePass: 'Ripristina password',
    submit: 'Invia',
    loginError: 'Login e/o password non validi'
  },
  restorePassForm: {
    restore: 'Ripristina',
    cancel: 'Annulla',
    info: 'Inserisci la tua e-mail, registrata nella piattaforma OSA Hybrid',
    emailInvalid: 'L\'e-mail inserita non è stata trovata',
    email: 'E-mail',
    success: 'Il link per il ripristino è stato inviato alla tua e-mail'
  },
  search: {
    notFound: 'Non trovato'
  },
  noMatchPage: {
    title: 'Pagina richiesta non trovata',
    body: 'Torna alla pagina iniziale'
  },
  warnings: {
    title: 'Avviso',
    isDesktop: 'Il caricamento delle foto è disponibile solo nella versione mobile del sistema. Usa la fotocamera del tuo telefono o tablet, per favore.'
  },
  errors: {
    netErrorText: 'Errore di rete. Per favore, ricarica la pagina',
    netErrorReload: 'Ricarica',
    netErrorTitle: 'Errore',
    netErrorClose: 'Chiudi',
    noShops: 'Oops! Sembra che non ti sia stato ancora assegnato alcun negozio. Dillo al tuo manager',
    noCases: 'Sembra che il nostro servizio non sia riuscito a trovare un solo caso in questo negozio. Inizia a crearli tu stesso',
    noAutoOrder: 'Non sono presenti attività per regolare l\'ordine automatico',
    causeError: 'Errore',
    scenariosEmpty: 'L\'array degli scenari è vuoto'
  },
  officeUI: {
    resetZoom: 'Ripristina zoom'
  },
  gridAutocomplete: {
    placeholder: 'Inizia a digitare il nome del negozio',
    notFound: 'Negozio non trovato'
  },
  targets: {
    nothing: '',
    shop: 'Negozio',
    category: 'Categoria',
    supplier: 'Fornitore',
    vendor: 'Venditore',
    brand: 'Marca',
    plu: 'PLU',
    day: 'Giorno',
    week: 'Settimana',
    month: 'Mese',
    quarter: 'Trimestre',
    year: 'Anno'
  },
  bubbleChart: {
    qty: 'qta',
    currency: 'rub'
  },
  chart: {
    quarter: 'trimestre',
    week: 'settimana'
  },
  download: {
    CSV: 'Scarica CSV',
    XLS: 'Scarica XLS'
  },
  counterTask: {
    task: 'Compiti in sospeso',
    noTask: 'Nessun compito'
  },
  filtersShop: {
    shopName: 'Indirizzo del negozio',
    shopId: 'Nome del negozio'
  },
  chartCaption: {
    thousand: 'mila',
    million: 'milione',
    billion: 'miliardo'
  },
  common: {
    close: 'Chiudi',
    userNotFound: 'Utente non trovato',
    yes: 'Sì',
    no: 'No'
  },
  virtualStock: {
    headers: {
      case_id: 'ID',
      plu_original: 'PLU',
      plu: 'PLU interno',
      shop_id: 'ID negozio',
      ass_type: 'Tipo assortimento',
      category_id3: 'ID sezione',
      category_id4: 'ID categoria',
      stock: 'Volume dei residui',
      stock_sum: 'Quantità di bilanci',
      product_turnover: 'Rotazione',
      days_without_sales: 'Giorni senza vendite',
      last_sales_date: 'Data dell\'ultima venditaa',
      last_sales_volume: 'Ultimo volume di vendita',
      last_shipment_date: 'Ultima data di consegna',
      last_shipment_volume: 'Ultimo volume di consegna',
      promo_start: 'Inizio promozione',
      promo_end: 'Fine promozione',
      promo_period: 'Periodo promozione',
      is_promo: 'Promozione',
      title: 'Nome',
      category_name3: 'Sezione',
      category_name4: 'Categoria'
    },
    hideReadyTasks: 'Nascondi compiti pronti',
    nothingFound: 'Niente trovato',
    shopsNotFound: 'Negozi non trovati',
    chooseShop: 'Scegli un negozio',
    createTasks: 'Crea compiti',
    taskCreating: 'Creazione compiti',
    next: 'Continua',
    columns: 'Colonne',
    from: 'Da',
    ok: 'OK',
    cancel: 'Annulla',
    filter: 'Filtro',
    chooseValue: 'Scegli un valore',
    enterValue: 'Inserisci il valore',
    rangeFrom: 'Da',
    rangeTo: 'A',
    selected: 'Selezionato',
    foundRows: 'Righe trovate',
    createdTasksTotal: 'Compiti totali creati'
  }
};
