export default {
  mobileFirefoxAlert: 'Kjo funksion nuk është i disponueshëm në këtë shfletues.',
  datePicker: {
    from: 'Nga',
    to: 'Deri',
    cancel: 'Anulo',
    apply: 'Zbato',
    month: [
      'Janar',
      'Shkurt',
      'Mars',
      'Prill',
      'Maj',
      'Qershor',
      'Korrik',
      'Gusht',
      'Shtator',
      'Tetor',
      'Nëntor',
      'Dhjetor'
    ],
    week: 'javë',
    weekShot: 'jav.',
    buttonName: {
      date: 'Data',
      week: 'Javë',
      month: 'Muaj'
    },
    modal: {
      header: 'Kujdes',
      text: 'Përzierja e dates nuk është e vlefshme',
      action: 'Prano'
    }
  },
  filters: {
    name: 'Emri'
  },
  scenario: {
    noDataReceived: 'Nuk ka të dhëna të marrura',
    filterPart: {
      name: 'Emri',
      clear: 'Pastro',
      notFound: 'Nuk u gjet',
      search: 'Kërko...'
    }
  },
  header: {
    logout: 'Çkyç',
    changePassword: 'Ndrysho fjalëkalimin',
    settings: 'Cilësimet',
    title: 'Shiko ndërfaqen e përdoruesit',
    searchTitle: 'Kërko përdoruesin',
    input: 'Fillo shkruar emrin ose hyrjen...',
    cancel: 'Anulo',
    view: 'Shiko',
    id: 'ID',
    login: 'Hyrja',
    name: 'Emri',
    phone: 'Telefoni',
    email: 'Emaili',
    stopSettings: 'Ndaloni shikimin'
  },
  directTask: {
    addCase: 'Shto rast',
    commentCase: 'Komento rastin',
    actions: 'Veprimet',
    searchProduct: 'PLU/Barkodi',
    searchProductShort: 'PLU/Barkodi',
    photo: 'Foto',
    back: 'Mbrapa',
    treeEmpty: 'VEPRIMET NUK JANË TË DISPONUESHME',
    step: 'Hapi',
    sortingButton: 'Rendit',
    sortButton: 'Rendis',
    add: 'Shto',
    delete: 'Fshi',
    deleteManualCases: 'Fshi rastet manuale',
    show: 'Shfaq',
    hide: 'Fshih',
    comment: 'Komento',
    showDoneCases: 'Trego detyrat e përfunduara',
    hideDoneCases: 'Fshih detyrat e përfunduara'
  },
  gridTable: {
    pluList: 'Lista PLU'
  },
  casesForm: {
    product: 'Produkti',
    loadFoto: 'Ngarko Foto',
    plu: 'Emri/PLU',
    success: 'Rasti është krijuar',
    delete: 'Fshije',
    add: 'Krijo',
    new: 'I ri',
    cancel: 'Anulo',
    comment: 'Komento'
  },
  pagination: {
    apply: 'Shko',
    placeholder: 'Faqja'
  },
  restorePass: {
    noLength: 'Fjalëkalimi duhet të ketë të paktën 12 karaktere',
    noLowercase: 'Fjalëkalimi duhet të ketë të paktën një shkronjë të vogël',
    noUppercase: 'Fjalëkalimi duhet të ketë të paktën një shkronjë të madhe',
    noNumber: 'Fjalëkalimi duhet të ketë të paktën një simbol numerik',
    noMatch: 'Fjalëkalimet nuk përputhen',
    password: 'Fjalëkalimi',
    repeatPassword: 'Përsërit fjalëkalimin',
    ok: 'Prano',
    cancel: 'Anulo'
  },
  navigation: {
    missions: 'Misione',
    scenarios: 'Skenarët'
  },
  changePassword: {
    previousPassword: 'Fjalëkalimi aktual',
    newPassword: 'Fjalëkalimi i ri',
    confirmPassword: 'Përsërit fjalëkalimin e ri',
    apply: 'Zbato',
    title: 'Ndrysho fjalëkalimin',
    success: 'Fjalëkalimi është ndryshuar me sukses',
    wrongPassword: 'Fjalëkalim i gabuar',
    weakPassword: 'Fjalëkalimi nuk përputhet me kërkesat',
    passwordsNotMatch: 'Fjalëkalimet nuk përputhen',
    requirements: 'Fjalëkalimi i ri duhet të ketë të paktën 12 karaktere, një shkronjë të madhe dhe të vogël dhe një numër'
  },
  auth: {
    login: 'Hyrja',
    password: 'Fjalëkalimi',
    restorePass: 'Rikthe fjalëkalimin',
    submit: 'Dërgo',
    loginError: 'Hyrja dhe/ose fjalëkalimi janë të pavlefshëm'
  },
  restorePassForm: {
    restore: 'Rikthe',
    cancel: 'Anulo',
    info: 'Ju lutem vendosni e-mailin tuaj, i cili është i regjistruar në Platformën OSA Hybrid',
    emailInvalid: 'E-mail-i i dorëzuar nuk u gjet',
    email: 'E-mail',
    success: 'Lidhja për rikthimin është dërguar në e-mailin tuaj'
  },
  search: {
    notFound: 'Nuk u gjet'
  },
  noMatchPage: {
    title: 'Faqja e kërkuar nuk u gjet',
    body: 'Kthehu te faqja kryesore'
  },
  warnings: {
    title: 'Kujdes',
    isDesktop: 'Ngarkimi i fotove është i disponueshëm vetëm në versionin mobil të sistemit. Ju lutem përdorni kamerën në telefonin ose tabletën tuaj.'
  },
  errors: {
    netErrorText: 'Gabim në rrjet. Ju lutem rifreskoni faqen',
    netErrorReload: 'Rifresko',
    netErrorTitle: 'Gabim',
    netErrorClose: 'Mbyll',
    noShops: 'Oopse! Me sa duket nuk ju është caktuar ende asnjë dyqan. Tregojini menaxherit tuaj për këtë',
    noCases: 'Duket se shërbimi ynë nuk ka gjetur asnjë rast në këtë dyqan. Filloni t\'i krijoni ato vetë',
    noAutoOrder: 'Nuk ka detyra për të rregulluar porosinë automatike',
    causeError: 'Gabim',
    scenariosEmpty: 'Nërritjet e skenarëve janë të zbrazëta'
  },
  officeUI: {
    resetZoom: 'Rivendos zbërthimin'
  },
  gridAutocomplete: {
    placeholder: 'Fillo shkruar emrin e dyqanit',
    notFound: 'Dyqani nuk u gjet'
  },
  targets: {
    nothing: '',
    shop: 'Dyqan',
    category: 'Kategoria',
    supplier: 'Furnizuesi',
    vendor: 'Shitësi',
    brand: 'Brendi',
    plu: 'PLU',
    day: 'Dita',
    week: 'Javë',
    month: 'Muaj',
    quarter: 'Çerek',
    year: 'Viti'
  },
  bubbleChart: {
    qty: 'sasia',
    currency: 'lek'
  },
  chart: {
    quarter: 'çerek',
    week: 'javë'
  },
  download: {
    CSV: 'Shkarko CSV',
    XLS: 'Shkarko XLS'
  },
  counterTask: {
    task: 'Detyra në pritje',
    noTask: 'Asnjë detyrë'
  },
  filtersShop: {
    shopName: 'Adresa e dyqanit',
    shopId: 'Emri i dyqanit'
  },
  chartCaption: {
    thousand: 'mijë',
    million: 'M',
    billion: 'Mld'
  },
  common: {
    close: 'Mbyll',
    userNotFound: 'Përdoruesi nuk u gjet',
    yes: 'Po',
    no: 'Jo'
  },
  virtualStock: {
    headers: {
      case_id: 'ID',
      plu_original: 'PLU',
      plu: 'PLU i brendshëm',
      shop_id: 'ID e dyqanit',
      ass_type: 'Lloji i asortimentit',
      category_id3: 'ID e seksionit',
      category_id4: 'ID e kategorisë',
      stock: 'Volumi i mbetjeve',
      stock_sum: 'Shuma e balancave',
      product_turnover: 'Qarkullimi',
      days_without_sales: 'Ditë pa shitje',
      last_sales_date: 'Data e shitjes së fundit',
      last_sales_volume: 'Vëllimi i fundit i shitjes',
      last_shipment_date: 'Data e fundit e dorëzimit',
      last_shipment_volume: 'Vëllimi i fundit i dorëzimit',
      promo_start: 'Fillimi i promocionit',
      promo_end: 'Përfundimi i promocionit',
      promo_period: 'Periudha e promocionit',
      is_promo: 'Promocioni',
      title: 'Emri',
      category_name3: 'Seksioni',
      category_name4: 'Kategoria'
    },
    hideReadyTasks: 'Fshih detyrat e përfunduara',
    nothingFound: 'Nuk u gjet asgjë',
    shopsNotFound: 'Duket se ende nuk janë caktuar dyqanet. Njoftoni supervisorin për këtë.',
    chooseShop: 'Zgjidh një dyqan',
    createTasks: 'Krijo detyrat',
    taskCreating: 'Krijimi i detyrave',
    next: 'Vazhdo',
    columns: 'Kolonat',
    from: 'Nga',
    ok: 'OK',
    cancel: 'Anulo',
    filter: 'Filtro',
    chooseValue: 'Zgjidh një vlerë',
    enterValue: 'Vendos një vlerë',
    rangeFrom: 'Nga',
    rangeTo: 'Deri',
    selected: 'Të zgjedhura',
    foundRows: 'Rreshtat e gjetura',
    createdTasksTotal: 'Totali i detyrave të krijuara'
  }
};
