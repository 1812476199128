export default {
  mobileFirefoxAlert: 'En este navegador no está disponible el procesamiento de tareas.',
  datePicker: {
    from: 'Desde',
    to: 'Hasta',
    cancel: 'Cancelar',
    apply: 'Aplicar',
    month: [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre'
    ],
    week: 'semana',
    weekShot: 'sem',
    buttonName: {
      date: 'Fecha',
      week: 'Semana',
      month: 'Mes'
    },
    modal: {
      header: 'Advertencia',
      text: 'Se ha seleccionado un rango de fechas no válido',
      action: 'Aceptar'
    }
  },
  filters: {
    name: 'Nombre'
  },
  header: {
    logout: 'Cerrar sesión',
    changePassword: 'Cambiar contraseña',
    settings: 'Configuración',
    title: 'Ver interfaz de usuario',
    searchTitle: 'Buscar usuario',
    input: 'Comience a escribir el nombre o el inicio de sesión...',
    cancel: 'Cancelar',
    view: 'Ver',
    id: 'ID',
    login: 'Inicio de sesión',
    name: 'Nombre',
    phone: 'Teléfono',
    email: 'Correo electrónico',
    stopSettings: 'Detener vista'
  },
  scenario: {
    noDataReceived: 'No se recibieron datos',
    filterPart: {
      name: 'Nombre',
      clear: 'Limpiar',
      notFound: 'No encontrado',
      search: 'Buscar...'
    }
  },
  directTask: {
    addCase: 'Agregar caso',
    addComment: 'Comentario',
    actions: 'Acciones',
    searchProduct: 'PLU/Código de barras',
    searchProductShort: 'PLU/Código de barras',
    photo: 'Foto',
    back: 'Atrás',
    treeEmpty: 'ACCIONES NO DISPONIBLES',
    step: 'Paso',
    sortingButton: 'Ordenar',
    sortButton: 'Orden',
    add: 'Agregar',
    delete: 'Eliminar',
    deleteManualCases: 'Eliminar tarea',
    show: 'Mostrar',
    hide: 'Ocultar',
    comment: 'Comentario',
    showDoneCases: 'Mostrar tareas disponibles',
    hideDoneCases: 'Ocultar tareas completadas'
  },
  gridTable: {
    pluList: 'Lista de PLU'
  },
  casesForm: {
    product: 'Producto',
    loadFoto: 'Cargar foto',
    plu: 'Nombre/PLU',
    success: 'Caso creado',
    add: 'Crear',
    new: 'Nuevo',
    delete: 'Borrar',
    cancel: 'Cancelar',
    comment: 'Comentario'
  },
  pagination: {
    apply: 'Ir',
    placeholder: 'Página'
  },
  restorePass: {
    noLength: 'La contraseña debe tener al menos 12 caracteres',
    noLowercase: 'La contraseña debe tener al menos una letra minúscula',
    noUppercase: 'La contraseña debe tener al menos una letra mayúscula',
    noNumber: 'La contraseña debe tener al menos un símbolo numérico',
    noMatch: 'Las contraseñas no coinciden',
    password: 'Contraseña',
    repeatPassword: 'Repetir contraseña',
    ok: 'Aceptar',
    cancel: 'Cancelar'
  },
  navigation: {
    missions: 'Misiones',
    scenarios: 'Escenarios'
  },
  changePassword: {
    previousPassword: 'Contraseña actual',
    newPassword: 'Nueva contraseña',
    confirmPassword: 'Confirmar nueva contraseña',
    apply: 'Aplicar',
    title: 'Cambiar contraseña',
    success: 'La contraseña se cambió exitosamente',
    wrongPassword: 'Contraseña incorrecta',
    weakPassword: 'La contraseña no cumple con los requisitos',
    passwordsNotMatch: 'Las contraseñas no coinciden',
    requirements: 
      'La nueva contraseña debe tener al menos 12 caracteres, una letra mayúscula y minúscula y un número'
  },
  auth: {
    login: 'Inicio de sesión',
    password: 'Contraseña',
    restorePass: 'Restablecer contraseña',
    submit: 'Enviar',
    loginError: 'Inicio de sesión y/o contraseña no válidos'
  },
  restorePassForm: {
    restore: 'Restaurar',
    cancel: 'Cancelar',
    info: 'Por favor, introduzca su correo electrónico registrado en la plataforma OSA Hybrid',
    emailInvalid: 'El correo electrónico proporcionado no se encontró',
    email: 'Correo electrónico',
    success: 'Se envió el enlace de restablecimiento a su correo electrónico'
  },
  search: {
    notFound: 'No encontrado'
  },
  noMatchPage: {
    title: 'Página solicitada no encontrada',
    body: 'Volver a la página de inicio'
  },
  warnings: {
    title: 'Advertencia',
    isDesktop: 'La carga de fotos solo está disponible en la versión móvil del sistema. Utilice la cámara de su teléfono o tableta, por favor.'
  },
  errors: {
    netErrorText: 'Error de red. Por favor, recargue la página',
    netErrorReload: 'Recargar',
    netErrorClose: 'Cerrar',
    netErrorTitle: 'Error',
    noShops: '¡OH! Parece que todavía no se te ha asignado ninguna tienda. Cuéntaselo a tu jefe',
    noCases: 'Parece que nuestro servicio no pudo encontrar ni un solo caso en esta tienda. Empieza a crearlos tú mismo',
    noAutoOrder: 'No hay tareas para ajustar el pedido automático.',
    causeError: 'Error',
    scenariosEmpty: 'El array de escenarios está vacío'
  },
  officeUI: {
    resetZoom: 'Restablecer zoom'
  },
  gridAutocomplete: {
    placeholder: 'Comience a escribir el nombre de la tienda',
    notFound: 'Tienda no encontrada'
  },
  targets: {
    nothing: '',
    shop: 'Tienda',
    category: 'Categoría',
    supplier: 'Proveedor',
    vendor: 'Vendedor',
    brand: 'Marca',
    plu: 'PLU',
    day: 'Día',
    week: 'Semana',
    month: 'Mes',
    quarter: 'Trimestre',
    year: 'Año'
  },
  bubbleChart: {
    qty: 'cant.',
    currency: 'rub'
  },
  chart: {
    quarter: 'trimestre',
    week: 'semana'
  },
  download: {
    CSV: 'Descargar CSV',
    XLS: 'Descargar XLS'
  },
  counterTask: {
    task: 'Tareas pendientes',
    noTask: 'No hay tareas'
  },
  filtersShop: {
    shopName: 'Dirección de la tienda',
    shopId: 'Nombre de la tienda'
  },
  chartCaption: {
    thousand: 'mil',
    million: 'millón',
    billion: 'mil millones'
  },
  common: {
    close: 'Cerrar',
    userNotFound: 'Usuario no encontrado',
    yes: 'Sí',
    no: 'No',
  },
  virtualStock: {
    headers: {
      case_id: "ID",
      plu_original: "PLU",
      plu: "PLU interno",
      shop_id: "ID de tienda",
      ass_type: "Tipo de surtido",
      category_id3: "ID de sección",
      category_id4: "ID de categoría",
      stock: "Volumen de existencias",
      stock_sum: "Cantidad de saldos",
      product_turnover: "Rotación",
      days_without_sales: "Días sin ventas",
      last_sales_date: "Última fecha de venta",
      last_sales_volume: "Volumen de última venta",
      last_shipment_date: "Última fecha de entrega",
      last_shipment_volume: "Último volumen de entrega",
      promo_start: "Inicio de promoción",
      promo_end: "Fin de promoción",
      promo_period: "Periodo de promoción",
      is_promo: "Promoción",
      title: "Nombre",
      category_name3: "Sección",
      category_name4: "Categoría"
    },
    hideReadyTasks: "Ocultar tareas completadas",
    nothingFound: "Nada encontrado",
    shopsNotFound: "Tiendas no encontradas",
    chooseShop: "Elegir una tienda",
    createTasks: "Crear tareas",
    taskCreating: "Creando tareas",
    next: "Continuar",
    columns: "Columnas",
    from: "Desde",
    ok: "Aceptar",
    cancel: "Cancelar",
    filter: "Filtrar",
    chooseValue: "Elegir un valor",
    enterValue: "Introducir valor",
    rangeFrom: "Desde",
    rangeTo: "Hasta",
    selected: "Seleccionado",
    foundRows: "Filas encontradas",
    createdTasksTotal: "Total de tareas creadas"
  }
};
