export default {
  mobileFirefoxAlert: 'В този браузър обработката на задачи не е налична.',
  datePicker: {
    from: 'От',
    to: 'До',
    cancel: 'Отказ',
    apply: 'Приложи',
    month: [
      'Януари',
      'Февруари',
      'Март',
      'Април',
      'Май',
      'Юни',
      'Юли',
      'Август',
      'Септември',
      'Октомври',
      'Ноември',
      'Декември'
    ],
    week: 'седмица',
    weekShot: 'седм.',
    buttonName: {
      date: 'Дата',
      week: 'Седмица',
      month: 'Месец'
    },
    modal: {
      header: 'Предупреждение',
      text: 'Избран е невалиден времеви диапазон',
      action: 'Приемам'
    }
  },
  filters: {
    name: 'Име'
  },
  scenario: {
    noDataReceived: 'Не са получени данни',
    filterPart: {
      name: 'Име',
      clear: 'Изчисти',
      notFound: 'Не е намерено',
      search: 'Търсене...'
    }
  },
  header: {
    logout: 'Изход',
    changePassword: 'Промяна на парола',
    settings: 'Настройки',
    title: 'Интерфейс за преглед',
    searchTitle: 'Търсене на потребител',
    input: 'Започнете да пишете име или потребител...',
    cancel: 'Отказ',
    view: 'Преглед',
    id: 'ИД',
    login: 'Потребителско име',
    name: 'Име',
    phone: 'Телефон',
    email: 'Имейл',
    stopSettings: 'Спиране на прегледа'
  },
  directTask: {
    addCase: 'Добави случай',
    commentCase: 'Коментирай случай',
    actions: 'Действия',
    searchProduct: 'PLU/Баркод',
    searchProductShort: 'PLU/Баркод',
    photo: 'Снимка',
    back: 'Назад',
    treeEmpty: 'ДЕЙНОСТИТЕ НЕ СА ДОСТЪПНИ',
    step: 'Стъпка',
    sortingButton: 'Сортиране',
    sortButton: 'Сортирай',
    add: 'Добави',
    delete: 'Изтрий',
    deleteManualCases: 'Изтрий ръчно случаите',
    show: 'Покажи',
    hide: 'Скрий',
    comment: 'Коментар',
    showDoneCases: 'Показване на изпълнените задачи',
    hideDoneCases: 'Скриване на изпълнените задачи'
  },
  gridTable: {
    pluList: 'Списък с PLU'
  },
  casesForm: {
    product: 'Продукт',
    loadFoto: 'Зареди снимка',
    plu: 'Име/PLU',
    success: 'Създаден случай',
    delete: 'Изтрий',
    add: 'Създавайте',
    new: 'Нов',
    cancel: 'Отказ',
    comment: 'Коментар'
  },
  pagination: {
    apply: 'Отиди',
    placeholder: 'Страница'
  },
  restorePass: {
    noLength: 'Паролата трябва да е поне 12 символа',
    noLowercase: 'Паролата трябва да има поне една малка буква',
    noUppercase: 'Паролата трябва да има поне една голяма буква',
    noNumber: 'Паролата трябва да има поне една цифра',
    noMatch: 'Паролите не съвпадат',
    password: 'Парола',
    repeatPassword: 'Повтори паролата',
    ok: 'Приеми',
    cancel: 'Отказ'
  },
  navigation: {
    missions: 'Задачи',
    scenarios: 'Сценарии'
  },
  changePassword: {
    previousPassword: 'Текуща парола',
    newPassword: 'Нова парола',
    confirmPassword: 'Повтори новата парола',
    apply: 'Приложи',
    title: 'Промяна на парола',
    success: 'Паролата е променена успешно',
    wrongPassword: 'Грешна парола',
    weakPassword: 'Паролата не отговаря на изискванията',
    passwordsNotMatch: 'Паролите не съвпадат',
    requirements: 'Новата парола трябва да има поне 12 символа, 1 главна и 1 малка буква и 1 цифра'
  },
  auth: {
    login: 'Потребител',
    password: 'Парола',
    restorePass: 'Възстановяване на парола',
    submit: 'Изпрати',
    loginError: 'Невалидно потребителско име и/или парола'
  },
  restorePassForm: {
    restore: 'Възстановяване',
    cancel: 'Отказ',
    info: 'Моля, въведете вашия имейл, регистриран в OSA Hybrid Platform',
    emailInvalid: 'Въведеният имейл не беше намерен',
    email: 'Имейл',
    success: 'Връзката за възстановяване беше изпратена на вашия имейл'
  },
  search: {
    notFound: 'Не е намерено'
  },
  noMatchPage: {
    title: 'Исканата страница не е намерена',
    body: 'Върни се на началната страница'
  },
  warnings: {
    title: 'Предупреждение',
    isDesktop: 'Качването на снимки е налично само в мобилната версия на системата. Моля, използвайте камерата на вашия телефон или таблет.'
  },
  errors: {
    netErrorText: 'Грешка в мрежата. Моля, презаредете страницата',
    netErrorReload: 'Презареди',
    netErrorTitle: 'Грешка',
    netErrorClose: 'Затвори',
    noShops: 'Оп-а! Изглежда, че все още не са ви присвоени магазини. Кажете на вашия мениджър за това',
    noCases: 'Изглежда, че нашата услуга не можа да намери никакви задачи в този магазин. Започнете да ги създавате сами',
    noAutoOrder: 'Няма задачи за коригиране на автоматичния ред',
    causeError: 'Грешка',
    scenariosEmpty: 'Масивът със сценарии е празен'
  },
  officeUI: {
    resetZoom: 'Нулиране на увеличението'
  },
  gridAutocomplete: {
    placeholder: 'Започнете да пишете името на магазина',
    notFound: 'Магазинът не е намерен'
  },
  targets: {
    nothing: '',
    shop: 'Магазин',
    category: 'Категория',
    supplier: 'Доставчик',
    vendor: 'Производител',
    brand: 'Марка',
    plu: 'PLU',
    day: 'Ден',
    week: 'Седмица',
    month: 'Месец',
    quarter: 'Тримесечие',
    year: 'Година'
  },
  bubbleChart: {
    qty: 'кол.',
    currency: 'лв.'
  },
  chart: {
    quarter: 'тримесечие',
    week: 'седмица'
  },
  download: {
    CSV: 'Изтегли CSV',
    XLS: 'Изтегли XLS'
  },
  counterTask: {
    task: 'Изчакващи задачи',
    noTask: 'Няма задачи'
  },
  filtersShop: {
    shopName: 'Адрес на магазина',
    shopId: 'Име на магазина'
  },
  chartCaption: {
    thousand: 'хил.',
    million: 'млн.',
    billion: 'млрд.'
  },
  common: {
    close: 'Затвори',
    userNotFound: 'Потребителят не е намерен',
    yes: 'Да',
    no: 'Не',
  },
  virtualStock: {
    headers: {
      case_id: "ИД",
      plu_original: "PLU",
      plu: "Вътрешен PLU",
      shop_id: "ИД на магазина",
      ass_type: "Тип на асортимента",
      category_id3: "ИД на секцията",
      category_id4: "ИД на категорията",
      stock: "Обем на запасите",
      stock_sum: "Сума на балансите",
      product_turnover: "Оборот",
      days_without_sales: "Дни без продажби",
      last_sales_date: "Дата на последна продажба",
      last_sales_volume: "Обем на последна продажба",
      last_shipment_date: "Последна дата на доставка",
      last_shipment_volume: "Последен обем на доставка",
      promo_start: "Начало на промоцията",
      promo_end: "Край на промоцията",
      promo_period: "Период на промоцията",
      is_promo: "Промоция",
      title: "Име",
      category_name3: "Секция",
      category_name4: "Категория"
    },
    hideReadyTasks: "Скриване на готовите задачи",
    nothingFound: "Нищо не е намерено",
    shopsNotFound: "Магазините не са намерени",
    chooseShop: "Изберете магазин",
    createTasks: "Създаване на задачи",
    taskCreating: "Създаване на задачи",
    next: "Продължи",
    columns: "Колони",
    from: "От",
    ok: "OK",
    cancel: "Отказ",
    filter: "Филтър",
    chooseValue: "Изберете стойност",
    enterValue: "Въведете стойност",
    rangeFrom: "От",
    rangeTo: "До",
    selected: "Избрано",
    foundRows: "Намерени редове",
    createdTasksTotal: "Общо създадени задачи"
  }
};
