export default {
  mobileFirefoxAlert: 'Ebben a böngészőben a feladatkezelés nem érhető el.',
  datePicker: {
    from: 'Kezdete',
    to: 'Vége',
    cancel: 'Mégse',
    apply: 'Alkalmaz',
    month: [
      'Január',
      'Február',
      'Március',
      'Április',
      'Május',
      'Június',
      'Július',
      'Augusztus',
      'Szeptember',
      'Október',
      'November',
      'December'
    ],
    week: 'hét',
    weekShot: 'h',
    buttonName: {
      date: 'Dátum',
      week: 'Hét',
      month: 'Hónap'
    },
    modal: {
      header: 'Figyelmeztetés',
      text: 'Érvénytelen dátumtartomány lett kiválasztva',
      action: 'Elfogadás'
    }
  },
  filters: {
    name: 'Név'
  },
  scenario: {
    noDataReceived: 'Nincs adat',
    filterPart: {
      name: 'Név',
      clear: 'Törlés',
      notFound: 'Nem található',
      search: 'Keresés...'
    }
  },
  header: {
    logout: 'Kijelentkezés',
    changePassword: 'Jelszó megváltoztatása',
    settings: 'Beállítások',
    title: 'Nézet felhasználói felület',
    searchTitle: 'Felhasználó keresése',
    input: 'Kezdje el begépelni a nevet vagy a bejelentkezést...',
    cancel: 'Mégse',
    view: 'Nézet',
    id: 'ID',
    login: 'Bejelentkezés',
    name: 'Név',
    phone: 'Telefon',
    email: 'E-mail',
    stopSettings: 'Nézet leállítása'
  },
  directTask: {
    addCase: 'Eset hozzáadása',
    commentCase: 'Megjegyzés',
    actions: 'Műveletek',
    searchProduct: 'PLU/Vonalkód',
    searchProductShort: 'PLU/Vonalkód',
    photo: 'Fotó',
    back: 'Vissza',
    treeEmpty: 'A MŰVELETEK NEM ELÉRHETŐK',
    step: 'Lépés',
    sortingButton: 'Rendezés',
    sortButton: 'Rendezés',
    add: 'Hozzáadás',
    delete: 'Törlés',
    deleteManualCases: 'Kézi esetek törlése',
    show: 'Megjelenítés',
    hide: 'Elrejtés',
    comment: 'Megjegyzés',
    showDoneCases: 'Elvégzett feladatok megjelenítése',
    hideDoneCases: 'Elrejtése befejezett feladatok'
  },
  gridTable: {
    pluList: 'PLU lista'
  },
  casesForm: {
    product: 'Termék',
    loadFoto: 'Fotó betöltése',
    plu: 'Név/PLU',
    success: 'Eset létrehozva',
    delete: 'Töröl',
    add: 'Teremt',
    new: 'Új',
    cancel: 'Mégse',
    comment: 'Megszünteti'
  },
  pagination: {
    apply: 'Ugrás',
    placeholder: 'Oldal'
  },
  restorePass: {
    noLength: 'A jelszónak legalább 12 karakterből kell állnia',
    noLowercase: 'A jelszónak legalább egy kisbetűt kell tartalmaznia',
    noUppercase: 'A jelszónak legalább egy nagybetűt kell tartalmaznia',
    noNumber: 'A jelszónak legalább egy számot kell tartalmaznia',
    noMatch: 'A jelszavak nem egyeznek',
    password: 'Jelszó',
    repeatPassword: 'Jelszó ismétlése',
    ok: 'Elfogadás',
    cancel: 'Mégse'
  },
  navigation: {
    missions: 'Feladatok',
    scenarios: 'Szituációk'
  },
  changePassword: {
    previousPassword: 'Jelenlegi jelszó',
    newPassword: 'Új jelszó',
    confirmPassword: 'Új jelszó újra',
    apply: 'Alkalmaz',
    title: 'Jelszó megváltoztatása',
    success: 'A jelszó sikeresen megváltozott',
    wrongPassword: 'Hibás jelszó',
    weakPassword: 'A jelszó nem felel meg a követelményeknek',
    passwordsNotMatch: 'A jelszavak nem egyeznek',
    requirements: 'Az új jelszónak legalább 12 karakterből kell állnia, tartalmaznia kell egy nagy- és egy kisbetűt, valamint egy számot'
  },
  auth: {
    login: 'Bejelentkezés',
    password: 'Jelszó',
    restorePass: 'Jelszó visszaállítása',
    submit: 'Küldés',
    loginError: 'A bejelentkezési adatok érvénytelenek'
  },
  restorePassForm: {
    restore: 'Visszaállítás',
    cancel: 'Mégse',
    info: 'Kérjük, adja meg azt az e-mail címet, amely a OSA Hybrid Platformon van regisztrálva',
    emailInvalid: 'A megadott e-mail cím nem található',
    email: 'E-mail',
    success: 'A visszaállító linket elküldtük az e-mail címére'
  },
  search: {
    notFound: 'Nincs találat'
  },
  noMatchPage: {
    title: 'A kért oldal nem található',
    body: 'Vissza a főoldalra'
  },
  warnings: {
    title: 'Figyelmeztetés',
    isDesktop: 'A fotók csak a rendszer mobil verziójában érhetők el. Kérjük, használja a telefonján vagy táblagépén lévő kamerát.'
  },
  errors: {
    netErrorText: 'Hálózati hiba. Kérem, töltse be az oldalt újra',
    netErrorReload: 'Újratöltés',
    netErrorTitle: 'Hiba',
    netErrorClose: 'Bezárás',
    noShops: 'Ó! Úgy tűnik, még nem rendeltek hozzád üzletet. Mondja el a menedzserének',
    noCases: 'Úgy tűnik, szervizünk egyetlen tokot sem talált ebben az üzletben. Kezdje el saját maga létrehozni őket',
    noAutoOrder: 'Nincsenek feladatok az automatikus sorrend beállításához',
    causeError: 'Hiba',
    scenariosEmpty: 'A forgatókönyvek tömbje üres'
  },
  officeUI: {
    resetZoom: 'Nagyítás visszaállítása'
  },
  gridAutocomplete: {
    placeholder: 'Kezdje el begépelni az üzlet nevét',
    notFound: 'Az üzlet nem található'
  },
  targets: {
    nothing: '',
    shop: 'Bolt',
    category: 'Kategória',
    supplier: 'Beszállító',
    vendor: 'Szállító',
    brand: 'Márka',
    plu: 'PLU',
    day: 'Nap',
    week: 'Hét',
    month: 'Hónap',
    quarter: 'Negyedév',
    year: 'Év'
  },
  bubbleChart: {
    qty: 'mennyiség',
    currency: 'Ft'
  },
  chart: {
    quarter: 'negyedév',
    week: 'hét'
  },
  download: {
    CSV: 'Letöltés CSV',
    XLS: 'Letöltés XLS'
  },
  counterTask: {
    task: 'Függőben lévő feladatok',
    noTask: 'Nincs feladat'
  },
  filtersShop: {
    shopName: 'Áruház címe',
    shopId: 'Áruház neve'
  },
  chartCaption: {
    thousand: 'E',
    million: 'M',
    billion: 'Mrd'
  },
  common: {
    close: 'Bezárás',
    userNotFound: 'Felhasználó nem található',
    yes: 'Igen',
    no: 'Nem'
  },
  virtualStock: {
    headers: {
      case_id: 'ID',
      plu_original: 'PLU',
      plu: 'Belső PLU',
      shop_id: 'Áruház ID',
      ass_type: 'Asszortment típusa',
      category_id3: 'Szekció ID',
      category_id4: 'Kategória ID',
      stock: 'Maradék mennyiség',
      stock_sum: 'Maradék mennyiség összesen',
      product_turnover: 'Forgalom',
      days_without_sales: 'Napok száma eladás nélkül',
      last_sales_date: 'Az utolsó eladás dátuma',
      last_sales_volume: 'Utolsó eladási mennyiség',
      last_shipment_date: 'Utolsó szállítási dátum',
      last_shipment_volume: 'Utolsó szállítási mennyiség',
      promo_start: 'Promóció kezdete',
      promo_end: 'Promóció vége',
      promo_period: 'Promóció időtartama',
      is_promo: 'Promóció',
      title: 'Név',
      category_name3: 'Szekció',
      category_name4: 'Kategória'
    },
    hideReadyTasks: 'Kész feladatok elrejtése',
    nothingFound: 'Nincs találat',
    shopsNotFound: 'Nincs elérhető üzlet',
    chooseShop: 'Válasszon egy üzletet',
    createTasks: 'Feladatok létrehozása',
    taskCreating: 'Feladatok létrehozása',
    next: 'Tovább',
    columns: 'Oszlopok',
    from: 'Kezdet',
    ok: 'OK',
    cancel: 'Mégse',
    filter: 'Szűrő',
    chooseValue: 'Válasszon értéket',
    enterValue: 'Adja meg az értéket',
    rangeFrom: 'Kezdete',
    rangeTo: 'Vége',
    selected: 'Kiválasztva',
    foundRows: 'Találatok száma',
    createdTasksTotal: 'Létrehozott feladatok összesen'
  }
};
