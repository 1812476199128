import React from 'react';
import PropTypes from 'prop-types';
import ReactHighcharts from 'react-highcharts';
import { SwatchesPicker } from 'react-color';
import ColorPickerIcon from '@material-ui/icons/ColorLens';
import { connect } from 'react-redux';
import moment from 'moment';
import UiElement from '../UiElement/UiElement';
import ExportDataComponent from '../ExportDataComponent';
import IsTimeTarget from '../TimeTarget';
import styles from './Chart.css';

// HighchartsExporting(ReactHighcharts.Highcharts);
// HighchartsExportCSV(ReactHighcharts.Highcharts);

class Chart extends UiElement {
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
  }

  state = {
    chartColor: ['#42bde8', '#3B3B3F', '#1c5787', '#d4dff0'],
    chartDefaultColors: [
      '#42bde8',
      '#3B3B3F',
      '#1c5787',
      '#d4dff0',
      '#6DA67A',
      '#99A66D',
      '#A9BD68',
      '#B5CC6A',
      '#C0DE5D',
      '#FE4365',
      '#FC9D9A',
      '#F9CDAD',
      '#C8C8A9',
      '#83AF9B',
      '#EEE6AB',
      '#C5BC8E',
      '#696758',
      '#45484B',
      '#36393B',
      '#30261C',
      '#ffee90',
      '#36544F',
      '#1F5F61',
      '#403831'
    ],
    customBarColor: '#42bde8',
    colorPickerOpen: false,
    customColorPickerOpen: false,
    itemToColor: 0,
    itemGlobalToColor: 0,
    kpiToColor: '',
    kpiList: [],
    source: [],
    config: {},
    propsData: {}
  };

  componentDidMount() {
    this.transformData();
  }

  componentWillReceiveProps(nextProps) {
    const colors = this.state.chartColor;
    const { chartDefaultColors } = this.state;
    const propsData = nextProps.data.data;
    const list = [];
    propsData.source.map((kpi, key) => {
      kpi.visible = true;
      list.push(kpi.name);
      const newArr = [];
      const defaultColorState = chartDefaultColors.filter(item => item === colors[key]);
      kpi.color = defaultColorState.length ? chartDefaultColors[key] : colors[key];
      kpi.data.map(item => {
        newArr.push({
          y: typeof item === 'object' ? (item ? item.y : null) : item,
          color: defaultColorState.length ? chartDefaultColors[key] : colors[key]
        });
      });
      kpi.data = newArr;
    });
    this.setState({
      kpiList: list
    });
    const source = [];
    propsData.xNames.data.map((item, key) => {
      const element = [];
      element.push(item);
      propsData.source.map(sourceItem => {
        element.push(sourceItem.data[key]);
      });
      element.push(propsData.xNames.resultSetAll[key]);
      source.push(element);
    });
    this.setState(
      {
        source: source,
        propsData: propsData
      },
      () => {
        this.prepareConfig();
      }
    );
  }

  shouldComponentUpdate(nextProps) {
    return !this.props.updatesLocked && this.props.updatesLocked === nextProps.updatesLocked;
  }

  chartAction = type => {
    const { transitionIsActive, transition, datePickerDate } = this.props;
    const { propsData } = this.state;
    const that = this;
    const style = type ? styles.link : styles.linkTranstion;
    let chart = this.chartRef.current.getChart();
    const subScenarioId = propsData['sub-scenario'] ? propsData['sub-scenario'] : 0;

    chart.xAxis[0].labelGroup.element.childNodes.forEach(function(label, index) {
      if (that.props.updatesLocked) {
        label.classList.remove(style);
        label.onclick = null;
      } else {
        label.classList.add(style);
        label.onclick = function() {
          const target = propsData.xNames.source;
          const resultSetItem = propsData.xNames.resultSetAll[index];
          if (!propsData.inverted) {
            if (type) {
              const filter = that.prepareFilter(target, resultSetItem);
              that.getSubScenarioData(subScenarioId, filter, target);
            } else {
              const filter = that.prepareFilter(target, resultSetItem, transition, datePickerDate);
              that.groupTransition(filter, target);
              transitionIsActive(true);
            }
          }
        };
      }
    });
  };

  initiateChartEvents(propsData) {
    const { transition } = this.props;
    const invert = propsData.inverted;
    let chart = this.chartRef.current.getChart();
    const subScenarioId = propsData['sub-scenario'] ? propsData['sub-scenario'] : 0;

    if (chart && chart.xAxis[0].labelGroup) {
      if (subScenarioId > 0 && !invert) {
        this.chartAction(true);
      } else if (transition && Object.keys(transition).length && !invert) {
        this.chartAction(false);
      }
    }
  }

  transformData = color => {
    const { chartDefaultColors } = this.state;
    const propsData = this.props.data.data;
    const source = [];
    let list = [];
    if (!color) {
      propsData.source.map((kpi, key) => {
        kpi.visible = true;
        list.push(kpi.name);
        const newArr = [];
        kpi.color = chartDefaultColors[key];
        kpi.data.map(item => {
          newArr.push({
            y: typeof item === 'object' ? (item ? item.y : null) : item,
            color: chartDefaultColors[key]
          });
        });
        kpi.data = newArr;
      });
    }

    this.setState({
      kpiList: list
    });

    propsData.xNames.data.map((item, key) => {
      const element = [];
      element.push(item);
      propsData.source.map(sourceItem => {
        element.push(sourceItem.data[key]);
      });
      element.push(propsData.xNames.resultSetAll[key]);
      source.push(element);
    });

    this.setState(
      {
        source: source,
        propsData: propsData
      },
      () => {
        this.prepareConfig();
      }
    );
  };

  handleColorChange = color => {
    const { propsData } = this.state;
    this.setState(
      prevState => ({
        chartColor: [color.hex, ...prevState.chartColor]
      }),
      () => {
        const { chartColor, itemGlobalToColor } = this.state;
        if (propsData.source[itemGlobalToColor] !== undefined) {
          const newArr = [];
          const kpi = propsData.source[itemGlobalToColor];
          kpi.color = chartColor[0];
          kpi.data.map(item => {
            newArr.push({
              y: item.y,
              color: chartColor[0]
            });
          });
          kpi.data = newArr;
        }
        this.setState(
          {
            chartColor: this.state.chartColor,
            itemGlobalToColor: this.state.itemGlobalToColor + 1
          },
          () => {
            if (propsData.source[this.state.itemGlobalToColor] === undefined) {
              this.setState({
                itemGlobalToColor: 0,
                colorPickerOpen: false,
                chartColor: propsData.source.map(item => item.color)
              });
            }
          }
        );
      }
    );
    this.setState(
      {
        propsData: propsData
      },
      () => {
        const source = [];
        const { propsData } = this.state;
        propsData.xNames.data.map((item, key) => {
          const element = [];
          element.push(item);
          propsData.source.map(sourceItem => {
            element.push(sourceItem.data[key]);
          });
          element.push(propsData.xNames.resultSetAll[key]);
          source.push(element);
        });
        this.setState({
          source: source
        });
      }
    );
    this.setState({
      chartDefaultColors: this.state.chartColor
    });
    this.transformData();
  };

  iconClickHandle = () => {
    this.setState({
      colorPickerOpen: !this.state.colorPickerOpen
    });
  };

  overlayHandle = () => {
    this.setState({
      colorPickerOpen: false,
      itemGlobalToColor: 0,
      customColorPickerOpen: false
    });
    this.transformData();
  };

  handleCustomColorChange = color => {
    const { propsData } = this.state;
    this.setState(
      {
        customBarColor: color.hex
      },
      () => {
        const { itemToColor, kpiToColor, customBarColor } = this.state;
        propsData.source.map(kpi => {
          if (kpi.name === kpiToColor) {
            kpi.data[itemToColor].color = customBarColor;
            this.setState({
              customColorPickerOpen: false
            });
            if (kpi.data.length === 1) {
              kpi.color = customBarColor;
            }
          }
        });
        this.setState({
          propsData: propsData
        });
      }
    );
    this.transformData(true);
  };

  sortHandle = (visibility, name) => {
    const { source, kpiList, propsData } = this.state;
    let kpiIndex = kpiList.indexOf(name);
    propsData.source[kpiIndex].visible = visibility;
    const itemIsVisible = propsData.source.find(item => item.visible === true);
    if (!itemIsVisible) {
      return 1;
    }
    if (!IsTimeTarget(propsData.xNames.source)) {
      const index = kpiList.indexOf(itemIsVisible.name);
      let sorted = [];
      if (!propsData.inverted) {
        if (propsData.order === 'desc') {
          sorted = source.sort((a, b) => b[index + 1].y - a[index + 1].y);
        } else {
          sorted = source.sort((a, b) => a[index + 1].y - b[index + 1].y);
        }
      } else {
        sorted = source;
      }

      const sortedNames = sorted.map(item => item[0]);
      const sortedValues = sorted.map(item => item[index + 1]);
      const collection = sorted.map(item => item[item.length - 1]);
      propsData.xNames.data = sortedNames;
      propsData.xNames.resultSetAll = collection;
      propsData.source[index].data = sortedValues;
      propsData.source.map((kpi, key) => {
        if (key !== index) {
          propsData.source[key].data = sorted.map(item => item[key + 1]);
        }
      });
    }
    this.setState(
      {
        propsData: propsData
      },
      () => {
        this.prepareConfig();
        this.initiateChartEvents(propsData);
      }
    );
  };

  downloadCSV = () => {
    const chart = this.chartRef.current.getChart();
    chart.downloadCSV();
  };

  plotBandsMutate = (target, data, xNamesData) => {
    data.map(item => {
      const newFrom = this.dateNamesTransformHandle(target, [item.from]);
      const newTo = this.dateNamesTransformHandle(target, [item.to]);

      const fromIndex = xNamesData.indexOf(newFrom[0]);
      const toIndex = xNamesData.indexOf(newTo[0]);

      item.from = fromIndex - 0.5;
      item.to = toIndex + 0.5;
      item.color = 'rgba(66, 189, 232, .1)';
    });
    return data;
  };

  dateNamesTransformHandle = (target, data) => {
    const isDate = new Date(data[0]);
    const isChecked = !isNaN(isDate.getDate());

    if (isChecked === true) {
      const { translation } = this.props.language;

      if (!data.length) {
        return data;
      }

      switch (target) {
        case 'day':
          return data.map(item =>
            item
              .split('-')
              .reverse()
              .join('.')
          );
        case 'week':
          return data.map(item => {
            const targetDate = moment(item).startOf('isoWeek');
            return `${targetDate.week()} ${translation.chart.week} ${targetDate.year()}`;
          });
        case 'month':
          return data.map(item => {
            const newItem = item.split('-').reverse();
            newItem.shift();
            return newItem.join('.');
          });
        case 'quarter':
          return data.map(item => {
            const newItem = item.split('-').reverse();
            const quarter = Math.ceil(+newItem[1] / 3);
            return `${quarter} ${translation.chart.quarter} ${newItem[2]}`;
          });
        case 'year':
          return data.map(item => item.split('-')[0]);
        default:
          return data;
      }
    } else {
      return data.map(item => item);
    }
  };

  prepareConfig = () => {
    const that = this;
    const { propsData } = this.state;
    const invert = propsData.inverted;
    const {
      updatesLocked,
      transition,
      language: { translation: { chartCaption: { thousand = '', million = '', billion = '' } = {} } = {} } = {}
    } = this.props;
    const subScenarioId = propsData['sub-scenario'] ? propsData['sub-scenario'] : 0;
    const targets = propsData.xNames.source;
    const { height } = this.props.data;
    const noData = this.props.data.data.source[0];
    const formatAxisY = value => {
      if (value >= 1000 && value < 1000000) {
        return ReactHighcharts.Highcharts.numberFormat(value / 1000, 1) + thousand;
      }
      if (value < 1000 && value >= 0) {
        return value;
      }
      if (value >= 1000000 && value < 1000000000) {
        return ReactHighcharts.Highcharts.numberFormat(value / 1000000, 1) + million;
      }
      if (value >= 1000000000) {
        return ReactHighcharts.Highcharts.numberFormat(value / 1000000000, 1) + billion;
      }
      if (value < -1000 && value > -1000000) {
        return ReactHighcharts.Highcharts.numberFormat(value / 1000, 0) + thousand;
      }
      if (value > -1000 && value < 0) {
        return value;
      }
      if (value < -1000000 && value > -1000000000) {
        return ReactHighcharts.Highcharts.numberFormat(value / 1000000, 1) + million;
      }
      if (value < -1000000000) {
        return ReactHighcharts.Highcharts.numberFormat(value / 1000000000, 1) + billion;
      }
    };

    const h = height || 1;
    let calcHeight = 120;
    if (height > 1) {
      calcHeight = 163 * h - 43;
    }

    const dateObj = new Date();
    const month = dateObj.getUTCMonth() + 1;
    const day = dateObj.getUTCDate();
    const year = dateObj.getUTCFullYear();
    const dateNow = day + '_' + month + '_' + year;
    propsData.plotType = {};
    propsData.plotType.column = {
      cursor: 'pointer'
    };
    propsData.plotType.series = {
      dataLabels: {
        color: '#000'
      },
      stickyTracking: false,
      events: {
        legendItemClick: function() {
          const { name, visible } = this;
          that.sortHandle(!visible, name);
        },
        click: function(e) {
          const { chartDefaultColors } = that.state;
          const defaultColorState = chartDefaultColors.filter(item => item === e.point.color);
          that.setState({
            itemToColor: e.point.index,
            kpiToColor: this.userOptions.name,
            customColorPickerOpen: true,
            customBarColor: defaultColorState.length ? false : e.point.color
          });
        }
      }
    };
    propsData.source.map(elem => {
      elem.dataLabels = {
        enabled: true,
        formatter: function() {
          if (this.y > 1000 && this.y < 1000000) {
            return ReactHighcharts.Highcharts.numberFormat(Math.floor(this.y / 100) / 10, 1) + thousand;
          }
          if (this.y > 1000000 && this.y < 1000000000) {
            return ReactHighcharts.Highcharts.numberFormat(Math.floor(this.y / 100000) / 10, 1) + million;
          }
          if (this.y > 1000000000) {
            return ReactHighcharts.Highcharts.numberFormat(Math.floor(this.y / 100000000) / 10, 1) + billion;
          }
          if (this.y < -1000 && this.y > -1000000) {
            return ReactHighcharts.Highcharts.numberFormat(Math.ceil(this.y / 100) / 10, 1) + thousand;
          }
          if (this.y < -1000000 && this.y > -1000000000) {
            return ReactHighcharts.Highcharts.numberFormat(Math.ceil(this.y / 100000) / 10, 1) + million;
          }
          if (this.y < -1000000000) {
            return ReactHighcharts.Highcharts.numberFormat(Math.ceil(this.y / 100000000) / 10, 1) + billion;
          }
          return this.y;
        }
      };
    });

    if (IsTimeTarget(propsData.xNames.source)) {
      propsData.xNames.data = this.dateNamesTransformHandle(propsData.xNames.source, propsData.xNames.data);

      if (propsData.xNames.plotBands) {
        if (typeof propsData.xNames.plotBands[0].from !== 'number') {
          const data = this.plotBandsMutate(propsData.xNames.source, propsData.xNames.plotBands, propsData.xNames.data);
          propsData.xNames.plotBands = data;
        }
      }
    }

    if (IsTimeTarget(propsData.yNames.source)) {
      propsData.yNames.data = this.dateNamesTransformHandle(propsData.yNames.source, propsData.yNames.data);
    }

    propsData.xNames.data.map(elem => {
      if (elem === null) {
        const index = propsData.xNames.data.indexOf(elem);
        propsData.xNames.data[index] = 'NO NAME';
      }
    });
    const config = {
      chart: {
        height: calcHeight
      },
      credits: {
        enabled: false
      },
      title: {
        text: propsData.name,
        style: {
          color: '#293258'
        }
      },
      exporting: {
        filename: `${dateNow}_${propsData.name}`,
        csv: {
          itemDelimiter: ';'
        },
        buttons: {
          contextButton: {
            enabled: true
          }
        }
      },
      legend: {
        itemStyle: {
          color: '#293258'
        },
        labelFormatter: function() {
          if (invert) {
            return that.dateNamesTransformHandle(propsData.xNames.source, this.name);
          } else {
            return this.name;
          }
        }
      },
      plotOptions: propsData.plotType,
      tooltip: {
        formatter: invert
          ? function() {
            if (invert) {
              const date = that.dateNamesTransformHandle(propsData.xNames.source, this.series.name);
              return this.x + '<br>' + date + ' : ' + '<strong>' + this.y.toLocaleString() + '</strong>';
            }
          }
          : null
      },
      xAxis: {
        collection: propsData.xNames.resultSetAll,
        categories: propsData.xNames.data,
        title: {
          text: propsData.xNames.source,
          enabled: false
        },
        plotBands: propsData.xNames.plotBands ? propsData.xNames.plotBands : [],
        labels: {
          formatter: function() {
            var tick = this.axis.ticks[this.pos];
            if (tick) {
              tick.label.element.onclick = function() {
                const item = tick.formatCtx.value;
                let key = propsData.xNames.data.indexOf(item);
                if (subScenarioId > 0 && !updatesLocked) {
                  const filter = that.prepareFilter(targets, propsData.xNames.resultSetAll[key]);
                  that.getSubScenarioData(subScenarioId, filter, targets);
                }
              };
            }
            return this.value;
          }
        }
      },
      yAxis: {
        title: {
          text: ''
        },
        labels: {
          formatter: function() {
            return formatAxisY(this.value);
          }
        }
      },
      series: propsData.source
    };

    if (propsData.auxiliary && !invert) {
      config.yAxis = [];
      config.series.map((item, key) => {
        if (key === 0) {
          config.yAxis.push({
            title: {
              text: ''
            },
            labels: {
              formatter: function() {
                return formatAxisY(this.value);
              }
            }
          });
        } else {
          config.yAxis.push({
            title: {
              text: ''
            },
            opposite: true,
            labels: {
              formatter: function() {
                return formatAxisY(this.value);
              }
            }
          });
        }
        item.yAxis = key;
      });
    }
    config.xAxis.labels = {
      style: {
        color: `${subScenarioId > 0 && !invert ? '#1AA7E0' : '#000'}`,
        fontStyle: `${transition && !invert ? 'italic' : 'normal'}`
      }
    };

    if (!noData.data.length && !noData.name.length) {
      config.series[0].name = ['NO DATA'];
    }

    this.setState(
      {
        config: config
      },
      () => {
        this.initiateChartEvents(propsData);
      }
    );
  };

  render() {
    const { config } = this.state;
    const { data, language } = this.props;
    const { height } = this.props.data;
    const { chartColor, colorPickerOpen, customColorPickerOpen, customBarColor } = this.state;

    const h = height || 1;
    let calcHeight = 120;
    if (height > 1) {
      calcHeight = 163 * h - 43;
    }
    return (
      <div className={styles.chart} style={{ height: +calcHeight }}>
        <div className={styles.topButtons}>
          <div role="presentation" className={styles.topButtonsButton} onClick={this.iconClickHandle}>
            <ColorPickerIcon style={{ color: chartColor[0] }} className={styles.topButtonsIcon} />
          </div>
          <ExportDataComponent data={data} translation={language.translation} />
        </div>
        {(colorPickerOpen || customColorPickerOpen) && (
          <div role="presentation" className={styles.overlay} onClick={this.overlayHandle} />
        )}{' '}
        {customColorPickerOpen && (
          <div className={styles.colorPickerPlugin}>
            <SwatchesPicker color={customBarColor} onChangeComplete={this.handleCustomColorChange} />
          </div>
        )}{' '}
        {colorPickerOpen && (
          <div className={styles.colorPickerPlugin}>
            <SwatchesPicker color={chartColor[0]} onChangeComplete={this.handleColorChange} />
          </div>
        )}{' '}
        <ReactHighcharts config={config} ref={this.chartRef} />
      </div>
    );
  }
}

Chart.defaultProps = {
  data: {},
  language: {},
  updatesLocked: false
};

Chart.propTypes = {
  data: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  language: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  filters: PropTypes.func.isRequired, // eslint-disable-line react/forbid-prop-types
  updateFilters: PropTypes.func.isRequired, // eslint-disable-line react/forbid-prop-types
  setDrillFilters: PropTypes.func.isRequired, // eslint-disable-line react/forbid-prop-types
  updatesLocked: PropTypes.bool, 
  transition: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  transitionIsActive: PropTypes.func.isRequired,
  datePickerDate: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  scenario: PropTypes.object.isRequired // eslint-disable-line react/forbid-prop-types
};

export default connect(store => ({
  scenario: store.scenario,
  language: store.language,
  parentScenario: store.parentScenario,
  datePickerDate: store.datePickerDate
}))(Chart);
